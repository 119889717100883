import { useState } from "react";
import { PackagingSimulationResponseType } from "@/hooks/usePackagingSimulationMutation";

export function usePackagingSimulation() {
  const [packagingSimulationData, setPackagingSimulationData] = useState(
    {} as PackagingSimulationResponseType
  );

  function handlePackagingSimulationData(
    data: PackagingSimulationResponseType
  ) {
    setPackagingSimulationData(data);
  }

  function clear() {
    setPackagingSimulationData({} as PackagingSimulationResponseType);
  }

  return {
    packagingSimulationData,
    handlePackagingSimulationData,
    clear,
  };
}
