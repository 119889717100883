import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { resourceKeys, ResourcesType } from "./useResources";

async function fetchResource({ id }: { id: number }) {
  if (!id) return;
  const response = await instance.post<ApiResponse<ResourcesType>>(
    "/planningbeer/resource/detail",
    {
      id: id,
    }
  );
  return response.data;
}

export default function useResource({ id }: { id: number }) {
  return useQuery({
    queryKey: resourceKeys.detail(id),
    queryFn: () => fetchResource({ id }),
    placeholderData: keepPreviousData,
  });
}
