import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { TEXT_COLOR } from "@/constants";
import { TbTruckDelivery } from "react-icons/tb";
import SupplierPackagingStep from "../Steps/SupplierPackagingStep";
import { useDeliveryAuthorizationContext } from "../../context/DeliveryAuthorizationContext";
import ContractStep from "../Steps/ContractStep";

export default function DeliveryAuthorizationModal() {
  const { step, clearSelectedComposition } = useDeliveryAuthorizationContext();

  const stepKeys = [<SupplierPackagingStep />, <ContractStep />];

  return (
    <Dialog
      onOpenChange={() => {
        clearSelectedComposition();
      }}
    >
      <DialogTrigger>
        <Button variant="link">
          <Row align="center">
            <TbTruckDelivery className="mr-1" size={24} color={TEXT_COLOR} />
            <Text className="mt-1">Gerar Autorização de Entrega</Text>
          </Row>
        </Button>
      </DialogTrigger>
      <DialogContent className="h-full max-w-full w-10/12 ">
        {stepKeys[step]}
      </DialogContent>
    </Dialog>
  );
}
