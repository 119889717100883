import Row from "@/components/Row";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { TEXT_COLOR } from "@/constants";
import { useEffect, useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import MaxProductionPopover from "./MaxProductionPopover";
import Can from "@/components/Can";

type EditLineCellPopoverProps = {
  value: string;
  onChange?: (value?: string) => void;
  maxProduction?: number;
  index?: number;
};

export default function EditLineCellPopover({
  value,
  onChange,
  maxProduction,
  index,
}: EditLineCellPopoverProps) {
  const popoverTriggerRef = useRef<HTMLButtonElement>(null);
  const [activeTabKey, setActiveTabKey] = useState<number>();
  const [cellValue, setCellValue] = useState<string>();
  const [visible, setVisible] = useState(false);

  function handlePopover() {
    setVisible(!visible);
    if (!cellValue) return;
    onChange?.(cellValue);
    setCellValue(undefined);
    setActiveTabKey((prev) => (prev == index ? prev : index));
  }

  useEffect(() => {
    if (!popoverTriggerRef.current) return;
    if (!visible) {
      if (activeTabKey == index) popoverTriggerRef.current.focus();
    }
  }, [visible]);

  return (
    <Popover open={visible} onOpenChange={handlePopover}>
      <PopoverTrigger ref={popoverTriggerRef} tabIndex={index}>
        <FaEdit size={16} color={TEXT_COLOR} />
      </PopoverTrigger>
      <PopoverContent side="right">
        <Row className="gap-2">
          <Input
            type="number"
            defaultValue={value}
            onChange={(e) => {
              setCellValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") handlePopover();
            }}
          />
          <Can
            condition={false}
            onTrue={<MaxProductionPopover value={maxProduction} />}
          />
        </Row>
      </PopoverContent>
    </Popover>
  );
}
