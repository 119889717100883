import { DialogTypeType, ReactChildrenType } from "@/types";

import { createContext, useContext, useState } from "react";
import { CargoModelType } from "../hooks/useCargoModels";

type CargoModelContextProps = {
  visibleDialog: boolean;
  handleDialog: () => void;
  dialogType: DialogTypeType;
  handleDialogType: (type: DialogTypeType) => void;
  cargoModel: CargoModelType;
  handleCargoModel: (data: CargoModelType) => void;
  clear: () => void;
};

const CargoModelContext = createContext({} as CargoModelContextProps);

export default function CargoModelProvider({ children }: ReactChildrenType) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [cargoModel, setCargoModel] = useState<CargoModelType>(
    {} as CargoModelType
  );
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handleCargoModel(value: CargoModelType) {
    setCargoModel(value);
  }

  function clear() {
    setCargoModel({} as CargoModelType);
    setDialogType("nonSelected");
    setVisibleDialog(false);
  }

  return (
    <CargoModelContext.Provider
      value={{
        visibleDialog,
        dialogType,
        cargoModel,
        clear,
        handleCargoModel,
        handleDialog,
        handleDialogType,
      }}
    >
      {children}
    </CargoModelContext.Provider>
  );
}

export function useCargoModelContext() {
  return useContext(CargoModelContext);
}
