import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

export default function ModelTurnSelect(props: SelectProps) {
  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="6x1">6x1</SelectItem>
        <SelectItem value="6x2">6x2</SelectItem>
        <SelectItem value="ADM">ADM</SelectItem>
      </SelectContent>
    </Select>
  );
}
