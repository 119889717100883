import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { factoryResourceKey } from "./useFactoriesResources";

export default function useFactoryResourceDeleteMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return instance.delete("/planningbeer/factoryResource", {
        data: { id },
      });
    },

    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: factoryResourceKey.all,
      });
    },
  });
}
