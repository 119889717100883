import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import EditDropdownItem from "@/components/DropdownItem/EditDropdownItem";
import React from "react";
import { useFactoryResourceProductContext } from "../../context";
import { ResourceFactoryProductsTableType } from "../..";

export default function ActionDropdown(
  props: ResourceFactoryProductsTableType
) {
  const { handleDialog, handleDialogType, handleResourceFactoryProduct } =
    useFactoryResourceProductContext();

  function handleEdit() {
    handleDialog();
    handleDialogType("edit");
    handleResourceFactoryProduct({ ...props });
  }

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handleResourceFactoryProduct({ ...props });
  }

  return (
    <React.Fragment>
      <EditDropdownItem onClick={handleEdit} />
      <DeleteDropdownItem onClick={handleDelete} />
    </React.Fragment>
  );
}
