import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useFactoryContext } from "../../context";
import { useFactoryDeleteMutation } from "@/hooks/useFactoryDeleteMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";

export default function DeleteDialogAlert() {
  const { mutateAsync, isPending } = useFactoryDeleteMutation();
  const { dialogType, visibleDialog, factory, clear } = useFactoryContext();

  async function handleDelete() {
    try {
      await mutateAsync({ id: Number(factory.SK_fabrica) });
      toast.success("Fábrica excluída com sucesso!");
      return clear();
    } catch (error) {
      return validationError(error);
    }
  }

  if (dialogType !== "delete") return null;
  return (
    <AlertDialog open={visibleDialog} onOpenChange={clear}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Exclusão</AlertDialogTitle>
          <AlertDialogDescription>
            Confirma a exclusão da fábrica "{factory.SK_fabrica}"? Essa ação é
            irreversível.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={clear} disabled={isPending}>
            Cancelar
          </AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete} disabled={isPending}>
            Excluir
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
