import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Table from "@/components/NewTable";
import Row from "@/components/Row";
import CreateIndisponibilityResourceDialog from "./components/Dialog/CreateIndisponibilityResourceDialog";
import {
  IndisponibilityResourceType,
  useIndisponibilityResources,
} from "@/hooks/useIndisponibilityResources";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import { TableData } from "@/components/NewTable/type";
import { getTableColumnOrder } from "@/utils/table";
import { useState } from "react";
import ActionCell from "@/components/Cell/ActionCell";
import Empty from "@/components/Empty";
import dayjs from "dayjs";
import { useFactoriesResources } from "@/hooks/useFactoriesResources";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import EditIndisponibilityResourceDialog from "./components/Dialog/EditIndisponibilityResourceDialog";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import { useIndisponibilityResourceContext } from "./context";
import useIndisponibilityResourceDeleteMutation from "@/hooks/useIndisponibilityResourceDeleteMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import TableContainer from "@/components/Container/TableContainer";

const columnHelper = createColumnHelper<IndisponibilityResourceType>();
const TABLE_ORDER_KEY = "indisponibilityResourceColumnOrder";

type IndisponibilityResourceTable = TableData<IndisponibilityResourceType>;

export default function ResourcesIndisponibility() {
  useFactoriesResources();
  const { dialogType, clear, indisponibilityResource, visibleDialog } =
    useIndisponibilityResourceContext();
  const { data } = useIndisponibilityResources();
  const { mutateAsync, isPending } = useIndisponibilityResourceDeleteMutation();
  const { data: resourcesIndisponibility = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_indisponibilidade_programada", {
      header: "ID",
      id: "SK_indisponibilidade_programada",
    }),
    columnHelper.accessor("motivo", {
      header: "Motivo",
      id: "motivo",
      size: 400,
    }),
    columnHelper.accessor("vigencia_inicio", {
      header: "Vigência Início",
      id: "vigencia_inicio",
      size: 200,
      cell: (row) => {
        const date = row.getValue();
        if (!date) return <Empty />;
        return (
          <span>
            {dayjs(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}
          </span>
        );
      },
    }),
    columnHelper.accessor("vigencia_fim", {
      header: "Vigência Fim",
      id: "vigencia_fim",
      size: 200,
      cell: (row) => {
        const date = row.getValue();
        if (!date) return <Empty />;
        return (
          <span>
            {dayjs(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}
          </span>
        );
      },
    }),
    columnHelper.accessor("abreviatura_empresa", {
      header: "Fábrica",
      id: "abreviatura_empresa",
    }),
    columnHelper.accessor("cnpj", { header: "CNPJ", id: "cnpj", size: 180 }),
    columnHelper.accessor("codigo", {
      header: "Código da Fábrica",
      id: "codigo",
      size: 200,
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição do Recurso",
      id: "descricao",
      size: 350,
    }),
    columnHelper.accessor("tipo", {
      header: "Tipo de Recurso",
      id: "tipo",
      size: 200,
    }),
    columnHelper.accessor("tipo_de_embalagem", {
      header: "Tipo de Embalagem",
      id: "tipo_de_embalagem",
      size: 200,
    }),
    columnHelper.display({
      id: "Ações",
      header: "Ações",
      size: 100,
      cell: ({ row }) => (
        <ActionCell items={<ActionDropdown {...row.original} />} />
      ),
    }),
  ];

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getTableColumnOrder({ column: columns, key: TABLE_ORDER_KEY })
  );

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  async function handleDelete() {
    if (isPending) return;
    try {
      await mutateAsync({
        id: indisponibilityResource?.SK_indisponibilidade_programada,
      });
      toast.success("Indisponibilidade excluída com sucesso!");
      return clear();
    } catch (error) {
      validationError(error);
    }
  }

  return (
    <AuthenticatedLayout>
      <Row justify="end">
        <CreateIndisponibilityResourceDialog />
      </Row>
      <TableContainer>
        <Table<IndisponibilityResourceTable>
          onColumnOrderStateChange={handleColumnOrder}
          columnOrderState={columnOrder}
          columns={columns}
          data={resourcesIndisponibility}
          meta={{
            layout: "stretch",
          }}
        />
      </TableContainer>
      <EditIndisponibilityResourceDialog />
      <DeleteDialogAlert
        onCancel={clear}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        onConfirm={handleDelete}
        type={dialogType}
        alertDialogTitle={`Deseja excluir a indisponibilidade, "${indisponibilityResource?.SK_indisponibilidade_programada}"?. Essa ação é irreversível.`}
      />
    </AuthenticatedLayout>
  );
}
