import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import Text from "@/components/Text/Text";
import { useProductionPlanContext } from "../../context";
import Each from "@/components/Each";
import { useProductionPlanSimulators } from "@/hooks/useProductionPlanSimulators";
import Col from "@/components/Col";
import { Separator } from "@/components/ui/separator";
import dayjs from "dayjs";
import Can from "@/components/Can";
import Row from "@/components/Row";
import { LuAlertTriangle } from "react-icons/lu";
import { EMPTY_VALUE, ROUTES, TEXT_COLOR } from "@/constants";
import { Checkbox } from "@/components/ui/checkbox";
import { useState } from "react";
import { useProductionPlanAddSimulationMutation } from "@/hooks/useProductionPlanAddSimulationMutation";
import { validationError } from "@/utils/api";
import { useNavigate } from "react-router-dom";
import { SimulatorsEmpty } from "../Empty/SimulatorsEmpty";
import { MdFileUpload } from "react-icons/md";
import SyncSimulatorButton from "../Button/SyncSimulatorButton";

type SelectedSimulatorType = {
  SK_simulacao: number;
  valores_linhas: string;
  observacao?: string;
};

export default function IncludeSimulatorModal() {
  const [selectedSimulator, setSelectedSimulator] = useState<
    Array<SelectedSimulatorType>
  >([]);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const {
    productionPlan: {
      revisao: productionPlanRevision,
      FK_fabrica,
      SK_plano_producao,
      valores_linhas: productionPlanLineValues = "[]",
    },
  } = useProductionPlanContext();
  const { mutateAsync, isPending } = useProductionPlanAddSimulationMutation({
    id: SK_plano_producao,
  });
  const { isFetching, data } = useProductionPlanSimulators({
    fkFactory: FK_fabrica,
    fkProductionPlan: SK_plano_producao,
  });
  const { data: simulators = [] } = data || {};

  function handleSelectedSimulator(value: SelectedSimulatorType) {
    setSelectedSimulator([value]);
  }

  async function handleIncludeSimulator() {
    try {
      if (isPending) return;
      await mutateAsync({
        fkSimulation: selectedSimulator[0].SK_simulacao,
        fkProductionPlan: SK_plano_producao,
        lineValues: selectedSimulator[0].valores_linhas,
        oldLineValues: productionPlanLineValues || "[]",
        revision: productionPlanRevision,
        observation: selectedSimulator[0].observacao || "",
      });
      setVisible(!visible);
      setSelectedSimulator([]);
      return navigate("/" + ROUTES.productionPlan.index);
    } catch (error) {
      return validationError(error);
    }
  }

  if (isFetching) return;
  return (
    <Dialog
      onOpenChange={() => {
        setVisible(!visible);
        setSelectedSimulator([]);
      }}
      open={visible}
    >
      <DialogTrigger asChild>
        <Button variant="outline" onClick={() => setVisible(!visible)}>
          <Row className="justify-center items-center">
            <MdFileUpload size={22} color={TEXT_COLOR} />
            <Text className="ml-1 mt-1">Incluir Simulador</Text>
          </Row>
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col max-w-full h-full w-11/12 px-8 py-8">
        <Text className="text-lg">
          <strong>Versão do Plano: </strong>
          {productionPlanRevision}
        </Text>
        <Separator />
        <Col className="h-full">
          <Text className="text-center mb-4">Simuladores Disponíveis</Text>
          <Can
            condition={simulators.length == EMPTY_VALUE}
            onTrue={<SimulatorsEmpty />}
          />
          <Each
            array={simulators}
            renderItem={({
              SK_simulacao,
              periodo_inicio,
              periodo_fim,
              revisao,
              observacao,
              criado_por_nome,
              valores_linhas,
              created_at,
            }) => {
              const isSameRevision = productionPlanRevision == revisao;
              return (
                <Row
                  className="border p-4 mb-4 rounded-sm"
                  align="center"
                  justify="space-between"
                >
                  <Row align="center">
                    <Checkbox
                      className="mr-4"
                      disabled={!isSameRevision}
                      onClick={() =>
                        handleSelectedSimulator({
                          SK_simulacao,
                          valores_linhas,
                          observacao,
                        })
                      }
                      checked={selectedSimulator.some(
                        (simulator) => simulator.SK_simulacao == SK_simulacao
                      )}
                    />
                    <div className="flex flex-col gap-1">
                      <Text>
                        {SK_simulacao} - Simulação para o período de{" "}
                        <strong>
                          {dayjs(periodo_inicio).format("DD/MM/YYYY")}
                        </strong>{" "}
                        a{" "}
                        <strong>
                          {dayjs(periodo_fim).format("DD/MM/YYYY")}
                        </strong>
                      </Text>
                      <Text>
                        Versão do simulador: <strong>{revisao}</strong>
                      </Text>
                      <Text>
                        Criado por: <strong>{criado_por_nome}</strong>
                      </Text>
                      <Text>
                        Criado em:{" "}
                        <strong>
                          {dayjs(created_at).format("DD/MM/YYYY HH:mm:ss")}
                        </strong>
                      </Text>
                      <Text>
                        Observação: <strong>{observacao}</strong>
                      </Text>
                      <Can
                        condition={!isSameRevision}
                        onTrue={
                          <Row className="mt-1">
                            <LuAlertTriangle
                              color={TEXT_COLOR}
                              className="mr-1"
                            />
                            <Text color="primary">
                              Este simulador está em uma versão incompatível com
                              o plano de produção
                            </Text>
                          </Row>
                        }
                      />
                    </div>
                  </Row>
                  <Can
                    condition={!isSameRevision}
                    onTrue={
                      <SyncSimulatorButton
                        revision={String(productionPlanRevision)}
                        skProductionSimulation={SK_simulacao}
                        productionPlanLineValues={JSON.parse(
                          productionPlanLineValues
                        )}
                        productionSimulationLineValues={JSON.parse(
                          valores_linhas
                        )}
                      />
                    }
                  />
                </Row>
              );
            }}
          />
        </Col>
        <Separator />
        <Row justify="end" className="gap-4">
          <Button variant="outline" onClick={() => setVisible(!visible)}>
            Cancelar
          </Button>
          <Button
            onClick={handleIncludeSimulator}
            disabled={isPending || selectedSimulator.length == EMPTY_VALUE}
          >
            Incluir
          </Button>
        </Row>
      </DialogContent>
    </Dialog>
  );
}
