import {
  productionPlanOldKey,
  ProductionPlanOldType,
} from "@/hooks/useProductionPlanOld";
import { MultiSelect } from "../ui/multiple-selector";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

type ProductionPlanOldData = {
  data: ProductionPlanOldType;
};

type ProductSelectProps = {
  selected: Array<string>;
  handleSelected: (value: string) => void;
  fkProductionPlan: number;
};

export default function ProductionPlanOldSelect({
  handleSelected,
  selected,
  fkProductionPlan,
}: ProductSelectProps) {
  const queryClient = useQueryClient();
  const [visible, setVisible] = useState(false);

  const productionPlanOldData: ProductionPlanOldData | undefined =
    queryClient.getQueryData(productionPlanOldKey.show(fkProductionPlan));

  if (!productionPlanOldData) return null;

  const { data: productionPlanOld } = productionPlanOldData;
  const filtered = productionPlanOld.anteriores?.filter(
    ({ deletado }) => !deletado
  );

  const options = filtered?.map((item) => {
    return {
      label: "v" + String(item.revisao) + " - " + (item.observacao ?? ""),
      value: String(item.sk_plano_producao_old),
    };
  });

  return (
    <MultiSelect
      options={options}
      onChange={handleSelected}
      selectedValues={selected}
      isOpen={visible}
      onOpen={() => {
        setVisible(true);
      }}
      onClose={() => {
        setVisible(false);
      }}
    />
  );
}
