/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import FactoryResourceForm from "..";
import { useEffect } from "react";
import { FactoryResourceType } from "@/hooks/useFactoriesResources";
import { FactoryResourceFormType } from "../CreateFactoryResourceForm";
import { validationError } from "@/utils/api";
import useFactoryResourcePatchMutation from "@/hooks/useFactoryResourcePatchMutation";
import dayjs from "dayjs";
import { toast } from "sonner";

type EditFactoryResourceFormProps = {
  data: FactoryResourceType;
  dialogVisiblity: () => void;
};

export default function EditFactoryResourceForm({
  data,
  dialogVisiblity,
}: EditFactoryResourceFormProps) {
  const form = useForm<FactoryResourceFormType>();
  const { mutateAsync, isPending } = useFactoryResourcePatchMutation();

  function formatUTCDate(date?: string) {
    if (!date) return undefined;
    const dateObj = new Date(date);
    dateObj.setDate(dateObj.getDate() + 1);
    return dateObj;
  }

  async function onSubmit(formData: FactoryResourceFormType) {
    if (isPending) return;
    try {
      await mutateAsync({
        ...formData,
        SK_fabrica_recurso: data.SK_fabrica_recurso,
        vigencia_inicio: formData.vigencia_inicio
          ? dayjs(formData.vigencia_inicio).format("YYYY-MM-DD")
          : undefined,
        vigencia_fim: formData.vigencia_inicio
          ? dayjs(formData.vigencia_fim).format("YYYY-MM-DD")
          : undefined,
      });
      dialogVisiblity();
      return toast.success("Recurso da fábrica editado com sucesso!");
    } catch (error) {
      validationError(error);
    }
  }

  useEffect(() => {
    form.reset({
      FK_fabrica: String(data.FK_fabrica),
      FK_recurso: String(data.FK_recurso),
      turno_de_trabalho: data.turno_de_trabalho,
      vigencia_inicio: formatUTCDate(data.vigencia_inicio),
      vigencia_fim: formatUTCDate(data.vigencia_fim),
    });
  }, [data]);

  return (
    <FactoryResourceForm
      form={form}
      onSubmit={onSubmit}
      button={{ disabled: isPending, title: "Editar" }}
    />
  );
}
