import LoadingProvider from "@/context/LoadingContext";
import PackagingResourceProvider from "@/pages/EmbalagemFornecedores/context";
import { AlertDialogProvider } from "@/pages/Estimativas/context/AlertDialogContext";
import FactoryResourceProvider from "@/pages/FabricaRecurso/context";
import FactoryResourceProductProvider from "@/pages/FabricaRecursoProdutos/context";
import FactoryProvider from "@/pages/Factory/context";
import IndisponibilityResourceProvider from "@/pages/IndisponibilidadeRecursos/context";
import InventoryProvider from "@/pages/Inventario/context";
import CargoModelProvider from "@/pages/ModeloCarga/context";
import PackagingPlanProvider from "@/pages/PlanoEmbalagem/context";
import { DeliveryAuthorizationProvider } from "@/pages/PlanoEmbalagem/context/DeliveryAuthorizationContext";
import ProductionPlanProvider from "@/pages/PlanoProducao/context";
import ResourceProvider from "@/pages/Recursos/context";
import { SimulationProductionProvider } from "@/pages/Simulacoes/context";
import PackagingSimulationProvier from "@/pages/SimulacoesEmbalagem/context";
import PackagingSimulationSidebarProvider from "@/pages/SimulacoesEmbalagem/context/SidebarContext";
import SimulatorProvider from "@/pages/Simuladores/context";
import PackagingSimulatorProvider from "@/pages/SimuladoresEmbalagem/context";
import ResourceTypeProvider from "@/pages/TipoRecurso/context";
import TransporterProvider from "@/pages/Transportador/context";
import { ReactChildrenType } from "@/types";

export default function Providers({ children }: ReactChildrenType) {
  return (
    <LoadingProvider>
      <AlertDialogProvider>
        <FactoryProvider>
          <ResourceProvider>
            <FactoryResourceProvider>
              <IndisponibilityResourceProvider>
                <SimulationProductionProvider>
                  <SimulatorProvider>
                    <FactoryResourceProductProvider>
                      <ResourceTypeProvider>
                        <InventoryProvider>
                          <PackagingSimulationProvier>
                            <PackagingSimulatorProvider>
                              <PackagingResourceProvider>
                                <ProductionPlanProvider>
                                  <PackagingPlanProvider>
                                    <PackagingSimulationSidebarProvider>
                                      <CargoModelProvider>
                                        <DeliveryAuthorizationProvider>
                                          <TransporterProvider>
                                            {children}
                                          </TransporterProvider>
                                        </DeliveryAuthorizationProvider>
                                      </CargoModelProvider>
                                    </PackagingSimulationSidebarProvider>
                                  </PackagingPlanProvider>
                                </ProductionPlanProvider>
                              </PackagingResourceProvider>
                            </PackagingSimulatorProvider>
                          </PackagingSimulationProvier>
                        </InventoryProvider>
                      </ResourceTypeProvider>
                    </FactoryResourceProductProvider>
                  </SimulatorProvider>
                </SimulationProductionProvider>
              </IndisponibilityResourceProvider>
            </FactoryResourceProvider>
          </ResourceProvider>
        </FactoryProvider>
      </AlertDialogProvider>
    </LoadingProvider>
  );
}
