import { Link } from "react-router-dom";
import { DropdownMenuItem } from "../ui/dropdown-menu";
import Row from "../Row";
import { FaEye } from "react-icons/fa";
import Text from "../Text/Text";
import { TEXT_COLOR } from "@/constants";

export default function VisualizeItem({
  id,
  onClick,
}: {
  id: string | number;
  onClick?: () => void;
}) {
  return (
    <DropdownMenuItem onClick={onClick}>
      <Link to={"visualizar/" + id}>
        <Row>
          <FaEye className="mr-2" color={TEXT_COLOR} />
          <Text>Visualizar</Text>
        </Row>
      </Link>
    </DropdownMenuItem>
  );
}
