import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useState } from "react";
import CreateFactoryButton from "../Button/CreateFactoryButton";
import CreateFactoryForm from "../Form/CreateFactoryForm";

export default function CreateFabricDialog() {
  const [open, setOpen] = useState(false);

  function handleDialog() {
    setOpen((prev) => !prev);
  }

  return (
    <Dialog onOpenChange={handleDialog} open={open}>
      <DialogTrigger>
        <CreateFactoryButton />
      </DialogTrigger>
      <DialogContent>
        <CreateFactoryForm handleModal={() => handleDialog()} />
      </DialogContent>
    </Dialog>
  );
}
