import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { ItemProps } from "./EditItem";
import { FaDownload } from "react-icons/fa";
import Text from "@/components/Text/Text";
import { TEXT_COLOR } from "@/constants";
import { exportToExcel } from "@/utils/excel";
import { fetchEstimateProductDetail } from "@/hooks/useEstimateProductDetail";
import { useState } from "react";
import { validationError } from "@/utils/api";

export default function DownloadItem({
  SK_planejamento_demanda,
  ...props
}: ItemProps) {
  const [isFetching, setIsFetching] = useState(false);
  async function handleDownloadXlsx() {
    if (isFetching) return;
    setIsFetching(true);
    try {
      const { data: estimateProductDetail } = await fetchEstimateProductDetail({
        id: SK_planejamento_demanda,
      });
      exportToExcel(
        props.nome_planejamento,
        ["SK_planejamento_demanda_detalhes"],
        { SK_planejamento_demanda, ...props },
        estimateProductDetail
      );
    } catch (error) {
      return validationError(error);
    } finally {
      setIsFetching(false);
    }
  }

  return (
    <DropdownMenuItem onClick={handleDownloadXlsx}>
      <FaDownload className="mr-2" color={TEXT_COLOR} />
      <Text>Download</Text>
    </DropdownMenuItem>
  );
}
