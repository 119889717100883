import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { packagingSimulatorKeys } from "./usePackagingSimulators";

export function usePackagingDeleteMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return instance.delete("/planningbeer/packagingSimulator", {
        data: { id },
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: packagingSimulatorKeys.all,
      });
    },
  });
}
