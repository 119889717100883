import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function VisualizeItem({ id }: { id: string | number }) {
  return (
    <DropdownMenuItem>
      <Link to={"visualizar/" + id}>
        <Row>
          <FaEye className="mr-2" color={TEXT_COLOR} />
          <Text>Visualizar</Text>
        </Row>
      </Link>
    </DropdownMenuItem>
  );
}
