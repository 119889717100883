import { ReactChildrenType } from "@/types";
import { createContext, useContext } from "react";

type TransporterContextProps = {
  visibleDialog: boolean;
  handleDialog: () => void;
};

const TransporterContext = createContext({} as TransporterContextProps);

export default function TransporterProvider({ children }: ReactChildrenType) {
  return (
    <TransporterContext.Provider
      value={{ handleDialog: () => {}, visibleDialog: false }}
    >
      {children}
    </TransporterContext.Provider>
  );
}

export function useTransporterContext() {
  return useContext(TransporterContext);
}
