import Col from "@/components/Col";
import Text from "@/components/Text/Text";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Separator } from "@/components/ui/separator";

export default function ForecastAccordion() {
  return (
    <Accordion
      type="single"
      collapsible
      className="border-1  border w-full rounded-md"
    >
      <AccordionItem value="item-1">
        <AccordionTrigger className="pb-0 px-4 mt-4">
          <Text className="text-base">Rotina - Estimativa de Demandas</Text>
        </AccordionTrigger>
        <Separator className="mt-4" />
        <AccordionContent>
          <Accordion type="single" collapsible className="px-8">
            <AccordionItem value="item-2">
              <AccordionTrigger className="pb-0 mt-4">
                <Text className="font-bold text-base">
                  Utilização da rotina pelo usuário do tipo Comercial
                  Operacional
                </Text>
              </AccordionTrigger>
              <AccordionContent>
                <Text className="mt-2 mb-2">
                  Este vídeo é destinado aos usuários do tipo Comercial
                  Operacional, que são responsáveis por carregar e editar
                  estimativas de demanda no sistema.
                </Text>
                <Col>
                  <video controls width={450}>
                    <source
                      src="/Pilar_Comercial_Operacional.mp4"
                      type="video/mp4"
                    />
                  </video>
                </Col>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </AccordionContent>
        <Separator />
        <AccordionContent>
          <Accordion type="single" collapsible className="px-8">
            <AccordionItem value="item-2">
              <AccordionTrigger className="pb-0 mt-4">
                <Text className="font-bold text-base">
                  Utilização da rotina pelo usuário do tipo Comercial Gerencial
                </Text>
              </AccordionTrigger>
              <AccordionContent>
                <Text className="mt-2 mb-2">
                  Este vídeo é destinado aos usuários do tipo Comercial
                  Gerencial, que são responsáveis por publicar ou rejeitar
                  estimativas de demanda no sistema.
                </Text>
                <Col>
                  <video controls width={450}>
                    <source
                      src="/Pilar_Comercial_Gerencial.mp4"
                      type="video/mp4"
                    />
                  </video>
                </Col>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
