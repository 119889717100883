import instance from "@/api/business";
import { useMutation } from "@tanstack/react-query";

type BuildLoadMutationType = {
  fkPackagingPlan: number;
  structure: string;
  observation?: string;
};

export default function useBuildLoadMutation() {
  return useMutation({
    mutationFn: async (data: BuildLoadMutationType) => {
      await instance.put("/planningbeer/packagingPlan/buildLoad", {
        ...data,
      });
    },
  });
}
