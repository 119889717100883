import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import { useQuery } from "@tanstack/react-query";

export type SupplierType = {
  SK_fornecedor: string;
  nome_reduzido: string;
  codigo: string;
  ativo: number;
};

export const supplierKeys = {
  all: ["suppliers"] as const,
};

async function fetchSuppliers() {
  const response = await instance.post<ApiResponse<SupplierType>>(
    "/planningbeer/supplier"
  );
  return response.data;
}

export function useSuppliers() {
  return useQuery({
    queryKey: supplierKeys.all,
    queryFn: () => fetchSuppliers(),
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
  });
}
