import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SelectProps } from "@radix-ui/react-select";

export const VIEW_SELECTED = "only_selected";
export const VIEW_ALL = "all";

export default function SeeSelectSelect(props: SelectProps) {
  return (
    <Select
      defaultValue={VIEW_ALL}
      value={props.value}
      onValueChange={props.onValueChange}
    >
      <SelectTrigger>
        <SelectValue></SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={VIEW_ALL}>Todos</SelectItem>
        <SelectItem value={VIEW_SELECTED}>Apenas selecionados</SelectItem>
      </SelectContent>
    </Select>
  );
}
