import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import SendButton from "../Button/SendButton";
import UploadFileForm from "../Form/UploadSheetForm";
import { useState } from "react";

export default function UploadDialog() {
  const [open, setOpen] = useState(false);

  function handleDialog() {
    setOpen((prev) => !prev);
  }

  return (
    <Dialog onOpenChange={handleDialog} open={open}>
      <DialogTrigger>
        <SendButton />
      </DialogTrigger>
      <DialogContent>
        <UploadFileForm handleModal={() => handleDialog()} />
      </DialogContent>
    </Dialog>
  );
}
