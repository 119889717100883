import { ResourcesType } from "@/hooks/useResources";
import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";

type DialogTypeType = "edit" | "delete" | "nonSelected";

type ResourceContextProps = {
  handleDialog: () => void;
  handleDialogType: (type: DialogTypeType) => void;
  handleResource: (factory: ResourcesType) => void;
  clear: () => void;
  visibleDialog: boolean;
  dialogType: DialogTypeType;
  resource: ResourcesType;
};

const ResourceContext = createContext({} as ResourceContextProps);

export default function ResourceProvider({ children }: ReactChildrenType) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [resource, setResource] = useState<ResourcesType>({} as ResourcesType);
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handleResource(value: ResourcesType) {
    setResource(value);
  }

  function clear() {
    setVisibleDialog(false);
    setResource({} as ResourcesType);
    setDialogType("nonSelected");
  }

  return (
    <ResourceContext.Provider
      value={{
        clear,
        handleDialogType,
        handleDialog,
        handleResource,
        resource,
        visibleDialog,
        dialogType,
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
}

export function useResourceContext() {
  return useContext(ResourceContext);
}
