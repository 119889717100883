import { useForm } from "react-hook-form";
import SimulationForm from "..";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema, SimulationFormType } from "../zod";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import { useEffect } from "react";
import dayjs from "dayjs";
import useProductionSimulationMutation, {
  UseProductionSimulationMutationType,
} from "@/hooks/useProductionSimulationMutation";
import { DialogProps } from "@/types";
import { validationError } from "@/utils/api";

export default function EditSimulationForm({ handleClose }: DialogProps) {
  const {
    simulationData,
    handleSimulationData,
    production: { handleAccumulatedProductionArray },
  } = useSimulationProductionContext();
  const { mutateAsync, isPending } = useProductionSimulationMutation();
  const { simulador_producao, observacao } = simulationData;
  const form = useForm<SimulationFormType>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  async function onSubmit(data: SimulationFormType) {
    if (isPending) return;
    const initialValidity = dayjs(data.periodo_inicio).format("YYYY-MM-DD");
    const finalValidity = dayjs(data.periodo_fim).format("YYYY-MM-DD");

    const correctedData: UseProductionSimulationMutationType = {
      FK_fabrica: data.FK_fabrica,
      periodo_inicio: initialValidity,
      periodo_fim: finalValidity,
      produtos_selecionados: data.produtos,
      recursos_selecionados: data.recursos,
      observacao: data.observacao,
    };

    try {
      const { data } = await mutateAsync(correctedData);
      handleSimulationData(data);
      handleAccumulatedProductionArray(data.producao_real_acumulada || []);
      form.reset();
      return handleClose();
    } catch (error) {
      return validationError(error);
    }
  }

  useEffect(() => {
    const {
      produtos_selecionados,
      periodo_fim,
      periodo_inicio,
      FK_fabrica,
      recursos_selecionados,
    } = simulador_producao;
    const finalPeriod = dayjs(periodo_fim);
    const initialPeriod = dayjs(periodo_inicio);

    form.reset({
      FK_fabrica: FK_fabrica,
      periodo_inicio: initialPeriod.toDate(),
      periodo_fim: finalPeriod.toDate(),
      produtos: produtos_selecionados,
      recursos: recursos_selecionados,
      observacao: observacao,
    });
  }, []);

  return (
    <SimulationForm
      form={form}
      onSubmit={onSubmit}
      button={{ disabled: isPending, title: "Gerar" }}
    />
  );
}
