import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormValues } from "../components/Form/zod";
import { cargoModelKey } from "./useCargoModels";

export function useCargoModelMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: FormValues) => {
      await instance.put("/planningbeer/cargoModel", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cargoModelKey.all });
    },
  });
}
