/* eslint-disable react-hooks/rules-of-hooks */
import { cn } from "@/lib/utils";
import { Cell, Table, flexRender } from "@tanstack/react-table";
import { ChevronDown, ChevronRight, CornerDownRight } from "lucide-react";
import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import { getCommonPinningStyles } from "../../utils";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Text from "@/components/Text/Text";
import Row from "@/components/Row";

interface CellProps<TData> {
  table: Table<TData>;
  cell: Cell<TData, any>;
  isRowSelected: boolean;
}

const TableCell = <T,>({ cell, table, isRowSelected }: CellProps<T>) => {
  const updatedData = table.options.meta?.updatedData || [];
  const isGrouped = cell.column.columnDef?.meta?.row?.isGrouped;
  const isCellEditable = cell.column?.columnDef?.meta?.enableColumnEditable;
  const inputType = cell.column?.columnDef?.meta?.inputType;
  const selectOptions = cell.column?.columnDef?.meta?.selectOptions || [];
  const formatterFn = cell.column.columnDef.meta?.cell?.formatterFn;
  const customRenderer = cell.column.columnDef.meta?.cell?.customRenderer;
  const customCellShell = cell.column.columnDef.meta?.cell?.customCellShell;

  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2s ease-in-out",
    width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };

  const renderContent = (): ReactNode => {
    function isUpdatedValue() {
      return updatedData.some(
        (item) => item.id === cell.row.id && item.columnId === cell.column.id
      );
    }

    if (cell.getIsGrouped() || isGrouped) {
      const toggle = cell.row.getToggleExpandedHandler();

      return (
        <div className="flex items-center overflow-hidden">
          {cell.row.depth !== 0 && !cell.row.getCanExpand() ? (
            <div className="px-2">
              <CornerDownRight className="text-neutral-500" size={12} />
            </div>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                toggle();
              }}
              className={cn(
                "px-2 cursor-pointer",
                isGrouped && cell.row.depth === 0 ? "text-primary-500" : ""
              )}
            >
              {cell.row.getIsExpanded() ? (
                <ChevronDown size={16} />
              ) : (
                <ChevronRight size={16} />
              )}
            </button>
          )}
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </div>
      );
    }

    if (isCellEditable) {
      const editableColumnType =
        cell.column?.columnDef?.meta?.editableColumnType;

      cell.column.columnDef.cell = ({
        getValue,
        row,
        column: { id: colId, columnDef },
        table,
      }) => {
        const selectRef = useRef<HTMLSelectElement | null>(null);
        const inputRef = useRef<HTMLInputElement | null>(null);
        const initialValue = getValue();
        const [value, setValue] = useState(initialValue || "");
        const [isEditing, setIsEditing] = useState(false);

        const onBlur = () => {
          if (table.options.meta?.updateData) {
            table.options.meta.updateData(
              row.id,
              colId as keyof T,
              value,
              columnDef
            );
          }
        };
        useEffect(() => {
          setValue(initialValue || "");
        }, [initialValue]);

        useEffect(() => {
          if (isEditing) {
            inputRef.current?.focus();
            selectRef.current?.focus();
          }
        }, [isEditing]);

        if (isEditing) {
          switch (inputType) {
            case "number":
              return (
                <input
                  ref={inputRef}
                  className="w-full px-2 py-1 text-xs border"
                  value={value as string}
                  type="number"
                  onChange={(e) => setValue(e.target.value)}
                  onBlur={() => {
                    onBlur();
                    setIsEditing(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onBlur();
                    }
                  }}
                />
              );
            case "select":
              return (
                <select
                  className="p-2"
                  ref={selectRef}
                  defaultValue={value as string}
                  onChange={(e) => setValue(e.target.value)}
                  onBlur={() => {
                    onBlur();
                    setIsEditing(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onBlur();
                    }
                  }}
                >
                  {selectOptions.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      className="p-2"
                    >
                      {option.key}
                    </option>
                  ))}
                </select>
              );
            default:
              return (
                <input
                  ref={inputRef}
                  className="w-full px-2 py-1 text-xs border"
                  value={value as string}
                  type={editableColumnType || "text"}
                  onChange={(e) => setValue(e.target.value)}
                  onBlur={() => {
                    onBlur();
                    setIsEditing(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onBlur();
                    }
                  }}
                />
              );
          }
        }
        return (
          <div
            className="w-full h-full p-2 overflow-hidden cursor-pointer text-ellipsis whitespace-nowrap"
            tabIndex={0}
            onClick={() => setIsEditing(true)}
            onFocus={() => setIsEditing(true)}
          >
            {formatterFn ? formatterFn({ value, row }) : value}
          </div>
        );
      };

      return (
        <Row className="w-full" align="center" justify="space-between">
          <Text style={{ color: isUpdatedValue() ? "#FAA307" : "black" }}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Text>
          <div>
            {customRenderer?.({ row: cell.row, value: cell.getValue() })}
          </div>
        </Row>
      );
    }

    return (
      <div className="flex items-center w-full h-full overflow-hidden">
        <div className="flex items-center w-full h-full overflow-hidden text-ellipsis whitespace-nowrap">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </div>
      </div>
    );
  };

  if (customCellShell) {
    return customCellShell({
      row: cell.row,
      value: cell.getValue(),
      shell: {
        className: cn(
          "flex border-r first-of-type:border-l border-b group-hover:border-white  border-neutral-200 items-center py-1.5 px-4 w-full text-xs",
          isRowSelected ? "border-white" : "",
          !table.options.meta?.layout || table.options.meta.layout === "default"
            ? ""
            : "last-of-type:flex-1",
          cell.column.columnDef.meta?.cell?.className ||
            table.options.meta?.cell?.className
        ),
        content: renderContent(),
        ref: setNodeRef,
        style: {
          width: cell.column.getSize(),
          paddingLeft:
            cell.getIsGrouped() || isGrouped ? `${cell.row.depth * 2}rem` : "",
          ...style,
          ...getCommonPinningStyles(cell.column),
        },
      },
    });
  }

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "flex border-r first-of-type:border-l border-b group-hover:border-white  border-neutral-200 items-center py-1.5 px-4 w-full text-xs",
        isRowSelected ? "border-white" : "",
        !table.options.meta?.layout || table.options.meta.layout === "default"
          ? ""
          : "last-of-type:flex-1",
        cell.column.columnDef.meta?.cell?.className ||
          table.options.meta?.cell?.className
      )}
      style={{
        width: cell.column.getSize(),
        paddingLeft:
          cell.getIsGrouped() || isGrouped ? `${cell.row.depth * 2}rem` : "",
        ...style,
        ...getCommonPinningStyles(cell.column),
      }}
    >
      {renderContent()}
    </div>
  );
};

export default TableCell;
