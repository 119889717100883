import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useQueryClient } from "@tanstack/react-query";
import { configurationKey, ConfigurationsType } from "@/hooks/useConfiguration";
import { CONFIGURATION_TABLES_CODE } from "@/constants";
import Each from "../Each";

type ResourceTypeData = {
  data: Array<ConfigurationsType>;
};

export default function ResourceTypeSelect(props: SelectProps) {
  const queryClient = useQueryClient();

  const resourceTypeData: ResourceTypeData | undefined =
    queryClient.getQueryData(
      configurationKey.table(CONFIGURATION_TABLES_CODE.dim_tipo_recursos.codigo)
    );
  const { data: resourceType } = resourceTypeData || {};
  if (!resourceType) return null;

  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={resourceType}
          renderItem={({ valor }) => {
            return <SelectItem value={valor}>{valor}</SelectItem>;
          }}
        />
      </SelectContent>
    </Select>
  );
}
