import { Dialog, DialogContent } from "@/components/ui/dialog";
import { DialogProps } from "@/types";
import Steps from "../Steps";
import { useState } from "react";
import NewInventoryPolicyContent from "./Content/NewInventoryPolicyContent";
import InformationContent from "./Content/InformationContent";
import PackagingContent from "./Content/PackagingContent";

type AdditionalInformationModalProps = {
  product: {
    code: string;
    description: string;
  };
} & DialogProps;

export default function AdditionalInformationModal({
  handleClose,
  visible,
  product: { code, description },
}: AdditionalInformationModalProps) {
  const [step, setStep] = useState(0);

  const stepItems = {
    0: <InformationContent product={{ code, description }} />,
    1: (
      <NewInventoryPolicyContent
        product={{ code, description }}
        onClose={onClose}
      />
    ),
    2: <PackagingContent product={{ code, description }} onClose={onClose} />,
  };

  function onClose() {
    setStep(0);
    handleClose();
  }

  return (
    <Dialog onOpenChange={onClose} open={visible}>
      <DialogContent className="flex flex-col max-w-full h-full w-11/12 px-0">
        <Steps selectedStep={step} handleStep={(step) => setStep(step)} />
        {stepItems[step]}
      </DialogContent>
    </Dialog>
  );
}
