import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import LoadPackagingStep from "../Steps/BuildLoad/LoadPackaging";
import { MdPallet } from "react-icons/md";
import { TEXT_COLOR } from "@/constants";
import Row from "@/components/Row";
import { useState } from "react";

export default function BuildLoadModal() {
  const [visible, setVisible] = useState(false);
  const steps = [
    <LoadPackagingStep
      handleClose={() => {
        setVisible(!visible);
      }}
    />,
  ];

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant="link">
          <Row>
            <MdPallet color={TEXT_COLOR} size={20} />
            <Text className="ml-1">Gerar Simulaçao de Carga</Text>
          </Row>
        </Button>
      </DialogTrigger>
      <DialogContent className="h-full w-11/12 max-w-full">
        {steps[0]}
      </DialogContent>
    </Dialog>
  );
}
