import Table from "@/components/NewTable";
import { TableData } from "@/components/NewTable/type";
import { FILIAL_OPTIONS, STATE_OPTIONS } from "@/constants";
import { SeeEstimateDetailType } from "@/hooks/useEstimateProductDetail";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import TableContainer from "@/components/Container/TableContainer";

const columnHelper = createColumnHelper<SeeEstimateDetailType>();

type TablePropsType = TableData<SeeEstimateDetailType>;

type ProductTableType = {
  data: Array<SeeEstimateDetailType>;
};

export const TABLE_ORDER_KEY = "seeEstimateProductDetailTableOrder";

export default function ProductTable({ data }: ProductTableType) {
  const columns = [
    columnHelper.accessor("SK_planejamento_demanda_detalhes", {
      header: "ID",
      id: "SK_planejamento_demanda_detalhes",
    }),
    columnHelper.accessor("Código", {
      header: "Código do Produto",
      id: "FK_produto",
      size: 300,
      cell: ({ getValue }) => {
        return <span className="p-2">{getValue()}</span>;
      },
    }),
    columnHelper.accessor("Descrição", {
      header: "Descrição",
      id: "Descrição",
      size: 400,
    }),
    columnHelper.accessor("Quantidade", {
      header: "Quantidade",
      id: "Quantidade",
      size: 200,
      meta: {
        cell: {
          formatterFn: ({ value }) =>
            Intl.NumberFormat("pt-BR").format(Number(value)),
        },
        inputType: "number",
        enableColumnEditable: false,
      },
    }),
    columnHelper.accessor("Filial_Origem", {
      header: "Origem",
      id: "Filial_Origem",
      meta: {
        enableColumnEditable: false,
        inputType: "select",
        selectOptions: FILIAL_OPTIONS,
      },
    }),
    columnHelper.accessor("Destino", {
      header: "Destino",
      id: "Destino",
      meta: {
        selectOptions: STATE_OPTIONS,
        inputType: "select",
        enableColumnEditable: false,
      },
    }),
    columnHelper.accessor("Vigência", {
      header: "Vigência",
      id: "Vigência",
      size: 150,
    }),
    columnHelper.accessor("Status", {
      header: "Status",
      id: "Status",
      size: 150,
    }),
    columnHelper.accessor("Tipo_Produto", {
      header: "Tipo de Produto",
      id: "Tipo_Produto",
      size: 150,
    }),
    columnHelper.accessor("Grupo", {
      id: "Grupo",
      header: "Grupo",
    }),
    columnHelper.accessor("Classe_Produto", {
      header: "Classe do Produto",
      id: "Classe_Produto",
    }),
    columnHelper.accessor("Classificação", {
      header: "Classificação",
      id: "Classificação",
    }),
    columnHelper.accessor("Litragem", {
      header: "Litragem",
      id: "Litragem",
    }),
    columnHelper.accessor("Tipo_Líquido", {
      header: "Tipo de Líquido",
      id: "Tipo_Líquido",
    }),
    columnHelper.accessor("Volume", {
      header: "Volume",
      id: "Volume",
    }),
    columnHelper.accessor("Formação", {
      header: "Formação",
      id: "Formação",
    }),
    columnHelper.accessor("Camadas", {
      header: "Camadas",
      id: "Camadas",
    }),
    columnHelper.accessor("Lastro", {
      header: "Lastro",
      id: "Lastro",
    }),
  ];

  const [columnOrder, setColumnOrder] =
    useState<ColumnOrderState>(getColumnOrder());

  function getColumnOrder() {
    const demandColumnOrder = localStorage.getItem(TABLE_ORDER_KEY);
    if (demandColumnOrder) {
      return JSON.parse(demandColumnOrder);
    }
    return columns.map((column) => column.id || "");
  }

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  return (
    <TableContainer className="max-h-[calc(100vh-190px)] animated-opacity-element">
      <Table<TablePropsType>
        data={data}
        onColumnOrderStateChange={handleColumnOrder}
        columnOrderState={columnOrder}
        columns={columns}
        getRowId={(row) => row.SK_planejamento_demanda_detalhes.toString()}
        meta={{
          layout: "stretch",
        }}
      />
    </TableContainer>
  );
}
