import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogPortal } from "@/components/ui/dialog";
import { ROUTES, TEXT_COLOR } from "@/constants";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useProductionPlanContext } from "../../context";
import { useProductionPlanVersionPatchMutation } from "@/hooks/useProductionPlanVersionPatchMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import { VersionDropdownProps } from "../Dropdown/VersionDropdown";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import { useNavigate } from "react-router-dom";

type EditCurrentVersionDialogProps = {
  open: boolean;
  onOpenChange: () => void;
} & VersionDropdownProps;

export default function EditCurrentVersionDialog({
  onOpenChange,
  open,
  revisao,
  valores_linhas,
}: EditCurrentVersionDialogProps) {
  const navigate = useNavigate();
  const {
    productionPlan: { SK_plano_producao },
    clear: clearProductionPlan,
  } = useProductionPlanContext();
  const { clear: clearSimulationProduction } = useSimulationProductionContext();
  const { mutateAsync, isPending } = useProductionPlanVersionPatchMutation();

  async function handleEditCurrentVersion() {
    if (isPending) return;
    try {
      await mutateAsync({
        lineValues: JSON.stringify(valores_linhas) || "[]",
        revision: revisao,
        skProductionPlan: SK_plano_producao,
      });
      toast.success("Versão alterada com sucesso!");
      navigate("/" + ROUTES.productionPlan.index);
      clearProductionPlan();
      clearSimulationProduction();
      return onOpenChange();
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogPortal>
        <DialogContent>
          <Row>
            <IoAlertCircleOutline size={35} color={TEXT_COLOR} />
            <Text className="font-bold mt-1 ml-1 text-lg">
              Mudança de Versão
            </Text>
          </Row>
          <Text className="mt-4 text-sm">
            Você tem certeza de que deseja alterar a versão do plano de
            produção? Ao prosseguir, todas as versões posteriores serão
            invalidadas e não poderão ser utilizadas novamente, os valores
            preenchidos não serão utilizados em novas simulações ou edição do
            plano de produção. Esta ação é irreversível.
          </Text>
          <Row justify="end" className="gap-2">
            <Button onClick={onOpenChange} disabled={isPending}>
              <Text color="white">Cancelar</Text>
            </Button>
            <Button
              variant="outline"
              disabled={isPending}
              onClick={handleEditCurrentVersion}
            >
              <Text>Confirmar Mudança</Text>
            </Button>
          </Row>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
