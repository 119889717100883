import ChangePasswordCard from "@/components/Card/ChangePasswordCard";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";

export default function ChangePassword() {
  return (
    <AuthenticatedLayout>
      <div className="w-full h-full flex items-center justify-center mb-20">
        <ChangePasswordCard />
      </div>
    </AuthenticatedLayout>
  );
}
