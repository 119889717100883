import { InventoryTableType } from "@/hooks/useInventory";
import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";

type DialogTypeType = "edit" | "delete" | "detail" | "nonSelected";

type InventoryContextProps = {
  visibleDialog: boolean;
  handleDialog: () => void;
  dialogType: DialogTypeType;
  handleDialogType: (type: DialogTypeType) => void;
  inventory: InventoryTableType;
  handleInventory: (factory: InventoryTableType) => void;
  clear: () => void;
};

const InventoryContext = createContext({} as InventoryContextProps);

export default function InventoryProvider({ children }: ReactChildrenType) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [inventory, setInventory] = useState<InventoryTableType>(
    {} as InventoryTableType
  );
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handleInventory(value: InventoryTableType) {
    setInventory(value);
  }

  function clear() {
    setInventory({} as InventoryTableType);
    setDialogType("nonSelected");
    setVisibleDialog(false);
  }

  return (
    <InventoryContext.Provider
      value={{
        clear,
        handleDialogType,
        handleDialog,
        handleInventory: handleInventory,
        inventory: inventory,
        visibleDialog,
        dialogType,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useInventoryContext() {
  return useContext(InventoryContext);
}
