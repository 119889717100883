import Text from "@/components/Text/Text";
import { SidebarItemProps } from "./SidebarItems";
import { Link } from "react-router-dom";
import Row from "@/components/Row";
import { cn } from "@/lib/utils";
import { setItem } from "@/utils/storage";

export default function Item({
  label = "",
  path = "/",
  icon,
  onClick,
  visible = true,
}: SidebarItemProps) {
  function handleActiveKey() {
    setItem(sessionStorage, "activeKey", label);
  }

  if (!visible) return null;
  return (
    <Link
      to={path}
      className={cn("hover:cursor-pointer")}
      onClick={onClick ?? handleActiveKey}
    >
      <Row align="center">
        {icon}
        <Text className="ml-2 mt-1 text-sm">{label}</Text>
      </Row>
    </Link>
  );
}
