import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import {
  factoryResourceKey,
  FactoryResourceType,
} from "./useFactoriesResources";
import { useQuery } from "@tanstack/react-query";

export async function fetchFactoryResource({ id }: { id: number }) {
  if (!id) return;
  const response = await instance.post<ApiResponse<FactoryResourceType>>(
    "/planningbeer/factoryResource/detail",
    {
      id,
    }
  );

  return response.data;
}

export function useFactoryResource({ id }: { id: number }) {
  return useQuery({
    queryKey: factoryResourceKey.show(id),
    queryFn: () => fetchFactoryResource({ id }),
  });
}
