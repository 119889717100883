import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export default function EditEstimateFieldTooltip({
  value,
  lastValue,
}: {
  value: string | number;
  lastValue: string | number;
}) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <p>{value} *</p>
        </TooltipTrigger>
        <TooltipContent side="right">
          <p>Valor anterior: {lastValue}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
