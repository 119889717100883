import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import CreateResourceTypeDialog from "./components/Dialog/CreateResourceTypeDialog";
import {
  ConfigurationsType,
  useConfigurations,
} from "@/hooks/useConfiguration";
import { CONFIGURATION_TABLES_CODE } from "@/constants";
import { createColumnHelper } from "@tanstack/react-table";
import { TableData } from "@/components/NewTable/type";
import Table from "@/components/NewTable";
import Can from "@/components/Can";
import ActionCell from "@/components/Cell/ActionCell";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import EditResourceTypeDialog from "./components/Dialog/EditResourceTypeDialog";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import { useResourceTypeContext } from "./context";
import useConfigurationMutationDeleteMutation from "@/hooks/useConfigurationMutationDeleteMutation";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import TableContainer from "@/components/Container/TableContainer";

const columnHelper = createColumnHelper<ConfigurationsType>();
const TABLE_ORDER_KEY = "resourceTypeColumnOrder";

type ResourceTypeTable = TableData<ConfigurationsType>;

export default function ResourceType() {
  const {
    clear,
    visibleDialog,
    dialogType,
    resourceType: { SK_configuracao },
  } = useResourceTypeContext();
  const { mutateAsync, isPending } = useConfigurationMutationDeleteMutation({
    tabela_codigo: CONFIGURATION_TABLES_CODE.dim_tipo_recursos.codigo,
  });
  const { data } = useConfigurations({
    tabela_codigo: CONFIGURATION_TABLES_CODE.dim_tipo_recursos.codigo,
  });
  const { data: resourceTypes = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_configuracao", {
      id: "SK_configuracao",
      header: "ID",
      size: 100,
    }),
    columnHelper.accessor("valor", {
      id: "valor",
      header: "Nome",
      size: 1200,
    }),
    columnHelper.display({
      header: "Ações",
      id: "Ações",
      size: 100,
      cell: ({ row }) => {
        const { pode_editar } = row.original;

        function canChange(value?: string) {
          if (!value) return false;
          return true;
        }
        return (
          <Can
            condition={canChange(pode_editar)}
            onTrue={<ActionCell items={<ActionDropdown {...row.original} />} />}
            onFalse={<div className="py-5" />}
          />
        );
      },
    }),
  ];

  async function handleDelete() {
    if (isPending) return;
    try {
      await mutateAsync({ id: Number(SK_configuracao) });
      clear();
      return toast.success("Tipo de recurso excluído com sucesso!");
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <AuthenticatedLayout>
      <Row justify="end">
        <CreateResourceTypeDialog />
      </Row>
      <TableContainer>
        <Table<ResourceTypeTable>
          columns={columns}
          data={resourceTypes}
          columnOrderKey={TABLE_ORDER_KEY}
          meta={{ layout: "stretch" }}
        />
      </TableContainer>
      <EditResourceTypeDialog />
      <DeleteDialogAlert
        onConfirm={handleDelete}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        onCancel={clear}
        type={dialogType}
        alertDialogTitle={`Deseja excluir o tipo de recurso, "${SK_configuracao}"?. Essa ação é irreversível.`}
      />
    </AuthenticatedLayout>
  );
}
