import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import EditDropdownItem from "@/components/DropdownItem/EditDropdownItem";
import { PackagingSimulatorsType } from "@/hooks/usePackagingSimulators";
import React from "react";
import { Link } from "react-router-dom";
import { usePackagingSimulatorContext } from "../../context";
import PublishDropdownItem from "./PublishDropdownItem";
import RejectDropdownItem from "./RejectDropdownItem";
import ApproveDropdownItem from "./ApproveDropdownItem";
import Row from "@/components/Row";
import { FaEye } from "react-icons/fa6";
import { TEXT_COLOR } from "@/constants";
import Text from "@/components/Text/Text";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { MdHistoryEdu } from "react-icons/md";

type ActionDropdownProps = PackagingSimulatorsType;

export default function ActionDropdown(props: ActionDropdownProps) {
  const { handleDialogType, handleSimulator, handleDialog } =
    usePackagingSimulatorContext();

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handleSimulator({ ...props });
  }

  function handleHistory() {
    handleDialog();
    handleDialogType("history");
    handleSimulator({ ...props });
  }

  function renderAction(status: PackagingSimulatorsType["status"]) {
    if (status == "RASCUNHO") {
      return (
        <>
          <DropdownMenuItem>
            <Link to={"visualizar/" + props.SK_simulacao_embalagem}>
              <Row>
                <FaEye className="mr-2" color={TEXT_COLOR} />
                <Text>Visualizar</Text>
              </Row>
            </Link>
          </DropdownMenuItem>
          <Link to={"editar/" + props.SK_simulacao_embalagem}>
            <EditDropdownItem onClick={() => {}} />
          </Link>
          <DropdownMenuItem onClick={handleHistory}>
            <Row align="center" justify="start">
              <MdHistoryEdu color={TEXT_COLOR} size={18} className="mr-1" />
              <Text>Histórico</Text>
            </Row>
          </DropdownMenuItem>
          <PublishDropdownItem
            skPackagingSimulator={props.SK_simulacao_embalagem}
          />
          <DeleteDropdownItem onClick={handleDelete} />
        </>
      );
    }
    if (status == "PUBLICADO") {
      return (
        <>
          <DropdownMenuItem>
            <Link to={"visualizar/" + props.SK_simulacao_embalagem}>
              <Row>
                <FaEye className="mr-2" color={TEXT_COLOR} />
                <Text>Visualizar</Text>
              </Row>
            </Link>
          </DropdownMenuItem>
          <RejectDropdownItem
            skPackagingSimulator={props.SK_simulacao_embalagem}
          />
          <ApproveDropdownItem
            skPackagingSimulator={props.SK_simulacao_embalagem}
          />
          <DropdownMenuItem onClick={handleHistory}>
            <Row align="center" justify="start">
              <MdHistoryEdu color={TEXT_COLOR} size={18} className="mr-1" />
              <Text>Histórico</Text>
            </Row>
          </DropdownMenuItem>
        </>
      );
    }
    if (status == "APROVADO") {
      return (
        <>
          <DropdownMenuItem>
            <Link to={"visualizar/" + props.SK_simulacao_embalagem}>
              <Row>
                <FaEye className="mr-2" color={TEXT_COLOR} />
                <Text>Visualizar</Text>
              </Row>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleHistory}>
            <Row align="center" justify="start">
              <MdHistoryEdu color={TEXT_COLOR} size={18} className="mr-1" />
              <Text>Histórico</Text>
            </Row>
          </DropdownMenuItem>
        </>
      );
    }
    return <></>;
  }

  return <React.Fragment>{renderAction(props.status)}</React.Fragment>;
}
