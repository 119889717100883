import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  configurationKey,
  ConfigurationProps,
  ConfigurationsType,
} from "./useConfiguration";
import instance from "@/api/business";

export default function useConfigurationMutation({
  tabela_codigo,
}: ConfigurationProps) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ConfigurationsType) => {
      return instance.put("/planningbeer/configuration", { ...data });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: configurationKey.table(Number(tabela_codigo)),
      });
    },
  });
}
