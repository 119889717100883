import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { productStockPolicyKeys } from "./useProductStockPolicy";
import { ProductInventoryPolicyFormType } from "@/pages/Products/components/Form/zod";

type ProductStockPolicyPatchMutation = {
  FK_produto: string;
  FK_fabrica: string;
} & ProductInventoryPolicyFormType;

export default function useProductStockPatchMutation() {
  const queryClent = useQueryClient();
  return useMutation({
    mutationFn: (data: ProductStockPolicyPatchMutation) => {
      return instance.patch("/planningbeer/stockProduct", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClent.invalidateQueries({
        queryKey: productStockPolicyKeys.all,
      });
    },
  });
}
