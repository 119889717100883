import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import { FormValues } from "./zod";
import { Input } from "@/components/ui/input";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";

type CargoModelFormProps = {
  form: UseFormReturn<FormValues, any, undefined>;
  onSubmit: (data: FormValues) => void;
};

export function CargoModelForm({ form, onSubmit }: CargoModelFormProps) {
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="nome"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Nome *</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="quantidade_palete"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Quantidade Paletes *</FormLabel>
              <FormControl>
                <Input type="number" {...field} min={0} minLength={0} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row justify="end" className="gap-2">
          <Button type="submit">Salvar</Button>
        </Row>
      </form>
    </Form>
  );
}
