import Row from "@/components/Row";
import ResourceTypeSelect from "@/components/Select/ResourceTypeSelect";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { schema } from "../CreateResourceForm/zod";
import { ResourceFormType } from "../CreateResourceForm";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import { ResourcesType } from "@/hooks/useResources";
import { useEffect } from "react";
import useResourcePatchMutation from "@/hooks/useResourcePatchMutation";
import PackingSelect from "@/components/Select/PackingSelect";

export type EditResourceFormProps = {
  dialogVisible: () => void;
  data: ResourcesType;
};

export default function EditResourceForm({
  data,
  dialogVisible,
}: EditResourceFormProps) {
  const { mutateAsync, isPending } = useResourcePatchMutation();
  const form = useForm<ResourceFormType>({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  async function onSubmit(formData: ResourceFormType) {
    if (isPending) return;
    try {
      await mutateAsync({ SK_recurso: data.SK_recurso, ...formData });
      toast.success("Recurso editado com sucesso!");
      return dialogVisible();
    } catch (error) {
      return validationError(error);
    }
  }

  useEffect(() => {
    form.reset({ ...data });
  }, [data, form]);

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Row className="gap-2">
          <FormField
            control={form.control}
            name="tipo"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>Tipo *</FormLabel>
                <FormControl>
                  <ResourceTypeSelect
                    value={field.value}
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="tipo_de_embalagem"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>Tipo de Embalagem*</FormLabel>
                <FormControl>
                  <PackingSelect
                    value={field.value}
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Row>
        <FormField
          control={form.control}
          name="descricao"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Descrição *</FormLabel>
              <FormControl>
                <Input {...field} maxLength={200} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="definicao"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Abreviatura *</FormLabel>
              <FormControl>
                <Input {...field} maxLength={200} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="codigo_centro_de_custo"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Código do Centro de Custo</FormLabel>
              <FormControl>
                <Input {...field} maxLength={200} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={isPending}>
            <Text color="white">Editar</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
