import Can from "@/components/Can";
import Col from "@/components/Col";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import {
  calculateTotalCargos,
  calculateTotalPallets,
} from "@/pages/SimulacoesEmbalagem/utils/carga";
import { LuAlertTriangle } from "react-icons/lu";
import { SuppliersPackageType } from "../Steps/BuildLoad/LoadPackaging";
import { useMemo } from "react";

type SummaryLoadPackagingContentProps = {
  totalSelectedPallets: number;
  dates: SuppliersPackageType["dates"];
  transporterCargo?: number;
};

export default function SummaryLoadPackagingContent({
  dates,
  totalSelectedPallets,
  transporterCargo,
}: SummaryLoadPackagingContentProps) {
  const totalCargos = transporterCargo
    ? calculateTotalCargos(totalSelectedPallets, Number(transporterCargo))
    : "Não selecionado";

  const isFloatValue =
    typeof totalCargos == "string" ? false : totalCargos % 1 !== 0;

  const totalPallets = useMemo(
    () =>
      dates
        .flatMap((date) => {
          const totalPallets = date.items.map((item) => {
            return calculateTotalPallets(
              Number(item.valor || 0),
              Number(item.quantidade_por_palete || 0)
            );
          });
          return totalPallets;
        })
        .reduce((acc, value) => acc + value, 0),
    [dates]
  );

  return (
    <Col className="mt-2 gap-2">
      <Text>Paletes Selecionados: {totalSelectedPallets}</Text>
      <Text>Total de Paletes: {totalPallets}</Text>
      <Separator />
      <Text>Paletes por Carga: {transporterCargo || 0}</Text>
      <Row>
        <Can
          condition={isFloatValue}
          onTrue={<LuAlertTriangle color="red" className="mr-1" />}
          onFalse={<></>}
        />
        <Text>Cargas: </Text>
        <Text
          className={cn(
            [
              isFloatValue
                ? "bg-red-200 rounded-full w-fit ml-1 text-white"
                : "bg-transparent",
            ],
            "text-sm px-1"
          )}
        >
          {totalCargos}
        </Text>
      </Row>
      <Separator />
    </Col>
  );
}
