import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Checkbox } from "@/components/ui/checkbox";
import React, { memo, useMemo } from "react";
import SelectPackagingTypeSelect from "../Select/SelectPackagingTypeSelect";
import { Separator } from "@/components/ui/separator";
import {
  getPackagingSupplierValues,
  isSelectedPallete,
} from "../../utils/buildLoadUtils";
import { SelectedPalleteType } from "../Steps/BuildLoad/LoadPackaging";

type PalletRowProps = {
  palletNumber: number;
  composition: string;
  day: string;
  fkSupplier: string;
  selectedPallets: SelectedPalleteType[];
  quantityPerPallet?: number | string;
  onCheckPallete: (data: SelectedPalleteType) => void;
  onChangeType: (data: SelectedPalleteType) => void;
};

const PalletRow = ({
  composition,
  day,
  palletNumber,
  selectedPallets,
  fkSupplier,
  quantityPerPallet = 0,
  onChangeType,
  onCheckPallete,
}: PalletRowProps) => {
  const isSelected = useMemo(
    () =>
      isSelectedPallete(selectedPallets, {
        composition: composition,
        day: day,
        pallet: palletNumber,
        supplier: fkSupplier,
      }),
    [selectedPallets]
  );

  const packagingSupplierValue = getPackagingSupplierValues(selectedPallets, {
    composition: composition,
    day: day,
    pallet: palletNumber,
    supplier: fkSupplier,
  });

  return (
    <React.Fragment>
      <Row align="center" justify="space-between" className="p-1">
        <Row className="gap-2">
          <Checkbox
            checked={isSelected}
            onCheckedChange={() => {
              onCheckPallete({
                composition: composition,
                pallet: palletNumber,
                day: day,
                supplier: fkSupplier,
                quantity_per_pallet: Number(quantityPerPallet || 0),
                type: "default",
              });
            }}
          />
          <Text>PLT {palletNumber}</Text>
        </Row>
        <div className="w-fit">
          <SelectPackagingTypeSelect
            defaultValue="default"
            value={packagingSupplierValue?.type || "default"}
            onValueChange={(selectedItem) => {
              onChangeType({
                composition: composition,
                pallet: palletNumber,
                day: day,
                supplier: fkSupplier,
                type: selectedItem,
              });
            }}
          />
        </div>
      </Row>
      <Separator />
    </React.Fragment>
  );
};

export default memo(PalletRow);
