import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import { useQuery } from "@tanstack/react-query";

export type PackagingSimulatorsType = {
  SK_simulacao_embalagem: number;
  FK_simulacao: number;
  abreviatura_empresa: string;
  cnpj: string;
  descricao: string;
  codigo: string;
  periodo_fim: string;
  periodo_inicio: string;
  criado_por_codigo: string;
  criado_por_nome: string;
  observacao_simulador_embalagem: string;
  revisao_simulador_embalagem: string;
  foi_incluido: boolean;
  status: "RASCUNHO" | "PUBLICADO" | "ACEITO" | "APROVADO";
};

export const packagingSimulatorKeys = {
  all: ["packagingSimulators"] as const,
  show: (value: number) => [...packagingSimulatorKeys.all, value] as const,
};

async function fetchPackagingSimulators() {
  const response = await instance.post<ApiResponse<PackagingSimulatorsType>>(
    "/planningbeer/packagingSimulator"
  );

  return response.data;
}

export function usePackagingSimulators() {
  return useQuery({
    queryKey: packagingSimulatorKeys.all,
    queryFn: () => fetchPackagingSimulators(),
    staleTime: 0,
    gcTime: 0,
  });
}
