import { BusinessType } from "@/hooks/useBusiness";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { SelectProps } from "@radix-ui/react-select";
import Each from "../Each";
import { useQueryClient } from "@tanstack/react-query";

type BusinessData = {
  data: Array<BusinessType>;
};

export default function BusinessSelect(props: SelectProps) {
  const queryClient = useQueryClient();

  const businessData: BusinessData | undefined = queryClient.getQueryData([
    "business",
  ]);
  const { data: business } = businessData || {};
  if (!business) return null;

  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={business}
          renderItem={(props) => {
            const businessToJson = {
              SK_EMPRESA: props.SK_EMPRESA,
              ABREVIATURA_EMPRESA: props.ABREVIATURA_EMPRESA,
              CNPJ: props.CNPJ,
            };
            return (
              <SelectItem value={JSON.stringify(businessToJson)}>
                {props.SK_EMPRESA}
                {" - "}
                {props.ABREVIATURA_EMPRESA}
              </SelectItem>
            );
          }}
        />
      </SelectContent>
    </Select>
  );
}
