import { AuthorizationDeliveryType } from "@/hooks/usePackagingSimulationMutation";
import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";

type ContentType = {
  day: string;
  indisponibilityLeadTime: number;
  supplierName: string;
  value: string | number;
  quantityPerPallets: number;
  cargoModelQuantityPallets: number;
  fkModelCargo: string;
  authorizationDelivery: Array<AuthorizationDeliveryType> | [];
};

type PackagingSimulationSidebarProps = {
  visibleSidebar: {
    visible: boolean;
    handleVisible: () => void;
  };
  content: {
    setContent: (data: ContentType) => void;
    getContent: () => ContentType;
  };
};

const PackagingSimulationSidebarContext = createContext(
  {} as PackagingSimulationSidebarProps
);

export default function PackagingSimulationSidebarProvider({
  children,
}: ReactChildrenType) {
  const [content, setContent] = useState({} as ContentType);
  const [visible, setVisible] = useState(false);

  function handleVisible() {
    setVisible(!visible);
  }

  return (
    <PackagingSimulationSidebarContext.Provider
      value={{
        visibleSidebar: {
          handleVisible,
          visible,
        },
        content: {
          setContent,
          getContent: () => content,
        },
      }}
    >
      {children}
    </PackagingSimulationSidebarContext.Provider>
  );
}

export function usePackagingSimulationSidebarContext() {
  return useContext(PackagingSimulationSidebarContext);
}
