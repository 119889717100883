import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useState } from "react";
import CreatePackagingSimulation from "../Form/Create";

export default function CreatePackagingSimulationDialog() {
  const [open, setOpen] = useState(true);

  function handleDialog() {
    setOpen((prev) => !prev);
  }

  return (
    <Dialog onOpenChange={handleDialog} open={open}>
      <DialogTrigger>
        <Button>Gerar Simulação</Button>
      </DialogTrigger>
      <DialogContent>
        <CreatePackagingSimulation handleClose={handleDialog} />
      </DialogContent>
    </Dialog>
  );
}
