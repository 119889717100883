import { Input } from "@/components/ui/input";

function getMinutes(minutes: number) {
  if (!minutes) return;
  if (minutes >= 60) return 0;
  if (minutes < 0) return 0;
  return minutes;
}

type MinuteTimePickerInputProps = {
  onChange: (value) => void;
  value: string | number;
};

export default function MinuteTimePickerInput({
  onChange,
  value,
}: MinuteTimePickerInputProps) {
  return (
    <div className="w-fit">
      <Input
        min={0}
        max={59}
        value={value}
        type="number"
        onChange={({ target: { value } }) => {
          return onChange(getMinutes(Number(value)));
        }}
      />
    </div>
  );
}
