export function quantityFormater(value: number) {
  return Intl.NumberFormat("pt-BR").format(value);
}

const ML_TO_LT = 1000;
const LT_TO_HL = 100;

export function calculateHectoliters(quantidade, volume) {
  return Math.ceil((quantidade * (volume / ML_TO_LT)) / LT_TO_HL);
}
