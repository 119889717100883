import Can from "@/components/Can";
import { DatePicker } from "@/components/DatePicker";
import HourTimePickerInput from "@/components/Input/TimePickerInput/HourTimePickerInput";
import MinuteTimePickerInput from "@/components/Input/TimePickerInput/MinuteTimePickerInput";
import Row from "@/components/Row";
import FactoryResourceSelect from "@/components/Select/FactoryResourceSelect";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";

type IndisponibilityResourceFormProps<T extends FieldValues> = {
  form: UseFormReturn<T, any, undefined>;
  onSubmit: (data: T) => void;
  button?: {
    disabled?: boolean;
    title?: string;
  };
};

export default function IndisponibilityResourceForm<T extends FieldValues>({
  form,
  onSubmit,
  button = {
    disabled: false,
    title: "Cadastrar",
  },
}: IndisponibilityResourceFormProps<T>) {
  const isAllDay = form.watch("dia_todo" as Path<T>) || false;
  const validityStart = form.watch("vigencia_inicio" as Path<T>);

  function resetTime() {
    form.setValue("hora_inicio" as Path<T>, undefined as PathValue<T, Path<T>>);
    form.setValue(
      "minuto_inicio" as Path<T>,
      undefined as PathValue<T, Path<T>>
    );
    form.setValue("hora_fim" as Path<T>, undefined as PathValue<T, Path<T>>);
    form.setValue("minuto_fim" as Path<T>, undefined as PathValue<T, Path<T>>);
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name={"FK_fabrica_recurso" as Path<T>}
          render={({ field }) => (
            <FormItem>
              <FormLabel>ID Recurso Fábrica *</FormLabel>
              <FormControl>
                <FactoryResourceSelect
                  onValueChange={field.onChange}
                  value={field.value}
                  defaultValue={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row className="gap-2">
          <FormField
            control={form.control}
            name={"vigencia_inicio" as Path<T>}
            render={({ field }) => (
              <FormItem className="grow">
                <FormLabel>Vigência Início *</FormLabel>
                <FormControl>
                  <DatePicker onChange={field.onChange} value={field.value} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Can
            condition={!isAllDay}
            onTrue={
              <>
                <FormField
                  control={form.control}
                  name={"hora_inicio" as Path<T>}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Hora *</FormLabel>
                      <HourTimePickerInput
                        onChange={field.onChange}
                        value={field.value}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name={"minuto_inicio" as Path<T>}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Minuto *</FormLabel>
                      <MinuteTimePickerInput
                        onChange={field.onChange}
                        value={field.value}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            }
          />
        </Row>
        <Row className="gap-2">
          <FormField
            control={form.control}
            name={"vigencia_fim" as Path<T>}
            render={({ field }) => (
              <FormItem className="grow">
                <FormLabel>Vigência Fim *</FormLabel>
                <FormControl>
                  <DatePicker
                    onChange={field.onChange}
                    value={field.value}
                    minDate={validityStart}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Can
            condition={!isAllDay}
            onTrue={
              <>
                <FormField
                  control={form.control}
                  name={"hora_fim" as Path<T>}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Hora *</FormLabel>
                      <HourTimePickerInput
                        onChange={field.onChange}
                        value={field.value}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name={"minuto_fim" as Path<T>}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Minuto *</FormLabel>
                      <MinuteTimePickerInput
                        onChange={field.onChange}
                        value={field.value}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            }
          />
        </Row>
        <FormField
          control={form.control}
          name={"dia_todo" as Path<T>}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <Row>
                  <Checkbox
                    onCheckedChange={(e) => {
                      resetTime();
                      field.onChange(e);
                    }}
                    checked={field.value}
                    value={field.value}
                  />
                  <Text className="ml-2 text-sm">Dia todo</Text>
                </Row>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={"motivo" as Path<T>}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Motivo *</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={button.disabled}>
            <Text color="white">{button.title}</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
