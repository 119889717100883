import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

export default function SemesterSelect(props: SelectProps) {
  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
    >
      <SelectTrigger>
        <SelectValue
          defaultValue="primeiro_semestre"
          placeholder="1° Semestre"
        />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="primeiro_semestre">1° Semestre</SelectItem>
        <SelectItem value="segundo_semestre">2° Semestre</SelectItem>
      </SelectContent>
    </Select>
  );
}
