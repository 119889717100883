import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const schema = z.object({
  empresa: z.string({ required_error: REQUIRED_FIELD }).max(500),
  codigo: z.string({ required_error: REQUIRED_FIELD }).max(100),
  cnpj: z.string({ required_error: REQUIRED_FIELD }).max(30),
  abreviatura_empresa: z.string({ required_error: REQUIRED_FIELD }).max(255),
  endereco: z.string().optional(),
  numero: z.string().max(100).optional(),
  bairro: z.string().optional(),
  estado: z.string().optional(),
  cidade: z.string().optional(),
  contato: z.string().optional(),
  email: z.string().optional(),
});
