import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import CreateSupplierAccessDialog from "./components/Dialog/CreateSupplierAccessDialog";
import { useSuppliers } from "@/hooks/useSuppliers";

export default function SupplierAccess() {
  useSuppliers();
  return (
    <AuthenticatedLayout>
      <Row justify="end">
        <CreateSupplierAccessDialog />
      </Row>
    </AuthenticatedLayout>
  );
}
