import {
  factoryResourceKey,
  FactoryResourceType,
} from "@/hooks/useFactoriesResources";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FactoryResourceData } from "./FactoryResourceSelect";
import { MultiSelect } from "../ui/multiple-selector";
import { Filter, filterItems } from "@/utils/select";

type FactoryResourceMultiSelectProps = {
  selected: Array<string>;
  handleSelected: (value: string) => void;
} & Filter<FactoryResourceType>;

export default function FactoryResourceMultiSelect({
  handleSelected,
  selected,
  fieldToFilter,
  valueToFilter,
}: FactoryResourceMultiSelectProps) {
  const [visible, setVisible] = useState(false);
  const queryClient = useQueryClient();

  const factoryResourceData: FactoryResourceData | undefined =
    queryClient.getQueryData(factoryResourceKey.all);
  const { data: factoryResource } = factoryResourceData || {};
  if (!factoryResource) return null;

  const filteredOptions = filterItems(factoryResource, {
    fieldToFilter,
    valueToFilter,
  });
  const options = filteredOptions.map(
    ({ SK_fabrica_recurso, abreviatura_empresa, descricao }) => {
      return {
        label:
          SK_fabrica_recurso + " - " + abreviatura_empresa + ", " + descricao,
        value: String(SK_fabrica_recurso),
      };
    }
  );

  return (
    <MultiSelect
      options={options}
      selectedValues={selected}
      isOpen={visible}
      onOpen={() => {
        setVisible(true);
      }}
      onClose={() => {
        setVisible(false);
      }}
      onChange={handleSelected}
    />
  );
}
