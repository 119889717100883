import { DatePicker } from "@/components/DatePicker";
import ProductPackagingSelect from "@/components/Select/ProductPackagingSelect";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { REQUIRED_FIELD } from "@/constants";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import SeeSelectSelect from "../../Select/ViewSelectedSelect";

const schema = z.object({
  periodo_inicio: z.date({ required_error: REQUIRED_FIELD }),
  periodo_fim: z.date({ required_error: REQUIRED_FIELD }),
  embalagens: z.array(z.string()),
  visualizando: z.string(),
});

export type BuildLoadFormType = z.infer<typeof schema>;

type BuildLoadFormProps = {
  form: UseFormReturn<BuildLoadFormType, any, undefined>;
};

export default function BuildLoadForm({ form }: BuildLoadFormProps) {
  function handlePackagingSelect(value) {
    const previousValue = form.getValues("embalagens") || [];

    if (previousValue.includes(value)) {
      const newValues = previousValue.filter((item) => item !== value);
      return form.setValue("embalagens", newValues);
    }

    const values = [...previousValue, value];
    form.setValue("embalagens", values);
  }

  return (
    <Form {...form}>
      <form className="flex gap-4 p-2 w-full">
        <FormField
          control={form.control}
          name="periodo_inicio"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Período Inicial *</FormLabel>
              <FormControl>
                <DatePicker {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="periodo_fim"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Período final *</FormLabel>
              <FormControl>
                <DatePicker {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="embalagens"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Embalagens</FormLabel>
              <FormControl>
                <ProductPackagingSelect
                  isMultiple
                  handleSelected={handlePackagingSelect}
                  selected={field.value || []}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="visualizando"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Visualizar</FormLabel>
              <FormControl>
                <SeeSelectSelect
                  value={field.value}
                  onValueChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}
