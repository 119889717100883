import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cargoModelKey } from "./useCargoModels";
import instance from "@/api/business";

export function useCargoModelDeleteMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      await instance.delete("/planningbeer/cargoModel", { data: { id } });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cargoModelKey.all });
    },
  });
}
