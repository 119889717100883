import Text from "@/components/Text/Text";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { TEXT_COLOR } from "@/constants";
import { quantityFormater } from "@/utils/number";
import { PopoverPortal } from "@radix-ui/react-popover";
import { IoMdInformationCircleOutline } from "react-icons/io";

type MaxProductionPopoverProps = {
  value?: number;
};

export default function MaxProductionPopover({
  value = 0,
}: MaxProductionPopoverProps) {
  return (
    <Popover>
      <PopoverTrigger>
        <IoMdInformationCircleOutline
          size={20}
          color={TEXT_COLOR}
          className="font-bold"
        />
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent>
          <Text>
            Valor máximo de produção {quantityFormater(Number(value))}
          </Text>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
}
