import VisualizeItem from "@/components/DropdownItem/VisualizeDropdownItem";
import { PackagingPlansType } from "../../hooks/usePackagingPlans";
import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import { usePackagingPlanContext } from "../../context";

export default function ActionDropdown(props: PackagingPlansType) {
  const { handleDialog, handleDialogType, handlePackagingPlan } =
    usePackagingPlanContext();

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handlePackagingPlan({ ...props });
  }

  function handleVisualize() {
    handleDialog();
    handlePackagingPlan({ ...props });
  }
  return (
    <>
      <VisualizeItem onClick={handleVisualize} id={props.SK_plano_embalagem} />
      <DeleteDropdownItem onClick={handleDelete} />
    </>
  );
}
