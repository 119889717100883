import { useForm } from "react-hook-form";
import { FactoryResourceProductFormType, schema } from "../zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import FactoryResourceSelect from "@/components/Select/FactoryResourceSelect";
import { Input } from "@/components/ui/input";
import { useFactoryResourceProductContext } from "@/pages/FabricaRecursoProdutos/context";
import { useEffect } from "react";
import useResourceFactoryProductPatchMutation from "@/hooks/useResourceFactoryProductPatchMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";

export default function EditFactoryResourceProductForm() {
  const { resourceFactoryProduct, clear } = useFactoryResourceProductContext();
  const { FK_produto, SK_recurso_fabrica_produto } = resourceFactoryProduct;
  const { mutateAsync, isPending } = useResourceFactoryProductPatchMutation();
  const form = useForm<FactoryResourceProductFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });

  async function onSubmit(data: FactoryResourceProductFormType) {
    if (isPending) return;

    const apiData = {
      SK_recurso_fabrica_produto: Number(SK_recurso_fabrica_produto),
      FK_fabrica_recurso: Number(data.FK_fabrica_recurso),
      FK_produto: String(FK_produto),
      capacidade: String(data.capacidade_nominal),
    };

    try {
      await mutateAsync(apiData);
      toast.success("Produto atualizado com sucesso!");
      return clear();
    } catch (error) {
      validationError(error);
    }
  }

  useEffect(() => {
    const {
      FK_fabrica_recurso,
      codigo_produto,
      descricao_produto,
      capacidade,
    } = resourceFactoryProduct;

    const product = codigo_produto + " - " + descricao_produto || "";
    form.reset({
      FK_fabrica_recurso: String(FK_fabrica_recurso),
      produtos: [product],
      capacidade_nominal: capacidade || "",
    });
  }, [resourceFactoryProduct]);

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="FK_fabrica_recurso"
          render={({ field }) => (
            <FormItem>
              <FormLabel>ID Recurso Fábrica *</FormLabel>
              <FormControl>
                <FactoryResourceSelect
                  onValueChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="produtos"
          render={({ field }) => (
            <FormItem>
              <FormLabel>ID Produto *</FormLabel>
              <FormControl>
                <Input disabled={true} value={field.value} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="capacidade_nominal"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Capacidade *</FormLabel>
              <FormControl>
                <Input {...field} type="number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={isPending}>
            <Text color="white">Salvar</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
