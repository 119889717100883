type ColorsType = {
  background: string;
  color: string;
};

type ForecastStatusCellProps = {
  status?: string;
};

const COLORS: { [key: string]: ColorsType } = {
  rascunho: { background: "#dee2e6", color: "#212529" },
  aprovado: { background: "#ffe169", color: "#e85d04" },
  publicado: { background: "#ade8f4", color: "#023e8a" },
  aceito: { background: "#aacc00", color: "#007f5f" },
};

export default function ForecastStatusCell({
  status = "Desconhecido",
}: ForecastStatusCellProps) {
  const { background, color } = COLORS[status.toLowerCase()] || {
    background: "#ef233c",
    color: "white",
  };

  return (
    <span
      className="p-1 rounded capitalize"
      style={{ backgroundColor: background, color: color }}
    >
      {status.toUpperCase()}
    </span>
  );
}
