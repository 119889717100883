import Col from "@/components/Col";
import Text from "@/components/Text/Text";

type InformationTextProps = {
  label: string;
  value?: string;
};

export default function InformationText({
  label,
  value = "",
}: InformationTextProps) {
  return (
    <Col>
      <Text className="text-sm font-bold">{label}</Text>
      <Text className="text-sm text-ellipsis overflow-hidden">{value}</Text>
    </Col>
  );
}
