import { DialogTypeType, ReactChildrenType } from "@/types";
import { ResourceFactoryProductsTableType as ResourceFactoryProductsType } from "..";
import { createContext, useContext, useState } from "react";

type ResourceFactoryProductContextProps = {
  visibleDialog: boolean;
  handleDialog: () => void;
  dialogType: DialogTypeType;
  handleDialogType: (type: DialogTypeType) => void;
  resourceFactoryProduct: ResourceFactoryProductsType;
  handleResourceFactoryProduct: (data: ResourceFactoryProductsType) => void;
  clear: () => void;
};

const FactoryResourceProductContext = createContext(
  {} as ResourceFactoryProductContextProps
);

export default function FactoryResourceProductProvider({
  children,
}: ReactChildrenType) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [resourceFactoryProduct, setResourceFactoryProduct] =
    useState<ResourceFactoryProductsType>({} as ResourceFactoryProductsType);
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handleResourceFactoryProduct(value: ResourceFactoryProductsType) {
    setResourceFactoryProduct(value);
  }

  function clear() {
    setResourceFactoryProduct({} as ResourceFactoryProductsType);
    setDialogType("nonSelected");
    setVisibleDialog(false);
  }

  return (
    <FactoryResourceProductContext.Provider
      value={{
        visibleDialog,
        dialogType,
        resourceFactoryProduct,
        clear,
        handleResourceFactoryProduct,
        handleDialog,
        handleDialogType,
      }}
    >
      {children}
    </FactoryResourceProductContext.Provider>
  );
}

export function useFactoryResourceProductContext() {
  return useContext(FactoryResourceProductContext);
}
