import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";

type ItemsStepProps = {
  title: string;
  step: number;
  onClick: (step: number) => void;
  isSelected: boolean;
};

export default function ItemsStep({
  title,
  onClick,
  step,
  isSelected,
}: ItemsStepProps) {
  return (
    <Row className="w-fit h-full">
      <Button
        variant={isSelected ? "default" : "ghost"}
        size="sm"
        onClick={() => onClick(step)}
      >
        <Text color={isSelected ? "white" : "text"}>{title}</Text>
      </Button>
      <div className="w-[1px] bg-gray-300 ml-2 mr-2"></div>
    </Row>
  );
}
