import { Sheet, SheetContent } from "@/components/ui/sheet";
import { usePackagingSimulationSidebarContext } from "../../context/SidebarContext";
import dayjs from "dayjs";
import Text from "@/components/Text/Text";
import Col from "@/components/Col";
import { quantityFormater } from "@/utils/number";
import { Separator } from "@/components/ui/separator";
import useCargoModels from "@/pages/ModeloCarga/hooks/useCargoModels";
import { convertObjectArrayToObject } from "@/utils/object";

export default function SupplierCellInformationSideBar() {
  const { data } = useCargoModels();
  const { data: cargoModels } = data || {};

  const cargoModelsObject = convertObjectArrayToObject(
    cargoModels,
    "SK_modelo_carga"
  );

  const {
    visibleSidebar: { handleVisible, visible },
    content: { getContent },
  } = usePackagingSimulationSidebarContext();
  const {
    day,
    indisponibilityLeadTime,
    supplierName,
    value,
    quantityPerPallets,
    cargoModelQuantityPallets,
    fkModelCargo,
    // authorizationDelivery = [],
  } = getContent();
  const dayToSendOrder = dayjs(day)
    .subtract(indisponibilityLeadTime, "day")
    .format("DD/MM/YYYY");

  return (
    <Sheet onOpenChange={handleVisible} open={visible}>
      <SheetContent className="overflow-y-scroll">
        <Col className="gap-3">
          <Text className="text-base font-bold">
            {dayjs(day).format("DD/MM/YYYY")}
          </Text>
          <Text>
            Data limite para envio de pedido: <strong>{dayToSendOrder}</strong>
          </Text>
          <Text>
            Lead time: <strong>{indisponibilityLeadTime} dias</strong>
          </Text>
          <Text>
            Nome do fornecedor: <strong>{supplierName}</strong>
          </Text>
          <Text>
            Quantidade: <strong>{quantityFormater(Number(value))}</strong>
          </Text>
          <Text>
            Quantidade por palete:{" "}
            <strong>{quantityFormater(Number(quantityPerPallets))}</strong>
          </Text>
          <Separator />
          <Text>
            Modelo de carga:{" "}
            <strong>{cargoModelsObject[fkModelCargo]?.nome || "Padrão"}</strong>
          </Text>
          <Text>
            Quantidade de paletes do modelo de carga:{" "}
            <strong>{cargoModelQuantityPallets}</strong>
          </Text>
          <Separator />
          <Text className="text-base font-bold">Autorizações de entrega</Text>
          {/* <Each
            array={authorizationDelivery}
            renderItem={({ codigo, criado_em, data_entrega }) => {
              return (
                <>
                  <Col className="gap-2 border px-4 py-1">
                    <Text>
                      Código: <strong>{codigo}</strong>
                    </Text>
                    <Text>
                      Criado em: <strong>{criado_em}</strong>
                    </Text>
                    <Text>
                      Estimativa de entrega: <strong>{data_entrega}</strong>
                    </Text>
                  </Col>
                </>
              );
            }}
          /> */}
        </Col>
      </SheetContent>
    </Sheet>
  );
}
