import Text from "@/components/Text/Text";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { DialogProps } from "@/types";
import {
  SelectedPalleteType,
  SuppliersPackageType,
} from "../Steps/BuildLoad/LoadPackaging";
import Each from "@/components/Each";
import Row from "@/components/Row";
import dayjs from "dayjs";
import Col from "@/components/Col";
import { Separator } from "@/components/ui/separator";
import { getComposition } from "../../utils/buildLoadUtils";
import { PackagingType } from "@/hooks/usePackagings";
import { calculateTotalCargos } from "@/pages/SimulacoesEmbalagem/utils/carga";
import useCargoModels, {
  CargoModelType,
} from "@/pages/ModeloCarga/hooks/useCargoModels";

type SummaryBuildLoadModalProps = {
  selectedPallete: SelectedPalleteType[];
  suplliersPackage: SuppliersPackageType[];
  packagings: PackagingType[];
} & DialogProps;

export default function SummaryBuildLoadModal({
  suplliersPackage,
  selectedPallete,
  visible,
  packagings,
  handleClose,
}: SummaryBuildLoadModalProps) {
  const { data } = useCargoModels();
  const { data: cargoModels = [] } = data || {};

  return (
    <Dialog onOpenChange={handleClose} open={visible}>
      <DialogContent className="h-full w-10/12 max-w-full flex flex-col p-8 overflow-y-scroll">
        <Each
          wrapper={{ className: "border rounded-sm" }}
          array={suplliersPackage}
          renderItem={({
            fkSupplier,
            supplierName,
            dates,
            palletPerCargo,
            fkTransporter,
          }) => {
            const transporter = getTransporter(
              cargoModels,
              Number(fkTransporter)
            );

            return (
              <>
                <Row className="p-2">
                  <Text className="text-base">
                    Fornecedor: <strong>{supplierName}</strong>
                  </Text>
                </Row>
                <Separator />
                <Each
                  array={dates}
                  renderItem={({ day }) => {
                    const calculatedTotalSelectedPallets =
                      calculateTotalPallets(selectedPallete, fkSupplier, day);
                    const compositionFiltered = filterSelectedComposition(
                      selectedPallete,
                      fkSupplier,
                      day
                    );
                    if (compositionFiltered.length == 0) return null;
                    const totalCargos = calculateTotalCargos(
                      calculatedTotalSelectedPallets,
                      palletPerCargo
                    );

                    return (
                      <>
                        <Separator />
                        <Col>
                          <Row align="center" className="gap-2">
                            <Text className="text-base mb-2 mt-2 ml-4 font-bold">
                              {dayjs(day).format("DD/MM/YYYY")}
                            </Text>
                            <Text className="text-sm ml-4">
                              Quantidade Total de Paletes:{" "}
                              <strong>{calculatedTotalSelectedPallets}</strong>
                            </Text>
                            <Text>
                              Quantidade de Cargas:{" "}
                              <strong>{totalCargos}</strong>
                            </Text>
                          </Row>
                          <Row className="gap-2 ml-4">
                            <Text>
                              Transportador:{" "}
                              <strong>{transporter?.nome}</strong>
                            </Text>
                            <Text>
                              Quantidade de paletes por carga:
                              <strong> {transporter?.quantidade_palete}</strong>
                            </Text>
                          </Row>
                        </Col>
                        <Separator />
                        <Each
                          array={compositionFiltered}
                          renderItem={({
                            composition,
                            totalDefaultPallets,
                            totalQuantityPallets,
                            totalStrategyPallets,
                          }) => {
                            const { descricao, composicao } =
                              getComposition(packagings, composition) || {};

                            return (
                              <>
                                <Separator />
                                <Col className="gap-2 py-2 px-8">
                                  <Text>
                                    Produto: {composicao} - {descricao}
                                  </Text>
                                  <Row className="gap-8">
                                    <Text>
                                      Quantidade de Paletes (Modelo 1):{" "}
                                      <strong>{totalDefaultPallets}</strong>
                                    </Text>
                                    <Text>
                                      Quantidade de Paletes (Modelo 2):{" "}
                                      <strong>{totalStrategyPallets}</strong>
                                    </Text>
                                    <Text>
                                      Paletes Totais: {totalQuantityPallets}
                                    </Text>
                                  </Row>
                                </Col>
                              </>
                            );
                          }}
                        />
                      </>
                    );
                  }}
                />
              </>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

function getTransporter(cargoModels: CargoModelType[], fkTransporter: number) {
  return cargoModels.find(
    ({ SK_modelo_carga }) => SK_modelo_carga == fkTransporter
  );
}

function filterSelectedComposition(
  selectedPallete: SelectedPalleteType[],
  fkSupplier: string,
  day: string
) {
  const selected = selectedPallete.filter(
    (item) => item.supplier == fkSupplier && item.day == day
  );

  const compositionMap: { [composition: string]: any } = {};

  selected.forEach(({ composition, type }) => {
    if (!compositionMap[composition]) {
      compositionMap[composition] = {
        composition,
        totalQuantityPallets: 0,
        totalDefaultPallets: 0,
        totalStrategyPallets: 0,
      };
    }

    compositionMap[composition].totalQuantityPallets++;
    if (type === "default") {
      compositionMap[composition].totalDefaultPallets++;
    } else if (type === "strategic") {
      compositionMap[composition].totalStrategyPallets++;
    }
  });

  return Object.values(compositionMap);
}

function calculateTotalPallets(
  selectedPallete: SelectedPalleteType[],
  fkSupplier: string,
  day: string
) {
  const selected = selectedPallete.filter(
    (item) => item.supplier == fkSupplier && item.day == day
  );

  const calculated = selected.filter(
    (item) => item.supplier == fkSupplier && item.day == day
  ).length;

  return calculated;
}
