import { supplierKeys, SupplierType } from "@/hooks/useSuppliers";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { MultiSelect } from "../ui/multiple-selector";
import { filterItems } from "@/utils/select";
import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import Each from "../Each";

type SupplierData = { data: Array<SupplierType> };

type SupplierSelectProps = {
  selected?: Array<string>;
  handleSelected?: (value: string) => void;
  isMultiple?: boolean;
} & SelectProps;

export default function SupplierSelect({
  handleSelected = () => {},
  selected = [],
  isMultiple,
  ...props
}: SupplierSelectProps) {
  const [visible, setVisible] = useState(false);
  const queryClient = useQueryClient();
  const supplierData: SupplierData | undefined = queryClient.getQueryData(
    supplierKeys.all
  );
  const { data: supplier = [] } = supplierData || {};

  const filteredActiveProducts = filterItems(supplier, {
    fieldToFilter: "ativo",
    valueToFilter: 1,
  });

  const options = filteredActiveProducts?.map(
    ({ SK_fornecedor, nome_reduzido, codigo }) => {
      return {
        label: codigo + " - " + nome_reduzido,
        value: SK_fornecedor,
      };
    }
  );

  if (!supplierData) return null;

  if (isMultiple) {
    return (
      <MultiSelect
        virtualized={options.length > 50 ? true : false}
        options={options}
        selectedValues={selected}
        isOpen={visible}
        onOpen={() => {
          setVisible(true);
        }}
        onClose={() => {
          setVisible(false);
        }}
        onChange={handleSelected}
      />
    );
  }

  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={options}
          renderItem={(props) => {
            return <SelectItem value={props.value}>{props.label}</SelectItem>;
          }}
        />
      </SelectContent>
    </Select>
  );
}
