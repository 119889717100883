import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import { FactoryColumnsType } from "@/pages/Factory";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { factoryKeys } from "./useFactoryMutation";

async function fetchFactory({ id }: { id: number }) {
  if (!id) return;
  const response = await instance.post<ApiResponse<FactoryColumnsType>>(
    "/planningbeer/factory/detail",
    {
      id,
    }
  );
  return response.data;
}

export default function useFactory({ id }: { id: number }) {
  return useQuery({
    queryKey: factoryKeys.detail(id),
    queryFn: () => fetchFactory({ id }),
    placeholderData: keepPreviousData,
  });
}
