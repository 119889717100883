import { useState } from "react";

export function useIndisponibilityLeadTime() {
  const [indisponibilityLeadTimeDates, setIndisponibilitLeadTimeDates] =
    useState<{ [key: string]: string }>({});

  function handleIndisponibilitLeadTimeDates(dates: { [key: string]: string }) {
    setIndisponibilitLeadTimeDates(dates);
  }

  function clear() {
    setIndisponibilitLeadTimeDates(() => {
      return {};
    });
  }

  return {
    indisponibilityLeadTimeDates,
    handleIndisponibilitLeadTimeDates,
    clear,
  };
}
