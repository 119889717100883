import Col from "@/components/Col";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import {
  SelectedSupplierValuesType,
  SupplierPackagingCompositionStepType,
  useDeliveryAuthorizationContext,
} from "../../context/DeliveryAuthorizationContext";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import Text from "@/components/Text/Text";
import Each from "@/components/Each";
import { getOperationType } from "../../utils/operationType";
import { Separator } from "@/components/ui/separator";
import { quantityFormater } from "@/utils/number";
import dayjs from "dayjs";
import {
  calculateTotalCargos,
  calculateTotalPallets,
} from "@/pages/SimulacoesEmbalagem/utils/carga";
import ContractSelect from "@/components/Select/ContractSelect";
import { ContractItemType, useContracts } from "../../hooks/useContracts";
import { useState } from "react";
import Can from "@/components/Can";
import { toast } from "sonner";

function getSuppliers(data: SupplierPackagingCompositionStepType[]) {
  const suppliers = data.map((item) => {
    const fornecedor = item.fornecedor as { valores?: any };
    delete fornecedor.valores;
    return item;
  });

  return suppliers;
}

function getPackages(
  data: SupplierPackagingCompositionStepType[]
): SelectedSupplierValuesType[] {
  const suppliersMap = new Map<number, any>();
  const suppliers = getSuppliers(data);

  suppliers.forEach((item) => {
    const supplierId = item.FK_embalagem_fornecedor;
    const supplierInfo = item.fornecedor;

    if (!suppliersMap.has(supplierId)) {
      suppliersMap.set(supplierId, {
        ...supplierInfo,
        valores_selecionados: [],
      });
    }
    suppliersMap.get(supplierId).valores_selecionados.push({
      dia: item.dia,
      valor: item.valor,
      quantidade_palete_carga: item.quantidade_palete_carga,
      quantidade_por_palete: item.quantidade_por_palete,
      descricao_composicao: item.descricao_composicao,
    });
  });

  return Array.from(suppliersMap.values());
}

export default function ContractStep() {
  useContracts();
  const { previousStep, selectedComposition } =
    useDeliveryAuthorizationContext();
  const [packages, setPackages] = useState<Array<SelectedSupplierValuesType>>(
    getPackages(selectedComposition)
  );

  function setPackageContract(
    contract: ContractItemType,
    composition: SelectedSupplierValuesType
  ) {
    setPackages((prev) => {
      const disponibilityQuantity =
        Number(contract.quantidade || 0) -
        Number(contract.quantidade_entregue || 0);
      const newPackages = [...prev];
      const supplierIndex = newPackages.findIndex(
        (item) =>
          item.SK_embalagem_fornecedor == composition.SK_embalagem_fornecedor
      );
      const supplier = newPackages[supplierIndex];

      const totalQuantity = supplier.valores_selecionados.reduce(
        (acc, { valor }) => {
          return acc + Number(valor);
        },
        0
      );

      if (totalQuantity > disponibilityQuantity) {
        toast.error(
          "Quantidade de contrato excedida, quantidade disponível: " +
            quantityFormater(disponibilityQuantity) +
            " quantidade total: " +
            quantityFormater(totalQuantity)
        );
        return prev;
      }

      const newSupplier = {
        ...supplier,
        contrato_selecionado: {
          ...contract,
          quantidade_restante: disponibilityQuantity - totalQuantity,
        },
      };
      newPackages[supplierIndex] = newSupplier;
      return newPackages;
    });
  }

  function removePackageContract(composition: SelectedSupplierValuesType) {
    setPackages((prev) => {
      const newPackages = [...prev];
      const supplierIndex = newPackages.findIndex(
        (item) =>
          item.SK_embalagem_fornecedor == composition.SK_embalagem_fornecedor
      );
      const supplier = newPackages[supplierIndex];
      const newSupplier = {
        ...supplier,
        contrato_selecionado: undefined,
      };
      newPackages[supplierIndex] = newSupplier;
      return newPackages;
    });
  }

  return (
    <>
      <Col className="px-1 h-full overflow-y-scroll">
        <Text className="text-lg font-bold">Selecionar Contratos</Text>
        <Each
          array={packages}
          renderItem={(renderedSupplier) => {
            const operationType = getOperationType(
              renderedSupplier.tipo_de_operacao
            );
            return (
              <Accordion type="single" collapsible>
                <AccordionItem
                  value={
                    renderedSupplier.nome_fornecedor +
                    renderedSupplier.SK_embalagem_fornecedor
                  }
                >
                  <AccordionTrigger>
                    <div className="border rounded-sm py-2 px-4 w-full flex flex-col items-start">
                      <Text className="text-base font-bold">
                        {renderedSupplier.codigo_fornecedor} -{" "}
                        {renderedSupplier.nome_fornecedor}
                      </Text>
                      <Text>
                        Tipo de operação: {operationType.ABREVIATURA_EMPRESA} -{" "}
                        {operationType.SK_EMPRESA}
                      </Text>
                      <Text className="text-sm mb-4">
                        Composição: {renderedSupplier.composicao} -{" "}
                        {renderedSupplier.descricao_composicao}
                      </Text>
                      <ContractSelect
                        composition={renderedSupplier}
                        onChange={setPackageContract}
                        onRemove={removePackageContract}
                      />
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <Can
                      condition={!!renderedSupplier.contrato_selecionado}
                      onTrue={
                        <Col className="gap-1 ml-4">
                          <Row className="gap-3">
                            <Text>
                              <strong>ID contrato: </strong>
                              {
                                renderedSupplier.contrato_selecionado
                                  ?.SK_contrato
                              }
                            </Text>
                            <Text>
                              <strong>Descrição do contrato: </strong>
                              {renderedSupplier.contrato_selecionado?.descricao}
                            </Text>
                            <Text>
                              <strong>Item do contrato: </strong>
                              {renderedSupplier.contrato_selecionado?.item}
                            </Text>
                          </Row>
                          <Row className="gap-3">
                            <Text>
                              <strong>Data Inicial: </strong>
                              {dayjs(
                                renderedSupplier.contrato_selecionado
                                  ?.data_inicial
                              ).format("DD/MM/YYYY")}
                            </Text>
                            <Text>
                              <strong>Data Final: </strong>
                              {dayjs(
                                renderedSupplier.contrato_selecionado
                                  ?.data_final
                              ).format("DD/MM/YYYY")}
                            </Text>
                          </Row>
                          <Row className="gap-3">
                            <Text>
                              <strong>Filial de Entrega: </strong>
                              {
                                renderedSupplier.contrato_selecionado
                                  ?.filial_entrega
                              }
                            </Text>
                            <Text>
                              <strong>Código Fornecedor: </strong>
                              {
                                renderedSupplier.contrato_selecionado
                                  ?.fornecedor
                              }
                            </Text>
                          </Row>
                          <Row className="gap-3">
                            <Text>
                              <strong>Quantidade: </strong>
                              {quantityFormater(
                                Number(
                                  renderedSupplier.contrato_selecionado
                                    ?.quantidade || 0
                                )
                              )}
                            </Text>
                            <Text>
                              <strong>Quantidade Entregue: </strong>
                              {quantityFormater(
                                Number(
                                  renderedSupplier.contrato_selecionado
                                    ?.quantidade_entregue || 0
                                )
                              )}
                            </Text>
                            <Text>
                              <strong>Quantidade Restante: </strong>
                              {quantityFormater(
                                Number(
                                  renderedSupplier.contrato_selecionado
                                    ?.quantidade_restante || 0
                                )
                              )}
                            </Text>
                          </Row>
                        </Col>
                      }
                    />
                    <Separator className="mt-2 mb-2" />
                    <Each
                      array={renderedSupplier.valores_selecionados}
                      renderItem={(valor) => {
                        return (
                          <Row align="center">
                            <Col className="w-full">
                              <Col className="ml-4 mt-2  w-full">
                                <Row className="gap-2">
                                  <Text>
                                    Composição:{" "}
                                    <strong>
                                      {renderedSupplier.composicao}
                                      {" - "}
                                      {renderedSupplier.descricao_composicao}
                                    </strong>
                                  </Text>
                                  <Text>
                                    Quantidade:{" "}
                                    <strong>
                                      {quantityFormater(Number(valor.valor))}
                                    </strong>
                                  </Text>
                                  <Text>
                                    Data:{" "}
                                    <strong>
                                      {dayjs(valor.dia).format("DD/MM/YYYY")}
                                    </strong>
                                  </Text>
                                </Row>
                                <Col>
                                  <Text>
                                    Paletes por Carga:{" "}
                                    <strong>
                                      {valor.quantidade_palete_carga}
                                    </strong>
                                  </Text>
                                  <Text>
                                    Quantidade por Palete:{" "}
                                    <strong>
                                      {quantityFormater(
                                        Number(valor.quantidade_por_palete)
                                      )}
                                    </strong>
                                  </Text>
                                  <Text>
                                    Quantidade de cargas:{" "}
                                    <strong>
                                      {calculateTotalCargos(
                                        calculateTotalPallets(
                                          Number(valor.valor),
                                          Number(valor.quantidade_por_palete)
                                        ),
                                        Number(
                                          valor.quantidade_palete_carga || 25
                                        )
                                      )}
                                    </strong>
                                  </Text>
                                </Col>
                                <Separator className="mb-1 w-full" />
                              </Col>
                            </Col>
                          </Row>
                        );
                      }}
                    />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            );
          }}
        />
      </Col>
      <Row justify="end" className="gap-2" align="end">
        <Button
          variant="outline"
          onClick={() => {
            previousStep();
          }}
        >
          Voltar
        </Button>
        <Button>Continuar</Button>
      </Row>
    </>
  );
}
