import instance from "@/api/business";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ApiResponse } from "./useEstimates";

export type InventoryTableType = InventoryResponseType;

export type InventoryResponseType = {
  SK_inventario_carga: string;
  FK_fabrica: string;
  descricao?: string;
  vigencia: string;
  nome_fabrica: string;
  codigo_fabrica: string;
  cnpj_fabrica: string;
  criado_por_codigo: string;
  criado_por_nome: string;
  created_at: string;
  editado_por_codigo: string;
  editado_por_nome: string;
  editado_por_data: string;
};

export const inventoryKeys = {
  all: ["inventory"] as const,
  detail: (id: string) => [...inventoryKeys.all, id],
};

async function fetchInventory() {
  const response = await instance.post<ApiResponse<InventoryResponseType>>(
    "/planningbeer/inventory"
  );
  return response.data;
}

export function useInventory() {
  return useQuery({
    queryFn: fetchInventory,
    queryKey: inventoryKeys.all,
    placeholderData: keepPreviousData,
  });
}
