import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useFactoryContext } from "../../context";
import Steps from "../Step/ModalSteps";
import { lazy, Suspense, useState } from "react";
import DotsLoading from "@/components/Loading/DotsLoading";

const ManagerFactoryContent = lazy(
  () => import("../Content/ManagerFactoryContent")
);
const FactoryProductContent = lazy(
  () => import("../Content/FactoryProductContent")
);

export default function ManagerFactoryModal() {
  const [step, setStep] = useState(0);
  const { visibleDialog, dialogType, clear } = useFactoryContext();

  const stepItems = {
    0: <ManagerFactoryContent />,
    1: <FactoryProductContent />,
  };

  if (dialogType !== "manageFactory") return null;
  return (
    <Dialog open={visibleDialog} onOpenChange={clear}>
      <DialogContent className="flex flex-col max-w-full h-full w-11/12 px-0">
        <Steps selectedStep={step} handleStep={(step) => setStep(step)} />
        <Suspense fallback={<DotsLoading />}>{stepItems[step]}</Suspense>
      </DialogContent>
    </Dialog>
  );
}
