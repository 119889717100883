import customParseFormat from "dayjs/plugin/customParseFormat";
import i18n from "@/i18n";
import dayjs from "dayjs";
dayjs.extend(customParseFormat);

const FIRST_SEMESTER_MONTHS = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
];

const SECOND_SEMESTER_MONTHS = [
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
];

export function validateSemester(formSemester: string, month: string, line) {
  if (formSemester == "primeiro_semestre") {
    const includes = FIRST_SEMESTER_MONTHS.includes(
      i18n[month.toLowerCase()].toLowerCase()
    );
    if (!includes)
      throw new Error(
        "Mês inválido na linha " +
          line +
          " somente datas do primeiro semestre são permitidas"
      );
    return;
  }
  if (formSemester == "segundo_semestre") {
    const includes = SECOND_SEMESTER_MONTHS.includes(month);
    if (!includes)
      throw new Error(
        "Mês inválido na linha " +
          line +
          " somente datas do segundo semestre são permitidas"
      );
    return;
  }
}

export function validateMonth(formMonth: string, month: string, line) {
  if (formMonth.toLowerCase() !== i18n[month.toLowerCase()].toLowerCase())
    throw new Error(
      "Mês inválido na linha " +
        line +
        " somente datas com mês " +
        formMonth +
        " são permitidas"
    );
}

export function validateYear(formYear, year, line) {
  if (formYear !== year)
    throw new Error(
      "Ano inválido na linha " +
        line +
        " somente datas com ano " +
        formYear +
        " são permitidas"
    );
}

type GetPeriodModelProps = {
  semester?: string;
  month?: string;
  year?: string;
};

const months = {
  janeiro: "01",
  fevereiro: "02",
  março: "03",
  abril: "04",
  maio: "05",
  junho: "06",
  julho: "07",
  agosto: "08",
  setembro: "09",
  outubro: "10",
  novembro: "11",
  dezembro: "12",
};

export function getPeriodModel({
  month,
  semester,
  year = "2024",
}: GetPeriodModelProps) {
  if (semester) {
    if (semester == "primeiro_semestre") {
      return "01/" + "01/" + year;
    }
    if (semester == "segundo_semestre") {
      return "01/" + "07/" + year;
    }
  }

  if (month) {
    return "01/" + months[month.toLowerCase()] + "/" + year;
  }

  return "01/01/" + year;
}

export function validateDate(date: string, line: number) {
  try {
    if (typeof date == "object") {
      const formatedDate = dayjs(date);
      if (!dayjs(formatedDate, "DD/MM/YYYY", true).isValid()) {
        throw new Error(
          `Vigência inválida na linha ${line}, utilize uma data válida. dia/mês/ano. Exemplo: 01/12/2024`
        );
      }
      return dayjs(formatedDate, "MM/DD/YYYY", true).format("DD/MM/YYYY");
    }
    if (!dayjs(date, "DD/MM/YYYY", true).isValid()) {
      throw new Error(
        `Vigência inválida na linha ${line}, utilize uma data válida. dia/mês/ano. Exemplo: 01/12/2024`
      );
    }
    return date;
  } catch (err) {
    throw new Error(
      `Vigência inválida na linha ${line}, utilize uma data válida. dia/mês/ano. Exemplo: 01/12/2024`
    );
  }
}
