// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import useProducts from "@/hooks/useProducts";
import { useFactoriesResources } from "@/hooks/useFactoriesResources";
import useResourceFactoryProducts from "@/hooks/useResourceFactoryProducts";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import { TableData } from "@/components/NewTable/type";
import Table from "@/components/NewTable";
import ActionCell from "@/components/Cell/ActionCell";
import { useState } from "react";
import { getTableColumnOrder } from "@/utils/table";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import { useFactoryResourceProductContext } from "./context";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import useResourceFactoryProductDeleteMutation from "@/hooks/useResourceFactoryProductDeleteMutation";
import CreateFactoryResourceProductDialog from "./components/dialog/CreateFactoryResourceProductDialog";
import EditFactoryResourceProductDialog from "./components/dialog/EditFactoryResourceProductDialog";
import TableContainer from "@/components/Container/TableContainer";
import { quantityFormater } from "@/utils/number";

export type ResourceFactoryProductsTableType = {
  SK_recurso_fabrica_produto: number;
  FK_produto: string;
  FK_fabrica_recurso: number;
  descricao_produto: string;
  capacidade: string;
  codigo_produto: string;
  codigo_fabrica: string;
  cnpj_fabrica: string;
  abreviatura_fabrica: string;
  descricao_recurso: string;
  definicao_recurso: string;
};

const columnHelper = createColumnHelper<ResourceFactoryProductsTableType>();
const TABLE_ORDER_KEY = "factoryResourceProductColumnOrder";

export type ResourceFactoryProductsTable =
  TableData<ResourceFactoryProductsTableType>;

export default function FactoryResourceProduct() {
  useFactoriesResources();
  useProducts();
  const { mutateAsync, isPending } = useResourceFactoryProductDeleteMutation();
  const { resourceFactoryProduct, clear, visibleDialog, dialogType } =
    useFactoryResourceProductContext();
  const { SK_recurso_fabrica_produto } = resourceFactoryProduct;
  const { data } = useResourceFactoryProducts();
  const { data: resourceFactoryProducts = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_recurso_fabrica_produto", {
      header: "ID",
      id: "SK_recurso_fabrica_produto",
      size: 100,
    }),
    columnHelper.accessor("codigo_produto", {
      header: "Código do Produto",
      id: "codigo_produto",
      size: 200,
      meta: {
        enableColumnOrdering: true,
        row: {
          isGrouped: true,
        },
      },
    }),
    columnHelper.accessor("descricao_produto", {
      header: "Descrição do Produto",
      id: "descricao_produto",
      size: 400,
    }),
    columnHelper.accessor("descricao_recurso", {
      header: "Descrição do Recurso",
      id: "descricao_recurso",
      size: 300,
    }),
    columnHelper.accessor("capacidade", {
      header: "Capacidade",
      id: "capacidade",
      size: 200,
      cell: ({
        row: {
          original: { capacidade = 0 },
        },
      }) => {
        if (!capacidade) return;
        return <span>{quantityFormater(capacidade)}</span>;
      },
    }),
    columnHelper.accessor("definicao_recurso", {
      header: "Definição do Recurso",
      id: "definicao_recurso",
    }),
    columnHelper.accessor("codigo_fabrica", {
      header: "Código da Fábrica",
      id: "codigo_fabrica",
      size: 150,
    }),
    columnHelper.accessor("cnpj_fabrica", {
      header: "CNPJ da Fábrica",
      id: "cnjp_fabrica",
      size: 170,
    }),
    columnHelper.accessor("abreviatura_fabrica", {
      header: "Abreviatura da Fábrica",
      id: "abreviatura_fabrica",
      size: 250,
    }),
    columnHelper.display({
      id: "Ações",
      header: "Ações",
      size: 100,
      cell: ({ row }) => {
        if (row.depth != 0)
          return <ActionCell items={<ActionDropdown {...row.original} />} />;
      },
    }),
  ];

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getTableColumnOrder({ column: columns, key: TABLE_ORDER_KEY })
  );

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  const dataSubRows = resourceFactoryProducts.map((row) => {
    return {
      codigo_produto: row.codigo_produto,
      descricao_produto: row.descricao_produto,
      subRows: [
        ...row.produtos.map((product) => {
          return {
            SK_recurso_fabrica_produto: product.SK_recurso_fabrica_produto,
            descricao_produto: product.descricao_produto,
            FK_produto: product.FK_produto,
            FK_fabrica_recurso: product.FK_fabrica_recurso,
            codigo_produto: product.codigo_produto,
            codigo_fabrica: product.codigo_fabrica,
            cnpj_fabrica: product.cnpj_fabrica,
            capacidade: product.capacidade,
            abreviatura_fabrica: product.abreviatura_fabrica,
            descricao_recurso: product.descricao_recurso,
            definicao_recurso: product.definicao_recurso,
          };
        }),
      ],
    };
  });

  async function handleDelete() {
    if (isPending) return;
    try {
      await mutateAsync({ id: Number(SK_recurso_fabrica_produto) });
      clear();
      return toast.success("Fábrica - recurso excluída com sucesso!");
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <AuthenticatedLayout>
      <Row justify="end">
        <CreateFactoryResourceProductDialog />
      </Row>
      <TableContainer>
        <Table<ResourceFactoryProductsTable>
          columns={columns}
          data={dataSubRows}
          columnOrderState={columnOrder}
          onColumnOrderStateChange={handleColumnOrder}
          columnOrderKey={TABLE_ORDER_KEY}
          meta={{
            layout: "stretch",
          }}
        />
      </TableContainer>
      <EditFactoryResourceProductDialog />
      <DeleteDialogAlert
        onConfirm={handleDelete}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        onCancel={clear}
        type={dialogType}
        alertDialogTitle={`Deseja excluir a relação fábrica/recurso - produto, "${SK_recurso_fabrica_produto}"?. Essa ação é irreversível.`}
      />
    </AuthenticatedLayout>
  );
}
