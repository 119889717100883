import instance from "@/api/business";
import { EstimatesColumnProps } from "@/pages/Estimativas";
import { UserType } from "@/types";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ColumnFiltersState } from "@tanstack/react-table";

export type ApiPaginatedResponse<T> = {
  status: number;
  meta: {
    total: number;
    pages: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  data: Array<T>;
};

export type ApiResponse<T> = {
  data: Array<T>;
};

export type ApiObjectResponse<T> = {
  data: T;
};

export type ApiType = {
  page: number;
  pageSize: number;
  filters?: ColumnFiltersState;
};

type FetchEstimatesType = ApiType & { role: UserType["role"] };

async function fetchEstimates({
  page,
  pageSize,
  filters,
  role,
}: FetchEstimatesType) {
  const response = await instance.post<
    ApiPaginatedResponse<EstimatesColumnProps>
  >("/planningbeer/forecast", {
    page: page,
    pageSize: pageSize,
    filters: filters,
    role: role,
  });
  return response.data;
}

export const estimates = {
  all: ["companies"] as const,
  lists: () => [...estimates.all, "list"] as const,
  show: (id: number) => [...estimates.all, "show", id] as const,
  detail: (id: number) => [...estimates.all, "detail", id] as const,
  log: (id: number) => [...estimates.all, "logs", id] as const,
  paginated: ({ page, pageSize, filters }: ApiType) =>
    [...estimates.lists(), "paginated", pageSize, page, filters] as const,
};

export default function useEstimates({
  page,
  pageSize,
  filters,
  role,
}: FetchEstimatesType) {
  return useQuery({
    queryKey: estimates.paginated({ page, pageSize, filters }),
    queryFn: () => fetchEstimates({ page, pageSize, filters, role }),
    placeholderData: keepPreviousData,
  });
}
