import Empty from "@/components/Empty";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Table from "@/components/NewTable";
import { TableData } from "@/components/NewTable/type";
import {
  ProductionSimulationType,
  useProductionSimulators,
} from "@/hooks/useProductionSimulators";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import ForecastStatusCell from "../Estimativas/components/Cell/StatusCell";
import ActionCell from "@/components/Cell/ActionCell";
import { useState } from "react";
import { getTableColumnOrder } from "@/utils/table";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import { useSimulatorContext } from "./context";
import useProductionSimulatorDeleteMutation from "@/hooks/useProductionSImulatorDeleteMutation";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import useFactories from "@/hooks/useFactories";
import useProducts from "@/hooks/useProducts";
import TableContainer from "@/components/Container/TableContainer";
import Text from "@/components/Text/Text";

export type SimulatorTableType = TableData<ProductionSimulationType>;

const columnHelper = createColumnHelper<SimulatorTableType>();
const TABLE_ORDER_KEY = "simulatorsColumnsOrder";

export default function Simulators() {
  useFactories();
  useProducts();
  const { clear, dialogType, visibleDialog, simulator } = useSimulatorContext();
  const { data } = useProductionSimulators();
  const { mutateAsync, isPending } = useProductionSimulatorDeleteMutation();
  const { data: simulators = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_simulacao", {
      header: "ID",
      id: "SK_simulacao",
      size: 100,
    }),
    columnHelper.accessor("status", {
      header: "Status",
      id: "status",
      cell: ({ row }) => {
        const { status } = row.original;
        return <ForecastStatusCell status={status?.toLowerCase()} />;
      },
    }),
    columnHelper.accessor("foi_incluido", {
      header: "Incluído ao Plano de Produção",
      id: "foi_incluido",
      cell: ({ row }) => {
        const { foi_incluido } = row.original;
        return (
          <Text
            className={`p-1 px-1.5 rounded capitalize ${foi_incluido ? "bg-lime-300" : "bg-red-400"}`}
            color="black"
          >
            {foi_incluido ? "SIM" : "NÃO"}
          </Text>
        );
      },
    }),
    columnHelper.accessor("periodo_inicio", {
      header: "Período Início",
      id: "periodo_inicio",
      cell: (row) => {
        const date = row.getValue();
        if (!date) return <Empty />;
        return <span>{dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>;
      },
    }),
    columnHelper.accessor("periodo_fim", {
      header: "Período Fim",
      id: "periodo_fim",
      cell: (row) => {
        const date = row.getValue();
        if (!date) return <Empty />;
        return <span>{dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>;
      },
    }),
    columnHelper.accessor("observacao", {
      header: "Observação",
      id: "observacao",
    }),
    columnHelper.accessor("revisao", {
      header: "Versão do Simulador",
      id: "revisao",
      cell: ({
        row: {
          original: { revisao },
        },
      }) => {
        return (
          <Text color="black" className="bg-gray-300 px-2 py-1 rounded">
            v{revisao}
          </Text>
        );
      },
    }),
    columnHelper.accessor("descricao_plano_producao", {
      header: "Descrição do Plano de Produção",
      id: "descricao_plano_producao",
      size: 300,
    }),
    columnHelper.accessor("revisao_plano_producao", {
      header: "Versão do Plano de Produção",
      id: "revisao_plano_producao",
      cell: ({
        row: {
          original: { revisao_plano_producao },
        },
      }) => {
        return (
          <Text color="white" className="bg-gray-600 px-2 py-1 rounded">
            v{revisao_plano_producao}
          </Text>
        );
      },
    }),
    columnHelper.accessor("abreviatura_empresa", {
      header: "Abreviatura da Fábrica",
      id: "abreviatura_empresa",
      size: 250,
    }),
    columnHelper.accessor("cnpj", {
      header: "CNPJ",
      id: "cnpj",
      size: 200,
    }),
    columnHelper.accessor("codigo", {
      header: "Código da Fábrica",
      id: "codigo",
      size: 200,
    }),
    columnHelper.accessor("criado_por_nome", {
      header: "Criado por",
      id: "criado_por_nome",
      size: 300,
    }),
    columnHelper.display({
      id: "Ações",
      header: "Ações",
      size: 100,
      cell: ({ row }) => {
        return <ActionCell items={<ActionDropdown {...row.original} />} />;
      },
    }),
  ];

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getTableColumnOrder({ column: columns, key: TABLE_ORDER_KEY })
  );

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  async function handleDelete() {
    if (isPending) return;
    try {
      await mutateAsync({ id: Number(simulator?.SK_simulacao) });
      clear();
      return toast.success("Simulador excluído com sucesso.");
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <AuthenticatedLayout>
      <TableContainer>
        <Table<SimulatorTableType>
          columns={columns}
          data={simulators}
          columnOrderState={columnOrder}
          onColumnOrderStateChange={handleColumnOrder}
          meta={{ layout: "stretch" }}
        />
      </TableContainer>
      <DeleteDialogAlert
        onCancel={clear}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        onConfirm={handleDelete}
        type={dialogType}
        alertDialogTitle={`Deseja excluir o simulador, "${simulator?.SK_simulacao}"?. Essa ação é irreversível.`}
      />
    </AuthenticatedLayout>
  );
}
