import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { IoMdThumbsDown } from "react-icons/io";
import { PublishDropdownItemProps } from "./PublishDropdownItem";
import { usePackagingSimulatorStatusMutation } from "@/hooks/usePackagingSimulatorStatusMutation";
import { validationError } from "@/utils/api";

export default function RejectDropdownItem({
  skPackagingSimulator,
}: PublishDropdownItemProps) {
  const { mutateAsync, isPending } = usePackagingSimulatorStatusMutation();

  async function handlePublishItem() {
    if (isPending) return;
    try {
      await mutateAsync({
        SK_simulacao_embalagem: skPackagingSimulator,
        status: "RASCUNHO",
      });
    } catch (err) {
      validationError(err);
    }
  }

  return (
    <DropdownMenuItem onClick={handlePublishItem}>
      <Row align="center">
        <IoMdThumbsDown className="mr-2" color={TEXT_COLOR} />
        <Text>Rejeitar</Text>
      </Row>
    </DropdownMenuItem>
  );
}
