import Text from "@/components/Text/Text";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { useAlertDialogContext } from "@/pages/Estimativas/context/AlertDialogContext";
import { MdHistoryEdu } from "react-icons/md";
import { ItemProps } from "./EditItem";

export default function StatusItem(props: ItemProps) {
  const { handleDialogVisibility, handleSheet } = useAlertDialogContext();

  function handlePublishItem() {
    handleDialogVisibility();
    handleSheet({ ...props, tipo: "status" });
  }

  return (
    <DropdownMenuItem onClick={handlePublishItem}>
      <MdHistoryEdu className="mr-2" color={TEXT_COLOR} size={14} />
      <Text>Histórico</Text>
    </DropdownMenuItem>
  );
}
