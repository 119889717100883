import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";
import { PackagingPlansType } from "../hooks/usePackagingPlans";
import { SeePackagingPlanFormType } from "../components/Form/See/zod";

type DialogTypeType = "edit" | "delete" | "nonSelected";

type PackagingPlanContextProps = {
  handleDialog: () => void;
  handleDialogType: (type: DialogTypeType) => void;
  handlePackagingPlan: (factory: PackagingPlansType) => void;
  handleFilterPackagingPlan: (filter: SeePackagingPlanFormType) => void;
  clear: () => void;
  visibleDialog: boolean;
  dialogType: DialogTypeType;
  packagingPlan: PackagingPlansType;
  filterPackagingPlan: SeePackagingPlanFormType;
};

const PackagingPlanContext = createContext({} as PackagingPlanContextProps);

export default function PackagingPlanProvider({ children }: ReactChildrenType) {
  const [filterPackagingPlan, setFilterPackagingPlan] =
    useState<SeePackagingPlanFormType>({} as SeePackagingPlanFormType);
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [packagingPlan, setPackagingPlan] = useState<PackagingPlansType>(
    {} as PackagingPlansType
  );

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handlePackagingPlan(value: PackagingPlansType) {
    setPackagingPlan(value);
  }

  function handleFilterPackagingPlan(filter: SeePackagingPlanFormType) {
    setFilterPackagingPlan(filter);
  }

  function clear() {
    setVisibleDialog(false);
    setPackagingPlan({} as PackagingPlansType);
    setFilterPackagingPlan({} as SeePackagingPlanFormType);
    setDialogType("nonSelected");
  }

  return (
    <PackagingPlanContext.Provider
      value={{
        clear,
        handleDialogType,
        handleDialog,
        handlePackagingPlan,
        handleFilterPackagingPlan,
        filterPackagingPlan,
        packagingPlan,
        visibleDialog,
        dialogType,
      }}
    >
      {children}
    </PackagingPlanContext.Provider>
  );
}

export function usePackagingPlanContext() {
  return useContext(PackagingPlanContext);
}
