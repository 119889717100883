import instance from "@/api/business";
import { FactoryColumnsType } from "@/pages/Factory";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { factoryKeys } from "./useFactoryMutation";

export default function useFactoryPatchMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: FactoryColumnsType) => {
      return instance.patch("/planningbeer/factory/", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: factoryKeys.all });
    },
  });
}
