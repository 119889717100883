import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useFactoryResourceContext } from "../../context";
import { useFactoryResource } from "@/hooks/useFactoryResource";
import EditFactoryResourceForm from "../Form/EditFactoryResourceForm";

export default function EditFactoryResourceDialog() {
  const { handleDialog, clear, dialogType, visibleDialog, factoryResource } =
    useFactoryResourceContext();
  const { data, isFetching } = useFactoryResource({
    id: Number(factoryResource?.SK_fabrica_recurso),
  });
  const { data: factoryResourceData } = data || {};
  const firstFactoryResourceData = factoryResourceData?.[0];

  function handleEditDialog() {
    handleDialog();
    clear();
  }

  if (dialogType !== "edit" || isFetching || !firstFactoryResourceData)
    return null;
  return (
    <Dialog onOpenChange={handleEditDialog} open={visibleDialog}>
      <DialogContent>
        <EditFactoryResourceForm
          data={firstFactoryResourceData}
          dialogVisiblity={handleEditDialog}
        />
      </DialogContent>
    </Dialog>
  );
}
