import instance from "@/api/business";
import { useQuery } from "@tanstack/react-query";
import { ApiObjectResponse } from "./useEstimates";

export type ProductType = {
  SK_produto: string;
  descricao: string;
  tipo_produto: string;
  grupo: string;
  classe_produto: string;
  classificacao: string;
  litragem: string;
  tipo_liquido: string;
  volume: string;
  formacao: string;
  camadas: string;
  lastro: string;
  tipo_planejador: string;
};

export type FactoriesAndResourcesType = {
  codigo: string;
  abreviatura_empresa: string;
  recursos: {
    descricao: string;
    codigo_linha_de_producao: string;
  }[];
};

export type ProductionPerMonthType = {
  ano_mes: string;
  producao_mes: number;
};

export type ProductDetailType = {
  information: ProductType;
  factoriesAndResources?: Array<FactoriesAndResourcesType>;
  productionPerMonth?: Array<ProductionPerMonthType>;
};

async function fetchProduct(id: string) {
  if (!id) return;
  const response = await instance.post<ApiObjectResponse<ProductDetailType>>(
    "/planningbeer/productDetail",
    {
      SkProduto: id,
    }
  );
  return response.data;
}

export default function useProduct(id: string) {
  return useQuery({
    queryKey: ["product", id],
    queryFn: () => fetchProduct(id),
  });
}
