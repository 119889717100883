import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useResourceTypeContext } from "../../context";
import EditResourceTypeForm from "../Form/EditResourceTypeForm";

export default function EditResourceTypeDialog() {
  const { visibleDialog, clear, dialogType } = useResourceTypeContext();
  if (dialogType !== "edit") return null;
  return (
    <Dialog open={visibleDialog} onOpenChange={clear}>
      <DialogContent>
        <EditResourceTypeForm />
      </DialogContent>
    </Dialog>
  );
}
