import Col from "@/components/Col";
import Text from "@/components/Text/Text";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import { usePackagingPlanContext } from "../../context";
import {
  PackagingType,
  usePackagingSuppliers,
} from "@/hooks/usePackagingSuppliers";
import Each from "@/components/Each";
import { quantityFormater } from "@/utils/number";
import dayjs from "dayjs";
import { Separator } from "@/components/ui/separator";
import {
  calculateTotalCargos,
  calculateTotalPallets,
} from "@/pages/SimulacoesEmbalagem/utils/carga";
import { getOperationType } from "../../utils/operationType";
import { TEXT_COLOR } from "@/constants";
import { Checkbox } from "@/components/ui/checkbox";
import {
  SupplierPackagingCompositionStepType,
  useDeliveryAuthorizationContext,
} from "../../context/DeliveryAuthorizationContext";
import SupplierPackagingSelect from "../Select/SupplierPackagingSelect";
import { useState } from "react";
import { PiBroom } from "react-icons/pi";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export type AssociateSupplierWithValuesType = PackagingType & {
  valores: SupplierPackagingCompositionStepType[];
};

function associateSuppliersWithValues(
  supplierList: Array<PackagingType>,
  filledSupplierValues: Array<SupplierPackagingCompositionStepType>
): AssociateSupplierWithValuesType[] {
  const suppliersWithValues = supplierList
    .map((fornecedor) => {
      const valores = filledSupplierValues.filter(
        (valor) =>
          valor.FK_embalagem_fornecedor == fornecedor.SK_embalagem_fornecedor
      );

      return {
        ...fornecedor,
        valores,
      };
    })
    .filter((fornecedor) => fornecedor.valores.length > 0);

  return suppliersWithValues;
}

export default function SupplierPackagingStep() {
  const {
    nextStep,
    selectedComposition: selectedCompositionContext,
    handleSelectedComposition,
    getKey,
  } = useDeliveryAuthorizationContext();
  const {
    packagingPlan: { valores_fornecedores },
  } = usePackagingPlanContext();
  const packagingsSuppliersCellValue: Array<SupplierPackagingCompositionStepType> =
    JSON.parse(valores_fornecedores || "[]");

  const { data } = usePackagingSuppliers();
  const { data: packagingSupplier = [] } = data || {};

  const packagingsSuppliers = packagingSupplier.flatMap(
    (item) => item.embalagens
  );

  const relatedSuppliers = associateSuppliersWithValues(
    packagingsSuppliers,
    packagingsSuppliersCellValue
  );
  const [renderedSuppliers, setRenderedSuppliers] = useState({
    filter: relatedSuppliers,
    value: "",
  });
  const [selectedComposition, setSelectedComposition] = useState<
    SupplierPackagingCompositionStepType[]
  >(selectedCompositionContext);

  function handleSelectItem(item: SupplierPackagingCompositionStepType) {
    const itemKey = item.composicao + item.FK_embalagem_fornecedor + item.dia;

    setSelectedComposition((prevSelected) => {
      const isAlreadySelected = prevSelected.some((selectedItem) => {
        const selectedItemKey = getKey(selectedItem);
        return selectedItemKey == itemKey;
      });

      if (isAlreadySelected) {
        return prevSelected.filter((selectedItem) => {
          const selectedItemKey = getKey(selectedItem);
          return selectedItemKey !== itemKey;
        });
      } else {
        return [...prevSelected, item];
      }
    });
  }

  function isItemSelected(item: SupplierPackagingCompositionStepType) {
    const itemKey = item.composicao + item.FK_embalagem_fornecedor + item.dia;

    return selectedComposition.some((selectedItem) => {
      const selectedItemKey = getKey(selectedItem);

      return selectedItemKey == itemKey;
    });
  }

  function handleSupplier(value: string) {
    setRenderedSuppliers({
      filter: relatedSuppliers.filter(
        (supplier) => supplier.codigo_fornecedor == value
      ),
      value,
    });
  }

  return (
    <>
      <Col className="h-full px-1 overflow-y-scroll">
        <Text className="text-lg font-bold">Selecionar Produtos</Text>
        <Text className="text-sm mt-2">Fornecedor</Text>
        <Row className="mb-4">
          <Button
            size="icon"
            variant="outline"
            className="mr-2"
            onClick={() => {
              setRenderedSuppliers({
                filter: relatedSuppliers,
                value: "",
              });
            }}
          >
            <PiBroom size={22} color={TEXT_COLOR} />
          </Button>
          <SupplierPackagingSelect
            value={renderedSuppliers.value}
            onValueChange={handleSupplier}
            options={relatedSuppliers}
          />
        </Row>
        <Each
          array={renderedSuppliers.filter}
          renderItem={(renderedSupplier) => {
            const operationType = getOperationType(
              renderedSupplier.tipo_de_operacao
            );
            return (
              <Accordion type="single" collapsible>
                <AccordionItem
                  value={
                    renderedSupplier.nome_fornecedor +
                    renderedSupplier.SK_embalagem_fornecedor
                  }
                >
                  <AccordionTrigger>
                    <div className="border rounded-sm py-2 px-4 w-full flex flex-col items-start">
                      <Text className="text-base font-bold">
                        {renderedSupplier.codigo_fornecedor} -{" "}
                        {renderedSupplier.nome_fornecedor}
                      </Text>
                      <Text>
                        Tipo de operação: {operationType.ABREVIATURA_EMPRESA} -{" "}
                        {operationType.SK_EMPRESA}
                      </Text>
                      <Text className="text-sm">
                        Composição: {renderedSupplier.composicao} -{" "}
                        {renderedSupplier.descricao_composicao}
                      </Text>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <Each
                      array={renderedSupplier.valores}
                      renderItem={(valor) => {
                        return (
                          <Row align="center">
                            <Col className="w-full">
                              <Col className="ml-4 mt-2  w-full">
                                <Row className="gap-2">
                                  <Text>
                                    Composição:{" "}
                                    <strong>
                                      {valor.composicao}
                                      {" - "}
                                      {renderedSupplier.descricao_composicao}
                                    </strong>
                                  </Text>
                                  <Text>
                                    Quantidade:{" "}
                                    <strong>
                                      {quantityFormater(Number(valor.valor))}
                                    </strong>
                                  </Text>
                                  <Text>
                                    Data:{" "}
                                    <strong>
                                      {dayjs(valor.dia).format("DD/MM/YYYY")}
                                    </strong>
                                  </Text>
                                </Row>
                                <Col className="gap-1">
                                  <Text>
                                    Paletes por Carga:{" "}
                                    <strong>
                                      {valor.quantidade_palete_carga}
                                    </strong>
                                  </Text>
                                  <Text>
                                    Quantidade por Palete:{" "}
                                    <strong>
                                      {quantityFormater(
                                        Number(valor.quantidade_por_palete)
                                      )}
                                    </strong>
                                  </Text>
                                  <Text>
                                    Quantidade de cargas:{" "}
                                    <strong>
                                      {calculateTotalCargos(
                                        calculateTotalPallets(
                                          Number(valor.valor),
                                          Number(valor.quantidade_por_palete)
                                        ),
                                        Number(
                                          valor.quantidade_palete_carga || 25
                                        )
                                      )}
                                    </strong>
                                  </Text>
                                </Col>
                                <Separator className="mb-1 w-full" />
                              </Col>
                            </Col>
                            <Checkbox
                              onClick={() => {
                                handleSelectItem({
                                  ...valor,
                                  descricao_composicao:
                                    renderedSupplier.descricao_composicao,
                                  fornecedor: renderedSupplier,
                                });
                              }}
                              checked={isItemSelected(valor)}
                            />
                          </Row>
                        );
                      }}
                    />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            );
          }}
        />
      </Col>
      <Row justify="end" className="gap-2" align="end">
        <Button
          disabled={selectedComposition.length === 0}
          onClick={() => {
            handleSelectedComposition(selectedComposition);
            nextStep();
          }}
        >
          Continuar
        </Button>
      </Row>
    </>
  );
}
