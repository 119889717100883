import { ColumnDef } from "@tanstack/react-table";

export function getTableColumnOrder({
  key,
  column,
}: {
  key: string;
  column: ColumnDef<any, any>[];
}) {
  const columnOrder = localStorage.getItem(key);
  if (columnOrder) {
    return JSON.parse(columnOrder);
  }
  return column.map((column) => column.id || "");
}
