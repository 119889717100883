import Each from "@/components/Each";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Separator } from "@/components/ui/separator";
import usePackagings, { PackagingType } from "@/hooks/usePackagings";
import PackagingItem from "./components/Item/PackagingItem";
import Col from "@/components/Col";
import { useEffect, useState } from "react";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import usePackagingPatchMutation from "@/hooks/usePackagingPatchMutation";
import { Input } from "@/components/ui/input";

const ACTIVE = 1;
const INACTIVE = 0;

export type SelectedItemsType = {
  SK_embalagem: string;
  position: string;
};

function filterAcitivityPackagings(
  packagings: Array<PackagingType>,
  status: number
) {
  const filtered = packagings
    .filter((packaging) =>
      packaging?.produtos?.some((product) => product?.ativo == status)
    )
    .map((packaging) => ({
      ...packaging,
      produtos: packaging?.produtos?.filter(
        (product) => product?.ativo == status
      ),
    }));

  return filtered;
}

function filteredItems(items: Array<PackagingType>, filter: string) {
  return items.filter((item) =>
    item?.descricao?.toLowerCase().includes(filter.toLowerCase())
  );
}

export default function Packagings() {
  const { mutateAsync, isPending } = usePackagingPatchMutation();
  const { data } = usePackagings();
  const { data: packagingsData = [] } = data || {};
  const [packagings, setPackagings] = useState<Array<PackagingType>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<SelectedItemsType>>(
    []
  );
  const [activeInput, setActiveInput] = useState("");
  const [inactiveInput, setInactiveInput] = useState("");
  const activePackagings = filterAcitivityPackagings(packagings, ACTIVE);
  const inactivePackagings = filterAcitivityPackagings(packagings, INACTIVE);

  const filteredActivePackagings = filteredItems(activePackagings, activeInput);
  const filteredInactivePackagings = filteredItems(
    inactivePackagings,
    inactiveInput
  );

  function handleProductSelection({
    SK_embalagem,
    position,
  }: SelectedItemsType) {
    const isSelected = selectedItems.some(
      (item) => item.SK_embalagem === SK_embalagem
    );

    if (isSelected) {
      setSelectedItems((prev) =>
        prev.filter((item) => item.SK_embalagem !== SK_embalagem)
      );
      return;
    }

    setSelectedItems((prev) => [...prev, { SK_embalagem, position }]);
  }

  function handleRightToLeft() {
    const packagingsToMove = selectedItems.map(
      ({ SK_embalagem }) => SK_embalagem
    );
    const updatedProducts = packagings.map((packaging) => {
      const updatedProducts = packaging.produtos.map((product) => {
        if (packagingsToMove.includes(product.SK_embalagem)) {
          return { ...product, ativo: INACTIVE };
        }
        return product;
      });

      return { ...packaging, produtos: updatedProducts };
    });
    setPackagings(updatedProducts);
    setSelectedItems([]);
  }

  function handleLeftToRight() {
    const packagingsToMove = selectedItems.map(
      ({ SK_embalagem }) => SK_embalagem
    );
    const updatedProducts = packagings.map((packaging) => {
      const updatedProducts = packaging.produtos.map((product) => {
        if (packagingsToMove.includes(product.SK_embalagem)) {
          return { ...product, ativo: ACTIVE };
        }
        return product;
      });

      return { ...packaging, produtos: updatedProducts };
    });
    setPackagings(updatedProducts);
    setSelectedItems([]);
  }

  function handleAllRightToLeft() {
    const updatedProducts = packagings.map((packaging) => {
      const updatedProducts = packaging.produtos.map((product) => ({
        ...product,
        ativo: INACTIVE,
      }));

      return { ...packaging, produtos: updatedProducts };
    });
    setPackagings(updatedProducts);
    setSelectedItems([]);
  }

  function handleAllLeftToRight() {
    const updatedProducts = packagings.map((packaging) => {
      const updatedProducts = packaging.produtos.map((product) => ({
        ...product,
        ativo: ACTIVE,
      }));

      return { ...packaging, produtos: updatedProducts };
    });
    setPackagings(updatedProducts);
    setSelectedItems([]);
  }

  async function handlePackagings() {
    if (isPending) return;
    const packagingKey = activePackagings.flatMap(({ produtos }) =>
      produtos.map(({ SK_embalagem }) => SK_embalagem)
    );

    try {
      await mutateAsync({
        skPackaging: packagingKey,
      });
      setSelectedItems([]);
      return toast.success("Embalagens salvas com sucesso!");
    } catch (error) {
      validationError(error);
    }
  }

  useEffect(() => {
    if (!packagingsData) return;
    setPackagings(packagingsData);
  }, [packagingsData]);

  return (
    <AuthenticatedLayout>
      <Row
        justify="space-between"
        className="max-h-[calc(100vh-150px)] h-full"
        align="center"
      >
        <div className="border h-full overflow-y-scroll w-1/2">
          <Text className="font-bold text-center mt-2">Embalagens</Text>
          <Row className="w-full p-4">
            <Input
              list="productLeft"
              placeholder="Pesquisar embalagem..."
              onChange={(e) => {
                setInactiveInput(e.target.value);
              }}
            />
          </Row>
          <Separator />
          <Each
            array={filteredInactivePackagings}
            renderItem={(props) => (
              <PackagingItem
                selectedItems={selectedItems.map((item) => item.SK_embalagem)}
                position="left"
                onSelect={handleProductSelection}
                {...props}
              />
            )}
          />
        </div>
        <Col className="mx-6">
          <Col className="mx-6 items-center">
            <MdKeyboardDoubleArrowLeft
              className="mb-2"
              size={22}
              onClick={handleAllRightToLeft}
            />
            <MdKeyboardArrowLeft size={30} onClick={handleRightToLeft} />
            <MdKeyboardArrowRight size={30} onClick={handleLeftToRight} />
            <MdKeyboardDoubleArrowRight
              className="mt-2"
              size={22}
              onClick={handleAllLeftToRight}
            />
          </Col>
        </Col>
        <div className="border h-full overflow-y-scroll w-1/2">
          <Text className="font-bold text-center mt-2">
            Embalagens Utilizadas Planning Beer
          </Text>
          <Row className="w-full p-4">
            <Input
              list="productRight"
              onChange={(e) => {
                setActiveInput(e.target.value);
              }}
              placeholder="Pesquisar embalagem..."
            />
          </Row>
          <Separator />
          <Each
            array={filteredActivePackagings}
            renderItem={(props) => (
              <PackagingItem
                selectedItems={selectedItems.map((item) => item.SK_embalagem)}
                position="right"
                onSelect={handleProductSelection}
                {...props}
              />
            )}
          />
        </div>
      </Row>
      <Row justify="end" className="mt-4">
        <Button onClick={handlePackagings} disabled={isPending}>
          Salvar
        </Button>
      </Row>
    </AuthenticatedLayout>
  );
}
