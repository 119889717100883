import {
    DropdownMenuItem,
    DropdownMenuSubContent,
} from '@/components/ui/dropdown-menu'
import { Table } from '@tanstack/react-table'

import { useEffect, useMemo, useState } from 'react'
import Item from './components/Item'
import {
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
    SortableContext,
    arrayMove,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'

interface ColumnListProps<T> {
    table: Table<T>
}

const ColumnList = <T,>({ table }: ColumnListProps<T>) => {
    const { setColumnOrder, getState } = table

    const columns = useMemo(() => table.getAllColumns(), [table])

    const [order, setOrder] = useState(getState().columnOrder)

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event

        if (active && over && active.id !== over.id) {
            setOrder((columnOrder) => {
                const oldIndex = columnOrder!.indexOf(active.id as string)
                const newIndex = columnOrder!.indexOf(over.id as string)
                return arrayMove(columnOrder!, oldIndex, newIndex)
            })
        }
    }

    useEffect(() => {
        setColumnOrder(order)
    }, [order])

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    )

    return (
        <DropdownMenuSubContent className="max-h-[300px] overflow-y-auto">
            <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}
                sensors={sensors}
                onDragEnd={handleDragEnd}
            >
                <DropdownMenuItem
                    className="justify-center gap-3 focus:bg-transparent focus:text-neutral-500"
                    onClick={(e) => e.preventDefault()}
                >
                    <button
                        className="px-1 rounded-md hover:text-primary-500 hover:bg-slate-100"
                        onClick={() => {
                            table.toggleAllColumnsVisible(
                                !table.getIsAllColumnsVisible()
                            )
                        }}
                    >
                        {table.getIsAllColumnsVisible()
                            ? 'Ocultar tudo'
                            : 'Mostrar tudo'}
                    </button>
                    <button
                        className="px-1 rounded-md hover:text-primary-500 hover:bg-slate-100"
                        onClick={() => table.resetColumnPinning(true)}
                    >
                        Desfixar tudo
                    </button>
                </DropdownMenuItem>
                <SortableContext
                    items={order}
                    strategy={verticalListSortingStrategy}
                >
                    {order.map((ord) => {
                        const column = columns.find((col) => col.id === ord)

                        return column ? (
                            <Item key={ord} column={column} />
                        ) : null
                    })}
                </SortableContext>
            </DndContext>
        </DropdownMenuSubContent>
    )
}

export default ColumnList
