import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PackagingSimulationResponseType,
  PackagingSupplierCellValueType,
} from "./usePackagingSimulationMutation";
import { packagingSimulatorKeys } from "./usePackagingSimulators";
import { ResponseDataType } from "@/types";

type PackagingSimulatorPatchMutationType = {
  FK_simulacao_producao: number;
  FK_simulacao_embalagem: number;
  valores_fornecedores: Array<PackagingSupplierCellValueType>;
};

export default function usePackagingSimulatorPatchMutation(
  fkPackagingSimulation: number
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: PackagingSimulatorPatchMutationType) => {
      const response = instance.patch<
        ResponseDataType<PackagingSimulationResponseType>
      >("/planningbeer/packagingSimulator", { ...data });

      return response;
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: packagingSimulatorKeys.show(fkPackagingSimulation),
      });
    },
  });
}
