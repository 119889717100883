import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import ForgotPassword from "./pages/RecuperarSenha";
import Initial from "./pages/Inicio";
import AuthMiddlware from "./middleware/AuthMiddleware";
import Estimates from "./pages/Estimativas";
import { ROUTES } from "./constants";
import NotFound from "./pages/NotFound";
import EditEstimate from "./pages/Estimativas/Editar";
import SeeEstimate from "./pages/Estimativas/Visualizar";
import ChangePassword from "./pages/AlterarSenha";
import DevOnly from "./pages/DevOnly";
import Products from "./pages/Products";
import AdministratorMiddleware from "./middleware/AdministratorMiddleware";
import Simulations from "./pages/Simulacoes";
import Factory from "./pages/Factory";
import Resources from "./pages/Recursos";
import FactoryResource from "./pages/FabricaRecurso";
import ResourcesIndisponibility from "./pages/IndisponibilidadeRecursos";
import Simulators from "./pages/Simuladores";
import EditSimulator from "./pages/Simuladores/Editar";
import FactoryResourceProduct from "./pages/FabricaRecursoProdutos";
import ResourceType from "./pages/TipoRecurso";
import Inventory from "./pages/Inventario";
import SeeInventory from "./pages/Inventario/Visualizar";
import PackagingSimulators from "./pages/SimuladoresEmbalagem";
import PackagingSimulations from "./pages/SimulacoesEmbalagem";
import EditPackagingSimulator from "./pages/SimuladoresEmbalagem/Editar";
import Suppliers from "./pages/Fornecedores";
import Packagings from "./pages/Embalagens";
import PackagingSuppliers from "./pages/EmbalagemFornecedores";
import Help from "./pages/Ajuda";
import ProductionPlan from "./pages/PlanoProducao";
import SeeProductionPlan from "./pages/PlanoProducao/Visualizar";
import SeeSimulator from "./pages/Simuladores/Visualizar";
import EditProductionPlan from "./pages/PlanoProducao/Editar";
import PackagingPlan from "./pages/PlanoEmbalagem";
import SeePackagingPlan from "./pages/PlanoEmbalagem/Visualizar";
import EditPackagingPlan from "./pages/PlanoEmbalagem/Editar";
import SeePackagingSimulator from "./pages/SimuladoresEmbalagem/Visualizar";
import CargoModel from "./pages/ModeloCarga";
import SupplierAccess from "./pages/AcessoFornecedores";
import SupplierStock from "./pages/EstoqueFornecedor";
import Transporter from "./pages/Transportador";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="devOnly" element={<DevOnly />} />
        <Route element={<AuthMiddlware />}>
          <Route path="/">
            <Route path={ROUTES.initial} element={<Initial />} />
            <Route path={ROUTES.changePassword} element={<ChangePassword />} />
            <Route path={ROUTES.help} element={<Help />} />
            <Route path={ROUTES.forecast.index}>
              <Route index element={<Estimates />} />
              <Route path={ROUTES.forecast.edit} element={<EditEstimate />} />
              <Route path={ROUTES.forecast.see} element={<SeeEstimate />} />
            </Route>
            <Route element={<AdministratorMiddleware />}>
              <Route
                path={ROUTES.transporter.index}
                element={<Transporter />}
              />
              <Route path={ROUTES.productionPlan.index}>
                <Route index element={<ProductionPlan />} />
                <Route
                  path={ROUTES.productionPlan.edit}
                  element={<EditProductionPlan />}
                />
                <Route
                  path={ROUTES.productionPlan.see}
                  element={<SeeProductionPlan />}
                />
              </Route>
              <Route path={ROUTES.packagingPlan.index}>
                <Route index element={<PackagingPlan />} />
                <Route
                  path={ROUTES.packagingPlan.edit}
                  element={<EditPackagingPlan />}
                />
                <Route
                  path={ROUTES.packagingPlan.see}
                  element={<SeePackagingPlan />}
                />
              </Route>
              <Route path={ROUTES.packagingSimulators.index}>
                <Route index element={<PackagingSimulators />} />
                <Route
                  path={ROUTES.packagingSimulators.edit}
                  element={<EditPackagingSimulator />}
                />
                <Route
                  path={ROUTES.packagingSimulators.see}
                  element={<SeePackagingSimulator />}
                />
              </Route>
              <Route path={ROUTES.simulators.index}>
                <Route index element={<Simulators />} />
                <Route
                  path={ROUTES.simulators.edit}
                  element={<EditSimulator />}
                />
                <Route
                  path={ROUTES.simulators.see}
                  element={<SeeSimulator />}
                />
              </Route>
              <Route path={ROUTES.packagingSimulations.index}>
                <Route index element={<PackagingSimulations />} />
              </Route>
              <Route path={ROUTES.products.index}>
                <Route index element={<Products />} />
              </Route>
              <Route path={ROUTES.packagings.index}>
                <Route index element={<Packagings />} />
              </Route>
              <Route path={ROUTES.packagingSuppliers.index}>
                <Route index element={<PackagingSuppliers />} />
              </Route>
              <Route path={ROUTES.suppliers.index}>
                <Route index element={<Suppliers />} />
              </Route>
              <Route path={ROUTES.simulations.index}>
                <Route index element={<Simulations />} />
              </Route>
              <Route path={ROUTES.factory.index}>
                <Route index element={<Factory />} />
              </Route>
              <Route path={ROUTES.resources.index}>
                <Route index element={<Resources />} />
              </Route>
              <Route path={ROUTES.resourcesType.index}>
                <Route index element={<ResourceType />} />
              </Route>
              <Route path={ROUTES.factoryResource.index}>
                <Route index element={<FactoryResource />} />
              </Route>
              <Route path={ROUTES.resourceIndisponibility.index}>
                <Route index element={<ResourcesIndisponibility />} />
              </Route>
              <Route path={ROUTES.factoryResourceProduct.index}>
                <Route index element={<FactoryResourceProduct />} />
              </Route>
              <Route path={ROUTES.inventory.index}>
                <Route index element={<Inventory />} />
                <Route
                  path={ROUTES.inventory.edit}
                  element={<SeeInventory />}
                />
              </Route>
              <Route path={ROUTES.cargoModel.index}>
                <Route index element={<CargoModel />} />
              </Route>
              <Route path={ROUTES.supplierAccess.index}>
                <Route index element={<SupplierAccess />} />
              </Route>
              <Route path={ROUTES.supplierStock.index}>
                <Route index element={<SupplierStock />} />
              </Route>
            </Route>
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="recuperarSenha" element={<ForgotPassword />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
