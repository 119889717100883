import Text from "@/components/Text/Text";

type EditEstimatePopoverContentProps = {
  previousValue: number | string;
  currentValue: number | string;
};

export default function EditEstimatePopoverContent({
  currentValue,
  previousValue,
}: EditEstimatePopoverContentProps) {
  return (
    <div className="flex w-full items-center gap-8 justify-between px-2 py-1">
      <Text className="text-sm ">{previousValue}</Text>
      <Text className="text-sm">{currentValue}</Text>
    </div>
  );
}
