import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const schema = z.object({
  periodo_inicio: z.date({ required_error: REQUIRED_FIELD }),
  periodo_fim: z.date({ required_error: REQUIRED_FIELD }),
  segregado: z.string().optional(),
});

export type SeePackagingPlanFormType = z.infer<typeof schema>;
