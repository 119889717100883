import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { packagingSupplierKey } from "./usePackagingSuppliers";

export type PackagingSupplierMutationType = {
  fkFactory: Array<string>;
  fkSupplier: Array<string>;
  fkPackaging: Array<string>;
  leadTime: string;
  palleteQuantity: string;
  operationType: string;
};

export function usePackagingSupplierMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: PackagingSupplierMutationType) => {
      return instance.put("/planningbeer/packagingSupplier", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: packagingSupplierKey.all });
    },
  });
}
