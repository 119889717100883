import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { PackagingType } from "@/hooks/usePackagings";
import { quantityFormater } from "@/utils/number";
import { useMemo } from "react";
import {
  getComposition,
  getQuantityCheckedPallets,
} from "../../utils/buildLoadUtils";
import { SelectedPalleteType } from "../Steps/BuildLoad/LoadPackaging";

type LoadPackagingSupplierHeaderProps = {
  valor: string;
  totalPallets: number;
  checkedPalletsQuantity: number;
  fkSupplier: string;
  day: string;
  composition: string;
  selectedPallets: SelectedPalleteType[];
  packagings?: PackagingType[];
  quantityPerPallet?: string | number;
};

export default function LoadPackagingSupplierHeader({
  composition,
  day,
  fkSupplier,
  selectedPallets,
  packagings = [],
  quantityPerPallet = 0,
  totalPallets = 0,
  valor = "0",
  checkedPalletsQuantity = 0,
}: LoadPackagingSupplierHeaderProps) {
  const selectedPalletsInQuantity = useMemo(
    () => checkedPalletsQuantity * Number(quantityPerPallet || 0),
    [checkedPalletsQuantity]
  );

  const packagingComposition = useMemo(
    () => getComposition(packagings, composition),
    []
  );

  const checkedPalletCount = useMemo(
    () =>
      getQuantityCheckedPallets(selectedPallets, {
        composition: composition,
        day: day,
        supplier: fkSupplier,
      }),
    [selectedPallets]
  );

  return (
    <Row className="gap-2">
      <Text>
        {packagingComposition?.composicao.toString() +
          " - " +
          packagingComposition?.descricao}
      </Text>
      <Text>Quantidade: {quantityFormater(Number(valor) || 0)}</Text>
      <Text className="font-bold">
        Selecionados: {checkedPalletCount} / {quantityFormater(totalPallets)} -{" "}
        {quantityFormater(selectedPalletsInQuantity || 0)}
      </Text>
    </Row>
  );
}
