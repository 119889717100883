import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const schema = z.object({
  FK_fabrica: z.string({ required_error: REQUIRED_FIELD }),
  FK_recurso: z.string({ required_error: REQUIRED_FIELD }),
  vigencia_inicio: z.date().optional(),
  vigencia_fim: z.date().optional(),
  turno_de_trabalho: z.string().max(200).optional(),
});
