import Row from "@/components/Row";
import ProductTitleText, {
  ProductTitleTextProps,
} from "../../Text/ProductTitleText";
import Col from "@/components/Col";
import Text from "@/components/Text/Text";
import FactorySelect from "@/components/Select/FactorySelect";
import { useMemo, useState } from "react";
import { Separator } from "@/components/ui/separator";
import {
  ProductPackagingResponseType,
  useProductPackaging,
} from "@/hooks/useProductPackaging";
import { TableData } from "@/components/NewTable/type";
import Table from "@/components/NewTable";
import { createColumnHelper } from "@tanstack/react-table";
import TableContainer from "@/components/Container/TableContainer";

type PackagingContentProps = { onClose: () => void } & ProductTitleTextProps;

type PackagingTableProps = {
  codigo: string;
  nome_completo: string;
  nome_reduzido: string;
} & Omit<ProductPackagingResponseType, "fornecedores">;

type PackagingTableType = TableData<PackagingTableProps>;

const columnHelper = createColumnHelper<PackagingTableType>();

export default function PackagingContent({ product }: PackagingContentProps) {
  const [factoryKey, setFactoryKey] = useState<number>();
  const { data } = useProductPackaging({
    fkProduct: product.code,
    fkFactory: factoryKey,
  });
  const { data: productPackaging = [] } = data || {};

  const columns = [
    columnHelper.accessor("composicao", {
      header: "Produto",
      id: "composicao",
      meta: {
        enableColumnOrdering: true,
        row: {
          isGrouped: true,
        },
      },
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição",
      id: "descricao",
      size: 500,
    }),
    columnHelper.accessor("codigo", {
      header: "Código",
      id: "codigo",
    }),
    columnHelper.accessor("nome_completo", {
      header: "Nome Completo",
      id: "nome_completo",
    }),
    columnHelper.accessor("nome_reduzido", {
      header: "Nome Reduzido",
      id: "nome_reduzido",
    }),
  ];

  const dataSubRows = useMemo(() => {
    return productPackaging.map((packaging) => {
      return {
        composicao: packaging.composicao,
        descricao: packaging.descricao,
        subRows: [
          ...packaging.fornecedores.map((supplier) => {
            return {
              composicao: packaging.composicao,
              descricao: packaging.descricao,
              codigo: supplier.codigo,
              nome_completo: supplier.nome_completo,
              nome_reduzido: supplier.nome_reduzido,
            };
          }),
        ],
      };
    });
  }, [productPackaging]);

  return (
    <>
      <div className="flex flex-col w-full h-full box-border">
        <ProductTitleText product={product} />
        <Row
          align="center"
          className="w-full px-2 mt-2"
          justify="space-between"
        >
          <Col className="w-1/6 mb-4 justify-center ali">
            <Text>Fábrica</Text>
            <FactorySelect
              onValueChange={(e) => {
                setFactoryKey(Number(e));
              }}
            />
          </Col>
        </Row>
        <Separator />
        <TableContainer className="p-2 h-[calc(100vh-220px)]">
          <Table<PackagingTableType>
            columns={columns}
            data={dataSubRows as unknown as PackagingTableType[]}
            meta={{
              layout: "stretch",
            }}
          />
        </TableContainer>
      </div>
    </>
  );
}
