import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

export default function MonthSelect(props: SelectProps) {
  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
    >
      <SelectTrigger>
        <SelectValue defaultValue="Janeiro" placeholder="Janeiro" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="janeiro">Janeiro</SelectItem>
        <SelectItem value="fevereiro">Fevereiro</SelectItem>
        <SelectItem value="março">Março</SelectItem>
        <SelectItem value="abril">Abril</SelectItem>
        <SelectItem value="maio">Maio</SelectItem>
        <SelectItem value="junho">Junho</SelectItem>
        <SelectItem value="julho">Julho</SelectItem>
        <SelectItem value="agosto">Agosto</SelectItem>
        <SelectItem value="setembro">Setembro</SelectItem>
        <SelectItem value="outubro">Outubro</SelectItem>
        <SelectItem value="novembro">Novembro</SelectItem>
        <SelectItem value="dezembro">Dezembro</SelectItem>
      </SelectContent>
    </Select>
  );
}
