import { Dialog, DialogContent } from "@/components/ui/dialog";
import EditFactoryForm from "../Form/EditFactoryForm";
import { useFactoryContext } from "../../context";
import useFactory from "@/hooks/useFactory";
import useBusiness from "@/hooks/useBusiness";

export default function EditFabricDialog() {
  const { visibleDialog, handleDialog, dialogType, clear, factory } =
    useFactoryContext();
  const { data, isFetching } = useFactory({ id: Number(factory.SK_fabrica) });
  const { isFetching: isFetchingBusiness } = useBusiness();
  const { data: factoryArrayData } = data || {};
  const factoryData = factoryArrayData?.[0];

  if (dialogType !== "edit" || isFetching || isFetchingBusiness) return null;

  function handleEditDialog() {
    handleDialog();
    clear();
  }

  return (
    <Dialog onOpenChange={handleEditDialog} open={visibleDialog}>
      <DialogContent>
        <EditFactoryForm
          data={factoryData}
          handleDialog={() => handleEditDialog()}
        />
      </DialogContent>
    </Dialog>
  );
}
