import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import CreatePackagingPlanDialog from "./components/Dialog/CreatePackagingPlanDialog";
import { useProductionPlans } from "@/hooks/useProductionPlans";
import {
  PackagingPlansType,
  usePackagingPlans,
} from "./hooks/usePackagingPlans";
import { TableData } from "@/components/NewTable/type";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import Table from "@/components/NewTable";
import { useState } from "react";
import { getTableColumnOrder } from "@/utils/table";
import dayjs from "dayjs";
import ActionCell from "@/components/Cell/ActionCell";
import ActionDropdown from "./components/Dropdown";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import { usePackagingPlanContext } from "./context";
import { usePackagingPlanDeleteMutation } from "./hooks/usePackagingPlanDeleteMutation";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import { VersionBadge } from "@/components/Badge/VersionBadge";

const TABLE_ORDER_KEY = "packagingPlanTable";
export type PackagingPlanTable = TableData<PackagingPlansType>;
const columnHelper = createColumnHelper<PackagingPlanTable>();

export default function PackagingPlan() {
  const {
    clear,
    dialogType,
    visibleDialog,
    packagingPlan: { SK_plano_embalagem },
  } = usePackagingPlanContext();
  const { isPending, mutateAsync } = usePackagingPlanDeleteMutation();
  useProductionPlans();
  const { data } = usePackagingPlans();
  const { data: packagingPlans = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_plano_embalagem", {
      header: "ID",
      id: "id",
      size: 90,
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição",
      id: "descricao",
      size: 200,
    }),
    columnHelper.accessor("versao", {
      header: "Versão",
      id: "versao",
      cell: ({
        row: {
          original: { versao },
        },
      }) => {
        return <VersionBadge revisao={versao} />;
      },
    }),
    columnHelper.accessor("periodo_inicio", {
      header: "Período de Início",
      id: "periodo_inicio",
      size: 200,
      cell: ({
        row: {
          original: { periodo_inicio },
        },
      }) => <span>{dayjs(periodo_inicio).format("DD/MM/YYYY")}</span>,
    }),
    columnHelper.accessor("periodo_fim", {
      header: "Período de Fim",
      id: "periodo_fim",
      size: 200,
      cell: ({
        row: {
          original: { periodo_fim },
        },
      }) => <span>{dayjs(periodo_fim).format("DD/MM/YYYY")}</span>,
    }),
    columnHelper.accessor("codigo", {
      header: "Código Empresa",
      id: "codigo",
      size: 200,
    }),
    columnHelper.accessor("abreviatura_empresa", {
      header: "Empresa",
      id: "abreviatura_empresa",
    }),
    columnHelper.accessor("criado_por", {
      header: "Criado por",
      id: "criado_por",
      size: 300,
    }),
    columnHelper.accessor("FK_plano_producao", {
      header: "Plano de Produção",
      id: "FK_plano_producao",
    }),
    columnHelper.accessor("descricao_plano_producao", {
      header: "Descrição do Plano de Produção",
      id: "descricao_plano_producao",
      size: 300,
    }),
    columnHelper.display({
      header: "Ações",
      id: "Ações",
      size: 100,
      cell: ({ row }) => {
        return <ActionCell items={<ActionDropdown {...row.original} />} />;
      },
    }),
  ];

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getTableColumnOrder({ column: columns, key: TABLE_ORDER_KEY })
  );

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  async function handleDelete() {
    if (isPending) return;
    try {
      await mutateAsync({ id: Number(SK_plano_embalagem) });
      clear();
      return toast.success(
        "Plano de Ressuprimento de Embalagens excluído com sucesso!"
      );
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <AuthenticatedLayout>
      <Row justify="end">
        <CreatePackagingPlanDialog />
      </Row>
      <Table
        columns={columns}
        data={packagingPlans}
        meta={{
          layout: "stretch",
        }}
        columnOrderState={columnOrder}
        onColumnOrderStateChange={handleColumnOrder}
      />
      <DeleteDialogAlert
        onConfirm={handleDelete}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        onCancel={clear}
        type={dialogType}
        alertDialogTitle={`Deseja excluir o plano de ressuprimento de embalagens, "${SK_plano_embalagem}"?. Essa ação é irreversível.`}
      />
    </AuthenticatedLayout>
  );
}
