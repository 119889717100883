import Col from "@/components/Col";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { REQUIRED_FIELD, TEXT_COLOR } from "@/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { PopoverPortal } from "@radix-ui/react-popover";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsStars } from "react-icons/bs";
import { z } from "zod";

const schema = z.object({
  leadTime: z.string({ required_error: REQUIRED_FIELD }),
  replacementTime: z.string({ required_error: REQUIRED_FIELD }),
});
type InventoryParameterFormType = z.infer<typeof schema>;

type InventoryParameterPopoverProps = {
  onGenerate: (data: InventoryParameterFormType) => void;
};

export default function InventoryParameterPopover({
  onGenerate,
}: InventoryParameterPopoverProps) {
  const [openPopover, setOpenPopover] = useState(false);
  const form = useForm<InventoryParameterFormType>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  function handleCalculate(data: InventoryParameterFormType) {
    onGenerate({
      leadTime: data.leadTime,
      replacementTime: data.replacementTime,
    });
  }

  return (
    <Popover
      open={openPopover}
      onOpenChange={() => {
        setOpenPopover((prev) => !prev);
      }}
    >
      <PopoverTrigger>
        <Button variant="ghost">
          <BsStars size={20} color={TEXT_COLOR} />
        </Button>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent>
          <Col>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(handleCalculate)}>
                <FormField
                  control={form.control}
                  name="leadTime"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormControl>
                        <Input
                          {...field}
                          label="Tempo de Entrega (Dias) *"
                          type="number"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="replacementTime"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          label="Tempo de Reposição (Dias) *"
                          type="number"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button className="mt-4">Gerar</Button>
              </form>
            </Form>
          </Col>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
}
