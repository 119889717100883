import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resourceKeys } from "./useResources";

export default function useResourceDeleteMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return instance.delete("/planningbeer/resource", { data: { id } });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: resourceKeys.all });
    },
  });
}
