import instance from "@/api/business";
import { IdParamType, ResponseDataType } from "@/types";
import { useQuery } from "@tanstack/react-query";
import { productionSimulationsKey } from "./useProductionSimulators";
import { ProductionSimulationResponseType } from "./useProductionSimulationMutation";

async function productionSimulation({ id }: IdParamType) {
  if (!id) return null;
  const response = await instance.post<
    ResponseDataType<ProductionSimulationResponseType>
  >("/planningbeer/simulator/detail", {
    id,
  });
  return response.data;
}

export function useProductionSimulator({ id }: IdParamType) {
  return useQuery({
    queryKey: productionSimulationsKey.show(Number(id)),
    queryFn: () => productionSimulation({ id }),
    staleTime: 0,
    gcTime: 0,
  });
}
