import { cn } from "@/lib/utils";
import React from "react";

type EachProps<T> = {
  array: Array<T>;
  wrapper?: React.HTMLAttributes<HTMLDivElement>;
  renderItem: (item: T, index?: number) => React.ReactNode;
};

export default function Each<T>({ array, renderItem, wrapper }: EachProps<T>) {
  return (
    <React.Fragment>
      {array.map((value, index) => (
        <div className={cn(wrapper?.className)} key={index}>
          {renderItem(value, index)}
        </div>
      ))}
    </React.Fragment>
  );
}
