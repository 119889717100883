import instance from "@/api/business";
import { ApiObjectResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type ContractItemType = {
  SK_contrato: string;
  filial: string;
  numero_contrato: string;
  fornecedor: string;
  item: string;
  produto: string;
  descricao: string;
  quantidade: string;
  quantidade_entregue: string;
  preco: string;
  data_inicial: string;
  data_final: string;
  valor_total: string;
  filial_entrega: string;
  responsavel: string;
  emissao: string;
  created_at: string;
  quantidade_restante?: number;
};

export type ContractType = {
  [key: string]: ContractItemType[];
};

export const contractsKey = {
  all: ["contracts"] as const,
};

async function fetchContracts() {
  const response = await instance.post<ApiObjectResponse<ContractType>>(
    "/planningbeer/contract"
  );
  return response.data;
}

export function useContracts() {
  return useQuery({
    queryFn: fetchContracts,
    queryKey: contractsKey.all,
  });
}
