import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { FactoryColumnsType } from "@/pages/Factory";
import { useFactoryContext } from "@/pages/Factory/context";
import { FaTrash } from "react-icons/fa";

export default function DeleteDropdownItem(props: FactoryColumnsType) {
  const { handleDialogType, handleFactory, handleDialog } = useFactoryContext();

  function handleDeleteItem() {
    handleDialog();
    handleDialogType("delete");
    handleFactory({ ...props });
  }

  return (
    <DropdownMenuItem onClick={handleDeleteItem}>
      <Row>
        <FaTrash className="mr-2" color={TEXT_COLOR} />
        <Text>Deletar</Text>
      </Row>
    </DropdownMenuItem>
  );
}
