import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

export default function PackingSelect(props: SelectProps) {
  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="Lata">Lata</SelectItem>
        <SelectItem value="Garrada de vidro">Garrafa de vidro</SelectItem>
        <SelectItem value="Garrafa de alumínio">Garrafa de alumínio</SelectItem>
        <SelectItem value="Barril">Barril</SelectItem>
        <SelectItem value="Todos">Todos</SelectItem>
      </SelectContent>
    </Select>
  );
}
