import { PackagingSupplierCellValueType } from "@/hooks/usePackagingSimulationMutation";
import { createContext, useContext, useState } from "react";
import { AssociateSupplierWithValuesType } from "../components/Steps/SupplierPackagingStep";
import { ContractItemType } from "../hooks/useContracts";

export type SelectedSupplierValuesType = {
  SK_embalagem_fornecedor: number;
  composicao: string;
  descricao_composicao: string;
  nome_fornecedor: string;
  codigo_fornecedor: string;
  abreviatura_empresa: string;
  FK_fabrica: number;
  FK_fornecedor: string;
  lead_time: number;
  quantidade_por_palete: number;
  tipo_de_operacao: string;
  valores_selecionados: {
    dia: string;
    valor: string;
    quantidade_palete_carga: string;
    quantidade_por_palete: string;
    descricao_composicao: string;
  }[];
  contrato_selecionado?: ContractItemType;
};

export type SupplierPackagingCompositionStepType =
  PackagingSupplierCellValueType & {
    descricao_composicao?: string;
    fornecedor?: AssociateSupplierWithValuesType;
  };

type DeliveryAuthorizationContextType = {
  step: number;
  selectedComposition: SupplierPackagingCompositionStepType[];
  getKey: (value: SupplierPackagingCompositionStepType) => string;
  nextStep: () => void;
  previousStep: () => void;
  handleSelectedComposition: (
    data: SupplierPackagingCompositionStepType[]
  ) => void;
  clearSelectedComposition: () => void;
};

const DeliveryAuthorizationContext = createContext(
  {} as DeliveryAuthorizationContextType
);

export function DeliveryAuthorizationProvider({ children }) {
  const [selectedComposition, setSelectedComposition] = useState<
    SupplierPackagingCompositionStepType[]
  >([]);
  const [step, setStep] = useState(0);

  function nextStep() {
    setStep((prevStep) => prevStep + 1);
  }

  function previousStep() {
    setStep((prevStep) => {
      if (prevStep > 0) return prevStep - 1;
      return prevStep;
    });
  }

  function handleSelectedComposition(
    data: SupplierPackagingCompositionStepType[]
  ) {
    setSelectedComposition(data);
  }

  function clearSelectedComposition() {
    setSelectedComposition([]);
    setStep(0);
  }

  function getKey(value: SupplierPackagingCompositionStepType) {
    return value.composicao + value.FK_embalagem_fornecedor + value.dia;
  }

  return (
    <DeliveryAuthorizationContext.Provider
      value={{
        getKey,
        nextStep,
        previousStep,
        handleSelectedComposition,
        clearSelectedComposition,
        step,
        selectedComposition,
      }}
    >
      {children}
    </DeliveryAuthorizationContext.Provider>
  );
}

export function useDeliveryAuthorizationContext() {
  return useContext(DeliveryAuthorizationContext);
}
