import Col from "@/components/Col";
import Each from "@/components/Each";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { PackagingSupplierCellValueType } from "@/hooks/usePackagingSimulationMutation";
import { usePackagingPlanContext } from "@/pages/PlanoEmbalagem/context";
import dayjs from "dayjs";
import { usePackagingSupplierGrouped } from "@/pages/PlanoEmbalagem/hooks/usePackagingSupplierGrouped";
import usePackagings from "@/hooks/usePackagings";
import { useEffect, useState } from "react";
import CargoModelSelect from "@/components/Select/CargoModelSelect";
import BuildLoadForm, {
  BuildLoadFormType,
} from "../../Form/BuildLoad/BuildLoadForm";
import {
  filterPackagingSupplierByComposition,
  filterPackagingSupplierByDate,
  getPackagingSupplier,
  groupBySupplier,
} from "@/pages/PlanoEmbalagem/utils/buildLoadUtils";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import isBetween from "dayjs/plugin/isBetween";
import SummaryLoadPackagingContent from "../../Content/SummaryLoadPackagingContent";
import LoadPackagingContent from "../../Content/LoadPackagingContent";
import SummaryBuildLoadModal from "../../Modal/SummaryBuildLoadModal";
import useBuildLoadMutation from "@/pages/PlanoEmbalagem/hooks/useBuildLoadMutation";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import { DialogProps } from "@/types";
import { VIEW_SELECTED } from "../../Select/ViewSelectedSelect";
dayjs.extend(isBetween);

export type SelectedPalleteType = {
  composition: string;
  pallet: number;
  day: string;
  supplier: string;
  type?: string;
  quantity_per_pallet?: number;
};

export type SuppliersPackageType = {
  fkSupplier: string;
  supplierName: string;
  fkTransporter?: string;
  palletPerCargo?: number;
  dates: {
    day: string;
    items: PackagingSupplierCellValueType[];
  }[];
};

export default function LoadPackagingStep({ handleClose }: DialogProps) {
  const { mutateAsync, isPending } = useBuildLoadMutation();
  const [visibleSummaryModal, setVisibleSummaryModal] = useState(false);
  //prettier-ignore
  const [selectedPallete, setSelectedPalete] = useState<SelectedPalleteType[]>([]);
  //prettier-ignore
  const [suppliersPackage, setSuppliersPackage] = useState<SuppliersPackageType[]>([]);
  const form = useForm<BuildLoadFormType>();
  const { data } = usePackagingSupplierGrouped();
  const { data: packagingSupplierGrouped = [] } = data || {};
  const { data: packagingsData } = usePackagings();
  const { data: packagings = [] } = packagingsData || {};
  const {
    packagingPlan: { valores_fornecedores, SK_plano_embalagem },
  } = usePackagingPlanContext();
  const [packagingValues, setPackagingValues] = useState(
    JSON.parse(valores_fornecedores || "[]") as PackagingSupplierCellValueType[]
  );

  function handleSelectAll(selectedValue: SelectedPalleteType[]) {
    setSelectedPalete((prevState) => {
      const selectedPallets = selectedValue.filter((value) => {
        const index = prevState.findIndex(
          (selected) =>
            selected.composition == value.composition &&
            selected.pallet == value.pallet &&
            selected.day == value.day &&
            selected.supplier == value.supplier
        );
        return index < 0;
      });
      return [...prevState, ...selectedPallets];
    });
  }

  function handleUnselectAll(selectedValue: SelectedPalleteType[]) {
    setSelectedPalete((prevState) => {
      return prevState.filter((value) => {
        const index = selectedValue.findIndex(
          (selected) =>
            selected.composition == value.composition &&
            selected.pallet == value.pallet &&
            selected.day == value.day &&
            selected.supplier == value.supplier
        );
        return index < 0;
      });
    });
  }

  function handleSelectPallete(selectedValue: SelectedPalleteType) {
    setSelectedPalete((prevState) => {
      const index = prevState.findIndex(
        (value) =>
          value.composition == selectedValue.composition &&
          value.pallet == selectedValue.pallet &&
          value.day == selectedValue.day &&
          value.supplier == selectedValue.supplier
      );
      if (index >= 0) {
        return prevState.filter(
          (value) =>
            value.composition != selectedValue.composition ||
            value.pallet !== selectedValue.pallet ||
            value.day != selectedValue.day ||
            value.supplier != selectedValue.supplier
        );
      }
      return [...prevState, selectedValue];
    });
  }

  function handleTransporter(fkSupplier: string, transporter: string) {
    const transporterInformation = transporter?.split("_");
    const transporterCargo = transporterInformation?.[1];
    const fkTransporter = transporterInformation?.[0];

    setSuppliersPackage((prevState) => {
      return prevState.map((value) => {
        if (value.fkSupplier == fkSupplier) {
          return {
            ...value,
            palletPerCargo: Number(transporterCargo || 0),
            fkTransporter,
          };
        }
        return value;
      });
    });
  }

  function handleType(selectedValue: SelectedPalleteType) {
    setSelectedPalete((prevState) => {
      const index = prevState.findIndex(
        (value) =>
          value.composition == selectedValue.composition &&
          value.pallet == selectedValue.pallet &&
          value.day == selectedValue.day
      );
      if (index >= 0) {
        return prevState.map((value) => {
          if (
            value.composition == selectedValue.composition &&
            value.pallet == selectedValue.pallet &&
            value.day == selectedValue.day
          ) {
            return {
              ...value,
              type: selectedValue.type,
            };
          }
          return value;
        });
      }
      return [...prevState, selectedValue];
    });
  }

  function handleFilter() {
    const supplierValues = JSON.parse(
      valores_fornecedores || "[]"
    ) as PackagingSupplierCellValueType[];
    let filteredValues = supplierValues;
    const selectedPackagings = form.getValues("embalagens") || [];
    const displayMode = form.getValues("visualizando") || "all";

    filteredValues = filterPackagingSupplierByDate(form, filteredValues);
    filteredValues = filterPackagingSupplierByComposition(
      selectedPackagings,
      filteredValues
    );

    if (displayMode == VIEW_SELECTED) {
      filteredValues = filteredValues.filter((value) => {
        return selectedPallete.some(
          (selected) =>
            selected.composition == value.composicao &&
            selected.day == value.dia
        );
      });
    }

    setPackagingValues(filteredValues);
  }

  function clear() {
    setSelectedPalete([]);
    setSuppliersPackage([]);
  }

  function clearFilter() {
    form.reset({
      visualizando: "all",
    });
    setPackagingValues(JSON.parse(valores_fornecedores || "[]"));
  }

  async function handleSave() {
    const hasTransporter = selectedPallete.some((selectedPallet) => {
      const supplierPackage = suppliersPackage.find(
        (supplier) => supplier.fkSupplier == selectedPallet.supplier
      );
      return !!supplierPackage?.fkSupplier;
    });
    if (!hasTransporter) return toast.error("Selecione um transportador");

    const structure = {
      selectedPallete,
      suppliersPackage,
    };

    try {
      await mutateAsync({
        fkPackagingPlan: SK_plano_embalagem,
        structure: JSON.stringify(structure),
        observation: "",
      });
      toast.success("Programação salva com sucesso!");
      handleClose();
      return clear();
    } catch (err) {
      return validationError(err);
    }
  }

  useEffect(() => {
    if (!packagingValues || !packagingSupplierGrouped) return;
    setSuppliersPackage(
      groupBySupplier(packagingValues, packagingSupplierGrouped)
    );
  }, [data, packagingValues]);

  return (
    <>
      <SummaryBuildLoadModal
        packagings={packagings}
        suplliersPackage={suppliersPackage}
        selectedPallete={selectedPallete}
        visible={visibleSummaryModal}
        handleClose={() => {
          setVisibleSummaryModal(!visibleSummaryModal);
        }}
      />
      <Col className="overflow-y-scroll">
        <Row align="end" className="w-full pr-2">
          <BuildLoadForm form={form} />
          <Button className="mb-2 mr-2" onClick={handleFilter}>
            Filtrar
          </Button>
          <Button className="mb-2" variant="outline" onClick={clearFilter}>
            Limpar
          </Button>
        </Row>
        <Each
          array={suppliersPackage}
          renderItem={({
            dates,
            fkSupplier,
            supplierName,
            palletPerCargo,
            fkTransporter,
          }) => {
            const packagingSupplier = getPackagingSupplier(
              selectedPallete,
              fkSupplier
            );
            const totalSelectedPallets = packagingSupplier.length;

            return (
              <>
                <Row className=" gap-4 p-2" justify="space-between">
                  <Col className="border border-solid w-3/4 rounded-sm ">
                    <LoadPackagingContent
                      packagings={packagings}
                      selectedPallets={packagingSupplier}
                      dates={dates}
                      supplierFk={fkSupplier}
                      supplierName={supplierName}
                      onChangeType={handleType}
                      onCheckPallete={handleSelectPallete}
                      onCheckAll={handleSelectAll}
                      onUncheckAll={handleUnselectAll}
                    />
                  </Col>
                  <Col className="border border-solid w-1/2 p-2 rounded-sm">
                    <Row className="gap-2" align="center">
                      <Text>Transportador</Text>
                      <CargoModelSelect
                        value={fkTransporter + "_" + palletPerCargo || ""}
                        onValueChange={(e) => {
                          handleTransporter(fkSupplier, e);
                        }}
                      />
                    </Row>
                    <SummaryLoadPackagingContent
                      totalSelectedPallets={totalSelectedPallets}
                      dates={dates}
                      transporterCargo={palletPerCargo}
                    />
                  </Col>
                </Row>
              </>
            );
          }}
        />
      </Col>
      <Row className="gap-2" justify="end" align="end">
        <Button
          variant="outline"
          onClick={() => {
            setVisibleSummaryModal(!visibleSummaryModal);
          }}
        >
          Visualizar Resumo
        </Button>
        <Button
          onClick={handleSave}
          disabled={selectedPallete.length == 0 || isPending}
        >
          Salvar Programação
        </Button>
      </Row>
    </>
  );
}
