import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { productionPlanKeys } from "./useProductionPlanMutation";
import { productionSimulationKey } from "./useProductionSimulation";

type ProductionPlanPatchMutationType = {
  skProductionPlan: number;
  revision: number | string;
  lineValues: string;
  oldLineValues?: string;
};

export function useProductionPlanPatchMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ProductionPlanPatchMutationType) => {
      return instance.patch("/planningbeer/productionPlan/simulators", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productionPlanKeys.all });
      queryClient.invalidateQueries({
        queryKey: productionSimulationKey.show,
      });
    },
  });
}
