import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { productionPlanSimulatorsKey } from "./useProductionPlanSimulators";

type ProductionSimulationPatchLineValueMutationType = {
  fkProductionSimulation: number;
  revision: string;
  lineValues: string;
};

export function useProductionSimulationPatchLineValueMutation({
  factoryKey,
}: {
  factoryKey: number;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      data: ProductionSimulationPatchLineValueMutationType
    ) => {
      await instance.patch("/planningbeer/simulator/lineValue", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: productionPlanSimulatorsKey.show(factoryKey),
      });
    },
  });
}
