import React from "react";

type CanProps = {
  condition: boolean;
  onFalse?: React.ReactNode | Array<React.ReactNode>;
  onTrue: React.ReactNode | Array<React.ReactNode>;
};

export default function Can({
  condition,
  onFalse = <React.Fragment></React.Fragment>,
  onTrue,
}: CanProps) {
  return condition ? onTrue : onFalse;
}
