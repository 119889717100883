import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { TEXT_COLOR } from "@/constants";
import { MdChecklist, MdRemoveDone } from "react-icons/md";
import { LoadPackagingContentProps } from "../Content/LoadPackagingContent";
import {
  SelectedPalleteType,
  SuppliersPackageType,
} from "../Steps/BuildLoad/LoadPackaging";

type SelectPalletRowActionsProps = {
  composition: string;
  day: string;
  fkSupplier: string;
  fkPackagingSupplier: number;
  pallets: number[];
} & Pick<LoadPackagingContentProps, "dates" | "onCheckAll" | "onUncheckAll">;

type FormatSelectAllProps = {
  dates: SuppliersPackageType["dates"];
  pallet: number[];
  supplier: string;
  composition: string;
  day: string;
  fkPackagingSupplier: number;
};

export function SelectPalletRowActions({
  dates,
  composition,
  day,
  fkPackagingSupplier,
  fkSupplier,
  pallets,
  onCheckAll,
  onUncheckAll,
}: SelectPalletRowActionsProps) {
  return (
    <>
      <Row className="gap-1">
        <Button
          variant="outline"
          size="icon"
          onClick={() => {
            const grouped = formatSelectAll({
              composition: composition,
              dates: dates,
              day: day,
              fkPackagingSupplier: fkPackagingSupplier,
              pallet: pallets,
              supplier: fkSupplier,
            });
            onCheckAll(grouped);
          }}
        >
          <MdChecklist size={20} color={TEXT_COLOR} />
        </Button>
        <Button
          variant="outline"
          size="icon"
          onClick={() => {
            const grouped = formatSelectAll({
              composition: composition,
              dates: dates,
              day: day,
              fkPackagingSupplier: fkPackagingSupplier,
              pallet: pallets,
              supplier: fkSupplier,
            });
            onUncheckAll(grouped);
          }}
        >
          <MdRemoveDone size={20} color={TEXT_COLOR} />
        </Button>
      </Row>
      <Separator className="mt-2" />
    </>
  );
}

function formatSelectAll({
  composition,
  dates,
  day,
  pallet,
  supplier,
  fkPackagingSupplier,
}: FormatSelectAllProps): SelectedPalleteType[] {
  const formated = pallet.flatMap((value) => {
    return dates.flatMap((date) => {
      return date.items
        .filter(
          (item) =>
            item.composicao == composition &&
            item.dia == day &&
            item.FK_embalagem_fornecedor == fkPackagingSupplier
        )
        .map((item) => {
          return {
            composition: item.composicao,
            pallet: value,
            day: date.day,
            supplier: supplier,
            type: "default",
            quantity_per_pallet: Number(item.quantidade_por_palete || 0),
          };
        });
    });
  });
  return formated;
}
