import instance from "@/api/business";
import { ApiResponse, estimates } from "./useEstimates";
import { EstimateDetailType } from "@/pages/Estimativas/Editar";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

async function fetchEstimate({ id }: { id: number }) {
  const response = await instance.post<ApiResponse<EstimateDetailType>>(
    "/planningbeer/forecast/details",
    {
      id,
    }
  );
  return response.data;
}

export default function useEstimate({ id }: { id: number }) {
  return useQuery({
    queryKey: estimates.show(id),
    queryFn: () => fetchEstimate({ id }),
    placeholderData: keepPreviousData,
  });
}
