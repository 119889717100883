import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import { PackagingPlanFormType } from "./zod";
import Row from "@/components/Row";
import { DatePicker } from "@/components/DatePicker";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import ProductionPlanSelect from "@/components/Select/ProductionPlanSelect";

type PackagingPlanFormProps = {
  button?: {
    title: string;
    disabled: boolean;
  };
  form: UseFormReturn<PackagingPlanFormType, any, undefined>;
  onSubmit: (data: PackagingPlanFormType) => void;
};

export default function PackagingPlanForm({
  form,
  button,
  onSubmit,
}: PackagingPlanFormProps) {
  const validityStartDate = form.watch("periodo_inicio");
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name={"FK_plano_producao"}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Plano de Produção *</FormLabel>
              <FormControl>
                <ProductionPlanSelect
                  onValueChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row className="gap-2">
          <FormField
            control={form.control}
            name="periodo_inicio"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Período Inicial *</FormLabel>
                <FormControl>
                  <DatePicker {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="periodo_fim"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Período final *</FormLabel>
                <FormControl>
                  <DatePicker {...field} minDate={validityStartDate} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Row>
        <FormField
          control={form.control}
          name="descricao"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Descrição *</FormLabel>
              <FormControl>
                <Input {...field} maxLength={150} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={button?.disabled}>
            <Text color="white">{button?.title}</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
