import Lottie from "lottie-react";
import animationData from "@/assets/images/fQS3LP3QEG.json";

export default function DotsLoading() {
  return (
    <div className="flex space-x-2 justify-center items-center bg-white dark:invert h-full">
      <Lottie animationData={animationData} loop={true} />
    </div>
  );
}
