import { Column } from '@tanstack/react-table'
import { CSSProperties } from 'react'

export const getCommonPinningStyles = <T>(column: Column<T>): CSSProperties => {
    const isPinned = column.getIsPinned()
    // const isLastLeftPinnedColumn =
    //     isPinned === 'left' && column.getIsLastColumn('left')
    // const isFirstRightPinnedColumn =
    //     isPinned === 'right' && column.getIsFirstColumn('right')

    return {
        // boxShadow: isLastLeftPinnedColumn
        //     ? '#c2c2c28a -8px 0px 4px -4px inset'
        //     : isFirstRightPinnedColumn
        //     ? '#c2c2c28a 8px 0px 4px -4px inset'
        //     : undefined,
        left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
        right:
            isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
        opacity: isPinned ? 0.95 : 1,
        position: isPinned ? 'sticky' : 'relative',
        width: column.getSize(),
        zIndex: isPinned ? 1 : 0,
        backgroundColor: isPinned ? 'white' : '',
    }
}
