import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AUTH_ROUTES } from "@/constants";
import Cookies from "js-cookie";

export default function AuthMiddlware() {
  const { pathname } = useLocation();

  const isAuthenticated = Cookies.get("token");
  if (!isAuthenticated && !AUTH_ROUTES.includes(pathname)) {
    return <Navigate to="/login" replace={true} />;
  }

  if ((isAuthenticated && AUTH_ROUTES.includes(pathname)) || pathname == "/") {
    return <Navigate to="/inicio" replace={true} />;
  }

  return <Outlet />;
}
