import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";

type LoadingContextProps = {
  isLoading: boolean;
  handleLoading: (isLoading: boolean) => void;
};

const LoadingContext = createContext({} as LoadingContextProps);

export default function LoadingProvider({ children }: ReactChildrenType) {
  const [isLoading, setIsLoading] = useState(false);
  function handleLoading(isLoading: boolean) {
    setIsLoading(isLoading);
  }

  return (
    <LoadingContext.Provider value={{ isLoading, handleLoading }}>
      {isLoading}
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoadingContext() {
  return useContext(LoadingContext);
}
