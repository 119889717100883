import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Toaster } from "./components/ui/sonner";
import Router from "./Router";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import Providers from "./providers";

function App() {
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToHorizontalAxis]}
        sensors={sensors}
      >
        <Providers>
          <Toaster expand={true} />
          <Router />
        </Providers>
      </DndContext>
    </>
  );
}

export default App;
