import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import CreateInventoryDialog from "./components/Dialog/CreateInventoryDialog";
import Table from "@/components/NewTable";
import { InventoryTableType, useInventory } from "@/hooks/useInventory";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import { TableData } from "@/components/NewTable/type";
import ActionCell from "@/components/Cell/ActionCell";
import dayjs from "dayjs";
import { useState } from "react";
import { getTableColumnOrder } from "@/utils/table";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import { useInventoryContext } from "./context";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import useInventoryDeleteMutation from "@/hooks/useInventoryDeleteMutation";
import TableContainer from "@/components/Container/TableContainer";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const columnHelper = createColumnHelper<InventoryTableType>();
const TABLE_ORDER_KEY = "inventoryColumnOrder";

type InventoryTablePropsType = TableData<InventoryTableType>;

export default function Inventory() {
  const { inventory, clear, dialogType, visibleDialog } = useInventoryContext();
  const { mutateAsync, isPending } = useInventoryDeleteMutation();
  const { data } = useInventory();
  const { data: inventoryData = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_inventario_carga", {
      header: "ID",
      id: "SK_inventario_carga",
      size: 100,
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição da carga",
      id: "descricao",
      size: 300,
    }),
    columnHelper.accessor("vigencia", {
      header: "Vigência",
      id: "vigencia",
      size: 140,
      cell: ({ row }) => {
        return dayjs(row.original.vigencia).format("DD/MM/YYYY");
      },
    }),
    columnHelper.accessor("nome_fabrica", {
      header: "Nome da fábrica",
      id: "nome_fabrica",
      size: 300,
    }),
    columnHelper.accessor("cnpj_fabrica", {
      header: "CNPJ da Fábrica",
      id: "cnpj_fabrica",
      size: 180,
    }),
    columnHelper.accessor("codigo_fabrica", {
      header: "Código da Fábrica",
      id: "codigo_fabrica",
      size: 200,
    }),
    columnHelper.accessor("criado_por_nome", {
      header: "Criado por",
      id: "criado_por_nome",
      size: 300,
    }),
    columnHelper.accessor("created_at", {
      header: "Data da Carga",
      id: "created_at",
      size: 200,
      cell: ({ row }) =>
        dayjs(row.original.created_at).format("DD/MM/YYYY HH:mm:ss"),
    }),
    columnHelper.accessor("editado_por_nome", {
      header: "Editado por",
      id: "editado_por_nome",
      size: 300,
    }),
    columnHelper.accessor("editado_por_data", {
      header: "Data da Edição",
      id: "editado_por_data",
      size: 200,
      cell: ({ row }) => {
        if (!row.original.editado_por_data) return;
        return dayjs(row.original.editado_por_data).format(
          "DD/MM/YYYY HH:mm:ss"
        );
      },
    }),
    columnHelper.display({
      header: "Ações",
      cell: ({ row }) => {
        return <ActionCell items={<ActionDropdown {...row.original} />} />;
      },
      id: "Ações",
    }),
  ];

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getTableColumnOrder({ column: columns, key: TABLE_ORDER_KEY })
  );

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  async function handleDelete() {
    if (isPending) return;
    try {
      await mutateAsync({ id: String(inventory?.SK_inventario_carga) });
      clear();
      return toast.success("Inventário excluído com sucesso.");
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <AuthenticatedLayout>
      <Row justify="end">
        <CreateInventoryDialog />
      </Row>
      <TableContainer>
        <Table<InventoryTablePropsType>
          columns={columns}
          data={inventoryData}
          columnOrderState={columnOrder}
          onColumnOrderStateChange={handleColumnOrder}
          columnOrderKey={TABLE_ORDER_KEY}
          meta={{ layout: "stretch" }}
        />
      </TableContainer>
      <DeleteDialogAlert
        onCancel={clear}
        onConfirm={handleDelete}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        type={dialogType}
        alertDialogTitle={`Deseja excluir o inventário, "${inventory.SK_inventario_carga}"?. Essa ação é irreversível.`}
      />
    </AuthenticatedLayout>
  );
}
