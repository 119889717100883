import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import SendStockDialog from "./components/Dialog/SendStockDialog";
import Row from "@/components/Row";
import Table from "@/components/NewTable";
import {
  SupplierStockType,
  useSupplierStocks,
} from "./hooks/useSupplierStocks";
import { getPermissionCookie } from "@/utils/cookies";
import { TableData } from "@/components/NewTable/type";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { quantityFormater } from "@/utils/number";

export type SupplierStockTableType = TableData<SupplierStockType>;

const columnHelper = createColumnHelper<SupplierStockTableType>();

export default function SupplierStock() {
  const role = getPermissionCookie();
  const { data: supplierStocksData } = useSupplierStocks(role);
  const { data: supplierStocks = [] } = supplierStocksData || {};

  const columns = [
    columnHelper.accessor("nome_completo_fornecedor", {
      header: "Fornecedor",
      id: "nome_completo_fornecedor",
      size: 300,
      meta: {
        row: {
          isGrouped: true,
        },
      },
    }),
    columnHelper.accessor("codigo", {
      id: "codigo",
      header: "Código do Produto",
      size: 200,
    }),
    columnHelper.accessor("descricao", {
      id: "descricao",
      header: "Descrição",
      size: 400,
    }),
    columnHelper.accessor("dia", {
      header: "Dia",
      id: "data",
      cell: ({
        row: {
          depth,
          original: { dia },
        },
      }) => {
        if (depth == 0) return <></>;
        return <span>{dayjs(dia).format("DD/MM/YYYY")}</span>;
      },
    }),
    columnHelper.accessor("quantidade", {
      header: "Quantidade",
      id: "quantidade",
      size: 130,
      cell: ({
        row: {
          depth,
          original: { quantidade },
        },
      }) => {
        if (depth == 0) return <></>;
        return <span>{quantityFormater(Number(quantidade))}</span>;
      },
    }),
    columnHelper.accessor("estado_fornecedor", {
      header: "Estado do fornecedor",
      id: "estado_fornecedor",
      size: 200,
    }),
    columnHelper.accessor("created_at", {
      header: "Data de envio",
      id: "created_at",
      cell: ({
        row: {
          depth,
          original: { created_at },
        },
      }) => {
        if (depth == 0) return <></>;
        return <span>{dayjs(created_at).format("DD/MM/YYYY")}</span>;
      },
    }),
    columnHelper.accessor("criado_por_nome", {
      header: "Criado por",
      id: "criado_por_nome",
      size: 300,
    }),
  ];

  return (
    <AuthenticatedLayout>
      <Row justify="end">
        <SendStockDialog />
      </Row>
      <Table
        columns={columns}
        data={supplierStocks}
        meta={{
          layout: "stretch",
        }}
      />
    </AuthenticatedLayout>
  );
}
