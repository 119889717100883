import instance from "@/api/business";
import {
  ProductionSimulationResponseType,
  UseProductionSimulationMutationType,
} from "./useProductionSimulationMutation";
import { ResponseDataType } from "@/types";
import { useQuery } from "@tanstack/react-query";

export const productionSimulationKey = {
  show: ["seeProductionSimulation"] as const,
};

async function fetchProductionSimulation(
  data: UseProductionSimulationMutationType
) {
  if (!data.periodo_inicio || !data.periodo_fim || !data.FK_fabrica) return;
  const response = await instance.put<
    ResponseDataType<ProductionSimulationResponseType>
  >("/planningbeer/productionSimulation", {
    ...data,
  });
  return response.data;
}

export function useProductionSimulation(
  data: UseProductionSimulationMutationType
) {
  return useQuery({
    queryKey: productionSimulationKey.show,
    queryFn: () => fetchProductionSimulation(data),
    gcTime: 0,
    staleTime: 0,
  });
}
