import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supplierKeys } from "./useSuppliers";

type SupplierPatchType = {
  skSupplier: Array<string>;
};

export function useSupplierPatchMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: SupplierPatchType) => {
      return instance.patch("/planningbeer/supplier", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: supplierKeys.all });
    },
  });
}
