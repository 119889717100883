import Row from "@/components/Row";
import Text from "@/components/Text/Text";

export type ProductTitleTextProps = {
  product: {
    code: string;
    description: string;
  };
};

export default function ProductTitleText({ product }: ProductTitleTextProps) {
  return (
    <Row justify="center" className="w-full shadow-md pb-4">
      <Text className="text-lg font-bold">
        {product.code} - {product.description}
      </Text>
    </Row>
  );
}
