import { Dialog, DialogContent } from "@/components/ui/dialog";
import EditIndisponibilityResourceForm from "../Form/EditIndisponibilityResourceForm";
import { useIndisponibilityResourceContext } from "../../context";
import { useIndisponibilityResource } from "@/hooks/useIndisponibilityResource";

export default function EditIndisponibilityResourceDialog() {
  const { visibleDialog, dialogType, clear, indisponibilityResource } =
    useIndisponibilityResourceContext();
  const { data } = useIndisponibilityResource({
    id: indisponibilityResource.SK_indisponibilidade_programada,
  });
  const { data: indisponibilityResourceArray } = data || {};
  const indisponibilityResourceData = indisponibilityResourceArray?.[0];

  if (dialogType !== "edit" || !indisponibilityResourceData) return null;
  return (
    <Dialog open={visibleDialog} onOpenChange={clear}>
      <DialogContent>
        <EditIndisponibilityResourceForm
          handleDialog={clear}
          data={indisponibilityResourceData}
        />
      </DialogContent>
    </Dialog>
  );
}
