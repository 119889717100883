import { Dialog, DialogContent } from "@/components/ui/dialog";
import EditFactoryResourceProductForm from "../Form/EditFactoryResourceProductForm";
import { useFactoryResourceProductContext } from "../../context";

export default function EditFactoryResourceProductDialog() {
  const { visibleDialog, clear, dialogType } =
    useFactoryResourceProductContext();
  if (dialogType !== "edit") return null;
  return (
    <Dialog open={visibleDialog} onOpenChange={clear}>
      <DialogContent>
        <EditFactoryResourceProductForm />
      </DialogContent>
    </Dialog>
  );
}
