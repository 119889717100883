import { quantityFormater } from "@/utils/number";
import { Input, InputProps } from "../ui/input";

export default function NumberMaskInput({ onChange, value }: InputProps) {
  return (
    <Input
      min={0}
      onChange={(e) => {
        e.target.value = e.target.value.replace(/\D/g, "");
        onChange?.(e);
      }}
      value={value ? quantityFormater(Number(value)) : undefined}
    />
  );
}
