import { useMutation, useQueryClient } from "@tanstack/react-query";
import { estimates } from "./useEstimates";
import instance from "@/api/business";
import { UpdatedValuesType } from "@/pages/Estimativas/Editar";

export type EstimatePatchMutationType = {
  rows: Array<UpdatedValuesType>;
};

export default function useEstimatePatchMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: EstimatePatchMutationType) => {
      return instance.patch("/planningbeer/forecast/details", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: estimates.all });
    },
  });
}
