import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SelectProps } from "@radix-ui/react-select";

export default function SelectPackagingTypeSelect(props: SelectProps) {
  return (
    <Select
      defaultValue={props.defaultValue}
      value={props.value}
      onValueChange={props.onValueChange}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="default">Modelo 1</SelectItem>
        <SelectItem value="strategic">Modelo 2</SelectItem>
      </SelectContent>
    </Select>
  );
}
