import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import SimulationTable from "@/pages/Simulacoes/components/Table/SimulationTable";
import { Link, useParams } from "react-router-dom";
import { useProductionSimulator } from "@/hooks/useProductionSimulator";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import React, { useEffect } from "react";
import Row from "@/components/Row";
import useFactories from "@/hooks/useFactories";
import useProducts from "@/hooks/useProducts";
import { Button } from "@/components/ui/button";
import { useFactoriesResources } from "@/hooks/useFactoriesResources";
import TableActionRow from "@/pages/Simulacoes/components/Row/TableActionRow";
import Can from "@/components/Can";
import SimulationError from "../components/Error/SimulationError";
import DotsLoading from "@/components/Loading/DotsLoading";

export default function SeeSimulator() {
  useFactories();
  useFactoriesResources();
  useProducts();
  const { id } = useParams();
  const {
    data,
    error,
    isPending: isPendingProductionSimulator,
  } = useProductionSimulator({
    id: String(id),
  });
  const { data: productionSimulation } = data || {};
  const {
    handleSimulationData,
    simulationData,
    clear,
    lines: { handleLineValuesArray },
    production: { handleAccumulatedProductionArray },
  } = useSimulationProductionContext();

  useEffect(() => {
    if (!productionSimulation) return;
    handleLineValuesArray(
      productionSimulation.simulador_producao.valores_linhas || []
    );

    handleAccumulatedProductionArray(
      productionSimulation.producao_real_acumulada || []
    );
    if (Object.keys(simulationData).length == 0) {
      handleSimulationData(productionSimulation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionSimulation]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  if (isPendingProductionSimulator)
    return (
      <AuthenticatedLayout className="h-full">
        <DotsLoading />
      </AuthenticatedLayout>
    );

  return (
    <AuthenticatedLayout>
      <Can
        condition={!error}
        onTrue={
          <React.Fragment>
            <Can
              condition={!isPendingProductionSimulator}
              onTrue={
                <React.Fragment>
                  <Row justify="end" align="center" className="gap-4">
                    <TableActionRow />
                  </Row>
                  <SimulationTable />
                  <Row justify="end" className="mt-4 gap-4">
                    <Link to="/simuladores">
                      <Button variant="ghost">Voltar</Button>
                    </Link>
                  </Row>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
        onFalse={<SimulationError />}
      />
    </AuthenticatedLayout>
  );
}
