import { useForm } from "react-hook-form";
import FactoryResourceProductForm from "..";
import { FactoryResourceProductFormType, schema } from "../zod";
import { zodResolver } from "@hookform/resolvers/zod";
import useResourceFactoryProductMutation from "@/hooks/useResourceFactoryProductMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import { DialogProps } from "@/types";

export default function CreateFactoryResourceProductForm({
  handleClose,
}: DialogProps) {
  const { isPending, mutateAsync } = useResourceFactoryProductMutation();
  const form = useForm<FactoryResourceProductFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });

  async function onSubmit(data: FactoryResourceProductFormType) {
    if (isPending) return;

    try {
      await mutateAsync(data);
      toast.success("Recurso de produto criado com sucesso!");
      return handleClose();
    } catch (error) {
      validationError(error);
    }
  }

  return (
    <FactoryResourceProductForm
      form={form}
      onSubmit={onSubmit}
      button={{ disabled: isPending, title: "Cadastrar" }}
    />
  );
}
