import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type SupplierStockType = {
  FK_fornecedor: string;
  SK_estoque_fornecedor: number;
  codigo: string;
  created_at: string;
  criado_por: string;
  criado_por_nome: string;
  dia: string;
  quantidade: string;
  estado_fornecedor: string;
  nome_completo_fornecedor: string;
  descricao: string;
};

export type SupplierStockResponseType = {
  FK_fornecedor: string;
  nome_completo_fornecedor: string;
  subRows: Array<SupplierStockType>;
};

export const supplierStockKey = {
  all: ["supplierStock"] as const,
};

async function fetchSupplierStocks(role?: string) {
  if (!role) return;
  const response = await instance.post<ApiResponse<SupplierStockType>>(
    "/planningbeer/supplierStock",
    {
      role,
    }
  );

  return response.data;
}

export function useSupplierStocks(role?: string) {
  return useQuery({
    queryFn: () => fetchSupplierStocks(role),
    queryKey: supplierStockKey.all,
    staleTime: 0,
    gcTime: 0,
  });
}
