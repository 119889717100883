import { UserType } from "@/types";
import { getPermissionCookie } from "@/utils/cookies";
import { SimulatorTableType } from "..";

export type StatusType = { status: SimulatorTableType["status"] };
type UserRoleType = UserType["role"];

export type OperationsType =
  | "see"
  | "edit"
  | "approve"
  | "publish"
  | "delete"
  | "accept"
  | "status"
  | "reject"
  | "download";

type UserOperationInStatusType = {
  role: UserType["role"];
  status: {
    type: SimulatorTableType["status"];
    operations: OperationsType[];
  }[];
};

const logisticUser: UserOperationInStatusType = {
  role: "logistics",
  status: [
    {
      type: "rascunho",
      operations: ["edit", "publish", "delete"],
    },
    {
      type: "aprovado",
      operations: ["see"],
    },
    {
      type: "publicado",
      operations: ["see", "approve", "reject"],
    },
  ],
};

const operationalCommercial: UserOperationInStatusType = {
  role: "operationalCommercial",
  status: [
    {
      type: "rascunho",
      operations: [],
    },
    {
      type: "aprovado",
      operations: [],
    },
    {
      type: "publicado",
      operations: [],
    },
  ],
};

const managerCommercial: UserOperationInStatusType = {
  role: "managerCommercial",
  status: [
    {
      type: "rascunho",
      operations: [],
    },
    {
      type: "aprovado",
      operations: [],
    },
    {
      type: "publicado",
      operations: [],
    },
  ],
};

const guest: UserOperationInStatusType = {
  role: "guest",
  status: [
    {
      type: "rascunho",
      operations: [],
    },
    {
      type: "aprovado",
      operations: [],
    },
    {
      type: "publicado",
      operations: [],
    },
  ],
};

const managerLogistics: UserOperationInStatusType = {
  role: "managerLogistics",
  status: [
    {
      type: "rascunho",
      operations: ["see"],
    },
    {
      type: "aprovado",
      operations: ["see"],
    },
    {
      type: "publicado",
      operations: ["see", "reject", "approve"],
    },
  ],
};

function getUserType(role: UserRoleType) {
  const userRoles: { [key in UserType["role"]]: UserOperationInStatusType } = {
    guest: guest,
    logistics: logisticUser,
    managerCommercial: managerCommercial,
    operationalCommercial: operationalCommercial,
    managerLogistics: managerLogistics,
  };

  return userRoles[role];
}

function getUserOperations(
  user: UserOperationInStatusType,
  { status: currentStatus }: StatusType
) {
  const opertations = user.status.filter(({ operations, type }) => {
    if (type === currentStatus) return operations;
  });

  return opertations.flatMap((op) => op.operations);
}

export function userStatusAction({ status }: StatusType) {
  const role = getPermissionCookie();
  const userOperations = getUserType(role);

  return getUserOperations(userOperations, { status });
}
