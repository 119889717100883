import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configurationKey, ConfigurationProps } from "./useConfiguration";

export default function useConfigurationMutationDeleteMutation({
  tabela_codigo,
}: ConfigurationProps) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return instance.delete("/planningbeer/configuration", {
        data: { id },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: configurationKey.table(Number(tabela_codigo)),
      });
    },
  });
}
