import Col from "@/components/Col";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import {
  handleSheetModel,
  SheetColumnsType,
  validateSheet,
  validateSheetRows,
} from "../../utils/sheet";
import { z } from "zod";
import { REQUIRED_FIELD } from "@/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { useState } from "react";
import { useSupplierStockMutation } from "../../hooks/useSupplierStockMutation";
import { DatePicker } from "@/components/DatePicker";
import dayjs from "dayjs";
import { validationError } from "@/utils/api";

const ACCEPTED_FILE_EXTENSIONS = "xlsx";

const schema = z.object({
  carga: z.object({}, { required_error: REQUIRED_FIELD }),
  vigencia: z.date({ required_error: REQUIRED_FIELD }),
});
export type SendStockFormType = z.infer<typeof schema>;

export default function SendStockDialog() {
  const [visible, setVisible] = useState(false);
  const { mutateAsync, isPending } = useSupplierStockMutation();
  const [sheetData, setSheetData] = useState<SheetColumnsType[]>([]);
  const form = useForm<SendStockFormType>({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  async function handleExcelSheet(file: File) {
    const { sheetJson } = await validateSheet(file);
    const validated = validateSheetRows(sheetJson);
    return validated;
  }

  async function handleSubmit() {
    if (isPending) return;
    const date = form.getValues("vigencia");

    try {
      await mutateAsync({
        date: dayjs(date).format("YYYY-MM-DD"),
        sheet: sheetData,
      });
      setSheetData([]);
      form.reset({
        carga: undefined,
        vigencia: undefined,
      });
      setVisible((prev) => !prev);
      return toast.success("Estoque carregado com sucesso");
    } catch (err) {
      return validationError(err);
    }
  }

  return (
    <Dialog
      onOpenChange={() => {
        setVisible((prev) => !prev);
      }}
      open={visible}
    >
      <DialogTrigger>
        <Button>Carregar Estoque</Button>
      </DialogTrigger>
      <DialogContent>
        <Col>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="flex flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="vigencia"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Vigência *</FormLabel>
                      <FormControl>
                        <DatePicker
                          onChange={field.onChange}
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="carga"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Carga *</FormLabel>
                      <FormControl>
                        <Input
                          type="file"
                          accept=".xlsx"
                          onChange={async (event) => {
                            const file = event.target.files?.[0];
                            if (!file) return;
                            const fileExtension = file?.name?.split(".").pop();
                            if (fileExtension !== ACCEPTED_FILE_EXTENSIONS) {
                              toast.error(
                                "O arquivo deve ser um arquivo Excel (.xlsx)"
                              );
                              event.target.value = "";
                              field.onChange(null);
                              return;
                            }
                            try {
                              const sheetValues = await handleExcelSheet(file);
                              setSheetData(sheetValues);
                              return field.onChange(file);
                            } catch (error) {
                              toast.error(error as unknown as string);
                              event.target.value = "";
                              return;
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <Row justify="end" className="gap-2 mt-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleSheetModel}
                >
                  Baixar Modelo
                </Button>
                <Button type="submit" disabled={isPending}>
                  Enviar
                </Button>
              </Row>
            </form>
          </Form>
        </Col>
      </DialogContent>
    </Dialog>
  );
}
