import { useQueryClient } from "@tanstack/react-query";
import { MultiSelect } from "../ui/multiple-selector";
import { useEffect, useState } from "react";
import { packagingsKey, PackagingType } from "@/hooks/usePackagings";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { SelectProps } from "@radix-ui/react-select";
import Each from "../Each";

type ProductPackagingData = {
  data: Array<PackagingType>;
};

type ProductPackagingSelectProps = {
  selected?: Array<string>;
  handleSelected?: (value: string) => void;
  isMultiple?: boolean;
} & SelectProps;

export default function ProductPackagingSelect({
  handleSelected = () => {},
  selected = [],
  isMultiple,
  ...props
}: ProductPackagingSelectProps) {
  const [visible, setVisible] = useState(false);
  const [optionsToSelect, setOptionsToSelect] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);
  const queryClient = useQueryClient();
  const productPackagingData: ProductPackagingData | undefined =
    queryClient.getQueryData(packagingsKey.all);

  const { data: productPackaging = [] } = productPackagingData || {};

  const options = productPackaging.map(({ descricao, composicao }) => ({
    label: composicao + " - " + descricao,
    value: composicao,
  }));

  function customFilter(search: string) {
    if (!search) return options;
    const filteredOptions = options?.filter((item) =>
      item.label.includes(search.toUpperCase())
    );
    return filteredOptions;
  }

  function handleCustomFilter(_: string, search: string) {
    const filtered = customFilter(search);
    setOptionsToSelect(filtered?.length > 0 ? filtered : optionsToSelect);
    return 1;
  }

  useEffect(() => {
    setOptionsToSelect(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPackagingData]);

  if (!productPackaging) return null;

  if (isMultiple) {
    return (
      <MultiSelect
        options={optionsToSelect}
        selectedValues={selected}
        isOpen={visible}
        onOpen={() => {
          setVisible(true);
        }}
        onClose={() => {
          setVisible(false);
        }}
        onChange={handleSelected}
        customFilter={handleCustomFilter}
      />
    );
  }

  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={options}
          renderItem={(props) => {
            return <SelectItem value={props.value}>{props.label}</SelectItem>;
          }}
        />
      </SelectContent>
    </Select>
  );
}
