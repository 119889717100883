import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { productionSimulationsKey } from "./useProductionSimulators";
import { CellLineValuesType } from "./useProductionSimulationMutation";

export type ProductionSimulatorType = {
  FK_fabrica: number;
  periodo_inicio: string;
  periodo_fim: string;
  descricao: string;
  planejamento_demanda: Array<number>;
  produtos_selecionados?: Array<string>;
  recursos_selecionados?: Array<string>;
  valores_linhas?: Array<CellLineValuesType>;
  revisao?: number | string;
  FK_plano_producao?: number;
  observacao?: string;
};

export default function useProductionSimulatorMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ProductionSimulatorType) => {
      return instance.put("/planningbeer/simulator", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: productionSimulationsKey.all,
      });
    },
  });
}
