import instance from "@/api/business";
import { ApiObjectResponse } from "./useEstimates";
import { useQuery } from "@tanstack/react-query";

export type ProductStockPolicyType = {
  FK_produto: string;
  periodo_inicial: string;
  periodo_final: string;
  maior_venda: {
    valor: number;
  };
  menor_venda: {
    valor: number;
  };
  media_venda: {
    valor: number;
  };
  politica_estoque: {
    estoque_minimo?: string;
    estoque_maximo?: string;
    estoque_reabastecimento?: string;
  };
  inventario: {
    SK_inventario: string;
    FK_produto: string;
    abreviatura_empresa: string;
    filial_origem: string | number;
    quantidade: string | number;
    vigencia: string;
    codigo: string;
  }[];
  inventario_atual: {
    SK_produto_estoque_diario?: string;
    FK_produto?: string;
    codigo_filial?: string;
    dia?: string;
    hora?: string;
    localizacao_codigo?: string;
    localizacao_nome?: string;
    quantidade?: string;
  }[];
};

export const productStockPolicyKeys = {
  all: ["productStockPolicy"] as const,
  detail: (id: string, factoryKey?: number) =>
    [...productStockPolicyKeys.all, "detail", id, factoryKey] as const,
};

type FetchProductStockPolicyProps = {
  id: string;
  factoryKey?: number;
};

async function fetchProductStockPolicy({
  id,
  factoryKey,
}: FetchProductStockPolicyProps) {
  if (!id || !factoryKey) return;
  const response = await instance.post<
    ApiObjectResponse<ProductStockPolicyType>
  >("/planningbeer/stockProduct", {
    id: id,
    factory: factoryKey,
  });
  return response.data;
}

export default function useProductStockPolicy({
  id,
  factoryKey,
}: FetchProductStockPolicyProps) {
  return useQuery({
    queryKey: productStockPolicyKeys.detail(id, factoryKey),
    queryFn: () => fetchProductStockPolicy({ id, factoryKey }),
    staleTime: 0,
    gcTime: 0,
  });
}
