import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { packagingPlansKey } from "./usePackagingPlans";
import { packagingPlanSimulatorsKey } from "./usePackagingPlanSimulators";
import { packagingSimulationKey } from "@/hooks/usePackagingSimulation";

type PackagingPlanPatchMutationType = {
  SK_plano_embalagem: number;
  versao: string;
  valores_fornecedores: string;
};

export function usePackagingPlanPatchMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: PackagingPlanPatchMutationType) => {
      await instance.patch("/planningbeer/packagingPlan", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          packagingPlansKey.all,
          packagingPlanSimulatorsKey.show,
          packagingSimulationKey.show,
        ],
      });
    },
  });
}
