import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { ReactChildrenType } from "@/types";
import { IoIosArrowDown } from "react-icons/io";

export default function MenuDropdown({ children }: ReactChildrenType) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant="outline">
          <Row align="center">
            <IoIosArrowDown size={19} color={TEXT_COLOR} />
            <Text className="mt-1 ml-1">Ações</Text>
          </Row>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex flex-col items-start gap-1">
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
