// const REPLACEMENT_TIME = 20;
// const LEAD_TIME = 7;

type InventoryPolicyType = {
  averageDemand: number;
  replacementTime: number;
  leadTime: number;
};

// ESTOQUE DE SEGURANÇA = MEDIA DE VENDA * TEMPO DE ENTREGA
function calculateSafetyStock({
  averageDemand,
  leadTime,
}: Pick<InventoryPolicyType, "averageDemand" | "leadTime">) {
  return averageDemand * leadTime;
}

// ESTOQUE MINIMO = ESTOQUE DE SEGURANÇA
function calculateMinimumStock({
  averageDemand,
  leadTime,
}: Pick<InventoryPolicyType, "averageDemand" | "leadTime">) {
  return calculateSafetyStock({ averageDemand, leadTime });
}

// PONTO DE RESSUPRIMENTO = MEDIA DE VENDA * TEMPO DE REPOSIÇÃO + ESTOQUE DE SEGURANÇA
function calculateRessuplyPoint({
  averageDemand,
  leadTime,
  replacementTime,
}: InventoryPolicyType) {
  return (
    averageDemand * replacementTime +
    calculateSafetyStock({ averageDemand, leadTime })
  );
}

// ESTOQUE MAXIMO = PONTO DE RESSUPRIMENTO + MEDIA DE VENDA * TEMPO DE REPOSIÇÃO
function calculateMaximumStock({
  averageDemand,
  leadTime,
  replacementTime,
}: InventoryPolicyType) {
  return (
    calculateRessuplyPoint({ averageDemand, leadTime, replacementTime }) +
    averageDemand * replacementTime
  );
}

export function calculateInventoryPolicy({
  averageDemand,
  leadTime = 0,
  replacementTime = 0,
}: InventoryPolicyType) {
  const minimumStock = calculateMinimumStock({ averageDemand, leadTime });
  const ressuplyStock = calculateRessuplyPoint({
    averageDemand,
    leadTime,
    replacementTime,
  });
  const maximumStock = calculateMaximumStock({
    averageDemand,
    leadTime,
    replacementTime,
  });

  return {
    minimumStock,
    ressuplyStock,
    maximumStock,
  };
}
