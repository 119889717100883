import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  factoryResourceKey,
  FactoryResourceType,
} from "./useFactoriesResources";

type FactoryResourceMutation = Omit<FactoryResourceType, "SK_fabrica_recurso">;

export default function useFactoryResourceMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: FactoryResourceMutation) => {
      return instance.put("/planningbeer/factoryResource", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: factoryResourceKey.all });
      queryClient.invalidateQueries({ queryKey: factoryResourceKey.all });
    },
  });
}
