import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import {
  handleSheetModel,
  SheetColumnsType,
  validateSheet,
  validateSheetRows,
} from "../../utils/sheet";
import { toast } from "sonner";
import { useState } from "react";
import useInventoryMutation from "@/hooks/useInventoryMutation";
import { validationError } from "@/utils/api";
import { DialogProps } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { FactoryResourceProductFormType, schema } from "./zod";
import { Textarea } from "@/components/ui/textarea";
import { DatePicker } from "@/components/DatePicker";
import useFactories from "@/hooks/useFactories";
import FactorySelect from "@/components/Select/FactorySelect";
import dayjs from "dayjs";

const ACCEPTED_FILE_EXTENSIONS = "xlsx";

export default function InventoryForm({ handleClose }: DialogProps) {
  useFactories();

  const { mutateAsync, isPending } = useInventoryMutation();
  const [sheetData, setSheetData] = useState<SheetColumnsType[]>([]);
  const form = useForm<FactoryResourceProductFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });

  async function handleExcelSheet(file: File) {
    const { sheetJson } = await validateSheet(file);

    const validated = validateSheetRows(sheetJson);
    return validated;
  }

  async function onSubmit(data: FactoryResourceProductFormType) {
    if (isPending) return;
    try {
      await mutateAsync({
        descricao: data.descricao,
        vigencia: dayjs(data.vigencia).format("YYYY-MM-DD"),
        FK_fabrica: data.FK_fabrica,
        sheetRows: sheetData,
      });
      toast.success("Inventário salvo com sucesso");
      setSheetData([]);
      form.reset();
      return handleClose();
    } catch (error) {
      validationError(error);
      return;
    }
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          name="descricao"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Descrição</FormLabel>
              <FormControl>
                <Textarea
                  maxLength={250}
                  rows={6}
                  style={{ maxHeight: 300 }}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={"vigencia"}
          render={({ field }) => (
            <FormItem className="grow">
              <FormLabel>Vigência *</FormLabel>
              <FormControl>
                <DatePicker onChange={field.onChange} value={field.value} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={"FK_fabrica"}
          render={({ field }) => (
            <FormItem className="grow">
              <FormLabel>Fábrica *</FormLabel>
              <FormControl>
                <FactorySelect onValueChange={field.onChange} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="file"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Carga *</FormLabel>
              <FormControl>
                <Input
                  type="file"
                  accept=".xlsx"
                  onChange={async (event) => {
                    const file = event.target.files?.[0];
                    if (!file) return;
                    const fileExtension = file?.name?.split(".").pop();
                    if (fileExtension !== ACCEPTED_FILE_EXTENSIONS) {
                      toast.error(
                        "O arquivo deve ser um arquivo Excel (.xlsx)"
                      );
                      event.target.value = "";
                      field.onChange(null);
                      return;
                    }
                    try {
                      const sheetValues = await handleExcelSheet(file);
                      setSheetData(sheetValues);
                      return field.onChange(file);
                    } catch (error) {
                      toast.error(error as unknown as string);
                      event.target.value = "";
                      return;
                    }
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row align="center" justify="end" className="gap-2">
          <Button type="button" variant="outline" onClick={handleSheetModel}>
            <Text color="primary">Baixar modelo</Text>
          </Button>
          <Button type="submit" disabled={isPending}>
            <Text color="white">Salvar</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
