import { ReactChildrenType } from "@/types";
import Header from "../Header/Index";
import DefaultLayout from "./DefaultLayout";
import { cn } from "@/lib/utils";
import usePermission from "@/hooks/usePermission";
import Cookies from "js-cookie";
import { MAX_AGE } from "@/constants";

type AuthenticatedLayoutProps = {
  className?: string;
  title?: string;
} & ReactChildrenType;

export default function AuthenticatedLayout({
  children,
  title,
  className,
}: AuthenticatedLayoutProps) {
  const { data } = usePermission();
  const { data: permission } = data || {};
  const cookie = Cookies.get("permission");

  if (permission) {
    if (!cookie)
      Cookies.set("permission", permission?.role, { expires: MAX_AGE });
  }

  if (!permission && !cookie) return null;
  return (
    <DefaultLayout>
      <Header title={title} />
      <div className={cn("p-4", className)}>{children}</div>
    </DefaultLayout>
  );
}
