import Col from "@/components/Col";
import Text from "@/components/Text/Text";
import { TEXT_COLOR } from "@/constants";
import { FaBoxOpen } from "react-icons/fa6";

export function SimulatorsEmpty() {
  return (
    <Col className="justify-center items-center h-full">
      <FaBoxOpen size={90} color={TEXT_COLOR} />
      <Text className="text-base">Não há simuladores disponíveis</Text>
    </Col>
  );
}
