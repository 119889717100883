import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { useAlertDialogContext } from "@/pages/Estimativas/context/AlertDialogContext";
import { ItemProps } from "./EditItem";
import { IoMdThumbsDown } from "react-icons/io";
import Text from "@/components/Text/Text";
import { TEXT_COLOR } from "@/constants";

export default function RejectItem(props: ItemProps) {
  const { handleDialogVisibility, handleSheet } = useAlertDialogContext();

  function handleAcceptItem() {
    handleDialogVisibility();
    handleSheet({ ...props, tipo: "reject" });
  }

  return (
    <DropdownMenuItem onClick={handleAcceptItem}>
      <IoMdThumbsDown className="mr-2" color={TEXT_COLOR} />
      <Text>Rejeitar</Text>
    </DropdownMenuItem>
  );
}
