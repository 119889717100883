import { Dialog, DialogContent } from "@/components/ui/dialog";
import { usePackagingSupplierContext } from "../../context";
import EditPackagingSupplierForm from "../Form/Edit";

export default function EditPackagingSupplierDialog() {
  const { visibleDialog, clear, dialogType } = usePackagingSupplierContext();
  if (dialogType !== "edit") return null;
  return (
    <Dialog open={visibleDialog} onOpenChange={clear}>
      <DialogContent>
        <EditPackagingSupplierForm />
      </DialogContent>
    </Dialog>
  );
}
