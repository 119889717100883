import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  indisponibilityResourceKey,
  IndisponibilityResourceType,
} from "./useIndisponibilityResources";
import instance from "@/api/business";

export default function useIndisponibilityResourcePatchMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IndisponibilityResourceType) => {
      return instance.patch("/planningbeer/indisponibilityResource", {
        ...data,
      });
    },

    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: indisponibilityResourceKey.all,
      });
    },
  });
}
