import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type PackagingPlanSimulatorsType = {
  SK_simulacao_embalagem: number;
  FK_simulacao: number;
  criado_por_codigo: string;
  criado_por_nome: string;
  created_at: string;
  D_E_L_E_T: null;
  FK_plano_embalagem: number;
  observacao: string;
  revisao: string;
  foi_incluido: boolean;
  foi_incluido_simulador_producao: boolean;
  valores_fornecedores:
    | {
        dia: string;
        valor: string;
        FK_fabrica: number;
        composicao: string;
        FK_simulacao_embalagem: number;
        FK_embalagem_fornecedor: number;
        SK_simulacao_embalagem_fornecedor: number;
      }[]
    | [null];
};

export const packagingPlanSimulatorsKey = {
  show: ["packagingPlanSimulators"],
};

async function fetchPackagingPlanSimulators(id: number) {
  if (!id) return;
  const response = await instance.post<
    ApiResponse<PackagingPlanSimulatorsType>
  >("/planningbeer/packagingPlan/simulators", {
    fkPackagingPlan: id,
  });
  return response.data;
}

export function usePackagingPlanSimulators(id: number) {
  return useQuery({
    queryKey: packagingPlanSimulatorsKey.show,
    queryFn: () => fetchPackagingPlanSimulators(id),
    gcTime: 0,
    staleTime: 0,
  });
}
