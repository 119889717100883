import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resourceFactoryProductsKeys } from "./useResourceFactoryProducts";

export default function useResourceFactoryProductDeleteMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return instance.delete("/planningbeer/resourceFactoryProduct", {
        data: { id },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: resourceFactoryProductsKeys.all,
      });
    },
  });
}
