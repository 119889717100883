import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import Each from "../Each";
import { useQueryClient } from "@tanstack/react-query";
import {
  ContractItemType,
  ContractType,
} from "@/pages/PlanoEmbalagem/hooks/useContracts";
import Row from "../Row";
import { Button } from "../ui/button";
import { PiBroom } from "react-icons/pi";
import { TEXT_COLOR } from "@/constants";
import { SelectedSupplierValuesType } from "@/pages/PlanoEmbalagem/context/DeliveryAuthorizationContext";
import { useState } from "react";

type ContractData = {
  data: ContractType;
};

type ContractSelectProps = {
  composition: SelectedSupplierValuesType;
  onRemove: (composition: SelectedSupplierValuesType) => void;
  onChange: (
    value: ContractItemType,
    composition: SelectedSupplierValuesType
  ) => void;
} & SelectProps;

export default function ContractSelect(props: ContractSelectProps) {
  const [value, setValue] = useState<any>();
  const queryClient = useQueryClient();

  const contractData: ContractData | undefined = queryClient.getQueryData([
    "contracts",
  ]);
  const { data: contracts = {} } = contractData || {};
  if (!contracts) return null;
  const compositionContracts = contracts[props.composition.composicao];

  const filteredBySupplier = compositionContracts?.filter(
    (compositionContract) =>
      compositionContract.fornecedor == props.composition.codigo_fornecedor
  );

  return (
    <Row className="w-full">
      <Button
        size="icon"
        variant="outline"
        className="mr-2"
        onClick={() => {
          props.onRemove(props.composition);
          return setValue("");
        }}
      >
        <PiBroom size={22} color={TEXT_COLOR} />
      </Button>
      <Select
        onValueChange={(e) => {
          const contract: ContractItemType = JSON.parse(e || "{}");
          props.onChange(contract, props.composition);
          setValue(e);
        }}
        value={value}
        defaultValue={value}
      >
        <SelectTrigger>
          <SelectValue placeholder="" />
        </SelectTrigger>
        <SelectContent>
          <Each
            array={filteredBySupplier || []}
            renderItem={(props) => {
              return (
                <SelectItem value={JSON.stringify(props)}>
                  <strong>Número Contrato: </strong>
                  {props?.numero_contrato}, <strong>Item: </strong>
                  {props?.item}
                </SelectItem>
              );
            }}
          />
        </SelectContent>
      </Select>
    </Row>
  );
}
