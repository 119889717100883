import Text from "@/components/Text/Text";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { EMPTY_VALUE, TEXT_COLOR } from "@/constants";
import dayjs from "dayjs";
import { EyeIcon } from "lucide-react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Each from "@/components/Each";
import { Separator } from "@/components/ui/separator";
dayjs.extend(customParseFormat);
type IndisponibilityLineToolTipProps = {
  indisponibilityLine: {
    dates: string[];
    motivo: string;
    FK_fabrica_recurso: string | number;
  }[];
};

export default function IndisponibilityLineToolTip({
  indisponibilityLine,
}: IndisponibilityLineToolTipProps) {
  if (indisponibilityLine.length == EMPTY_VALUE) return null;
  return (
    <Popover>
      <PopoverTrigger>
        <EyeIcon size={20} color={TEXT_COLOR} />
      </PopoverTrigger>
      <PopoverContent side="right">
        <Each
          array={indisponibilityLine}
          renderItem={({ motivo, dates }) => {
            const formatedDates = dates.map((date) =>
              dayjs(date).format("DD/MM/YYYY HH:mm:ss")
            );
            return (
              <div>
                <Text>
                  <strong>Motivo:</strong> {motivo}
                </Text>
                <Text>
                  <strong>Datas:</strong>
                  <Text>
                    {formatedDates.map((date) => {
                      return <div key={date}>{date}</div>;
                    })}
                  </Text>
                </Text>
                <Separator className="mb-2" />
              </div>
            );
          }}
        />
      </PopoverContent>
    </Popover>
  );
}
