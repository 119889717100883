/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import IndisponibilityResourceForm from "..";
import { IndisponibilityResourceFormType, schema } from "../zod";
import { IndisponibilityResourceType } from "@/hooks/useIndisponibilityResources";
import { useEffect } from "react";
import { formatUTCDate } from "@/utils/date";
import { zodResolver } from "@hookform/resolvers/zod";
import useIndisponibilityResourcePatchMutation from "@/hooks/useIndisponibilityResourePatchMutation";
import dayjs from "dayjs";
import { toast } from "sonner";
import { validationError } from "@/utils/api";

type EditIndisponibilityResourceFormProps = {
  handleDialog: () => void;
  data: IndisponibilityResourceType;
};

export default function EditIndisponibilityResourceForm({
  handleDialog,
  data,
}: EditIndisponibilityResourceFormProps) {
  const { mutateAsync, isPending } = useIndisponibilityResourcePatchMutation();
  const form = useForm<IndisponibilityResourceFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });

  async function onSubmit(formData: IndisponibilityResourceFormType) {
    const { dia_todo } = formData;

    const initialValidity = dayjs(formData.vigencia_inicio)
      .hour(formData.hora_inicio || 0)
      .minute(formData.minuto_inicio || 0)
      .second(0);
    const finalValidity = dayjs(formData.vigencia_fim)
      .hour(formData.hora_fim || 0)
      .minute(formData.minuto_fim || 0)
      .second(0);

    const correctedData: IndisponibilityResourceType = {
      SK_indisponibilidade_programada: data.SK_indisponibilidade_programada,
      FK_fabrica_recurso: formData.FK_fabrica_recurso,
      motivo: formData.motivo,
      dia_todo: formData.dia_todo ? 1 : 0,
      vigencia_inicio: initialValidity.format("YYYY-MM-DD HH:mm:ss"),
      vigencia_fim: dia_todo
        ? finalValidity.hour(23).minute(59).format("YYYY-MM-DD HH:mm:ss")
        : finalValidity.format("YYYY-MM-DD HH:mm:ss"),
    };

    try {
      if (isPending) return;
      await mutateAsync(correctedData);
      toast.success("Indisponibilidade editada com sucesso!");
      return handleDialog();
    } catch (error) {
      return validationError(error);
    }
  }

  useEffect(() => {
    form.reset({
      FK_fabrica_recurso: String(data.FK_fabrica_recurso),
      motivo: data.motivo,
      dia_todo: data.dia_todo ? true : false,
      vigencia_inicio: formatUTCDate(data.vigencia_inicio),
      vigencia_fim: formatUTCDate(data.vigencia_fim),
      hora_fim: formatUTCDate(data.vigencia_fim)?.getHours(),
      hora_inicio: formatUTCDate(data.vigencia_inicio)?.getHours(),
      minuto_fim: formatUTCDate(data.vigencia_fim)?.getMinutes(),
      minuto_inicio: formatUTCDate(data.vigencia_inicio)?.getMinutes(),
    });
  }, [data]);

  return (
    <IndisponibilityResourceForm
      form={form}
      onSubmit={onSubmit}
      button={{ disabled: isPending, title: "Editar" }}
    />
  );
}
