import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { packagingPlansKey } from "./usePackagingPlans";

export type PackagingPlanMutationType = {
  FK_plano_producao: string;
  periodo_inicio: string;
  periodo_fim: string;
  descricao: string;
};

export function usePackagingPlanMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: PackagingPlanMutationType) => {
      await instance.put("planningbeer/packagingPlan", { ...data });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: packagingPlansKey.all }),
  });
}
