import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type PackagingPlansType = {
  SK_plano_embalagem: number;
  FK_plano_producao: number;
  abreviatura_empresa: string;
  codigo: string;
  descricao: string;
  descricao_plano_producao: string;
  periodo_inicio: string;
  periodo_fim: string;
  criado_por: string;
  versao: string;
  D_E_L_E_T_: null;
  created_at: string;
  valores_fornecedores?: string;
};

export const packagingPlansKey = {
  all: ["packagingPlans"] as const,
};

async function fetchPackagingPlans() {
  const response = await instance.post<ApiResponse<PackagingPlansType>>(
    "/planningbeer/packagingPlan"
  );
  return response.data;
}

export function usePackagingPlans() {
  return useQuery({
    queryKey: packagingPlansKey.all,
    queryFn: fetchPackagingPlans,
    staleTime: 0,
    gcTime: 0,
  });
}
