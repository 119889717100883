import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useState } from "react";
import CreateIndisponibilityResourceForm from "../Form/CreateIndisponibilityResourceForm";

export default function CreateIndisponibilityResourceDialog() {
  const [open, setOpen] = useState(false);

  function handleDialog() {
    setOpen((prev) => !prev);
  }

  return (
    <Dialog onOpenChange={handleDialog} open={open}>
      <DialogTrigger>
        <Button>
          <Text color="white">Criar Indisponibilidade</Text>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <CreateIndisponibilityResourceForm handleDialog={handleDialog} />
      </DialogContent>
    </Dialog>
  );
}
