import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/alert-dialog";

type DeleteDialogAlertProps = {
  visibleDialog: boolean;
  handleVisibleDialog: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  alertDialogTitle: string;
  type: string;
};

export function DeleteDialogAlert({
  onCancel,
  onConfirm,
  handleVisibleDialog,
  alertDialogTitle,
  visibleDialog,
  type,
}: DeleteDialogAlertProps) {
  if (type.toLowerCase() != "delete") return null;
  return (
    <AlertDialog open={visibleDialog} onOpenChange={handleVisibleDialog}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Exclusão</AlertDialogTitle>
          <AlertDialogDescription>{alertDialogTitle}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Cancelar</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm}>Excluir</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
