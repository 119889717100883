import { quantityFormater } from "@/utils/number";
import {
  ACCUMULATE_SALE_ST,
  ACCUMULATED_PRODUCION_ST,
  AVERAGE_SALE_ST,
  DEMAND_PLANNING_ST,
  GRID_SALE,
  GRID_SALE_TOTAL_ST,
  REAL_PRODUCTION_ST,
  REAL_PRODUCTION_TOTAL_ST,
  SD_FINAL_ST,
  SD_INICIAL_ST,
  SEGREGATED_GRID_SALE_ST,
  SEGREGATED_REAL_PRODUCTION_ST,
} from "../../constants";
import { lazy, Suspense, useMemo } from "react";
import Empty from "@/components/Empty";

const DefaultCell = lazy(() => import("./DefaultCell"));
const GridSaleCell = lazy(() => import("./GridSaleCell"));
const InitialBalanceCell = lazy(() => import("./InitialBalanceCell"));
const FinalBalanceCell = lazy(() => import("./FinalBalanceCell"));
const RealProductionCell = lazy(() => import("./RealProductionCell"));
const SegregatedGridSaleCell = lazy(() => import("./SegregatedGridSaleCell"));
const SegregatedRealProductionCell = lazy(
  () => import("./SegregatedRealProductionCell")
);

type CellProps = {
  segregated: boolean;
  fkProduct: string;
  st: string;
  balance: {
    isNew: boolean;
    initial: number;
    final: number;
  };
  gridSale: number;
  acumulatedSale: number;
  demandPlanning: number;
  averageSale: number;
  realProduction: number;
  accumulatedProduction: number;
  segregatedRealProduction: number;
  segregatedGridSale: number;
};

type StatusType = {
  isInitialBalance: boolean;
  isFinalBalance: boolean;
  isGridSale: boolean;
  isAccumulatedSale: boolean;
  isAvergeSaleType: boolean;
  isDemandPlanning: boolean;
  isRealProduction: boolean;
  isAccumulatedProduction: boolean;
  isSegregatedRealProduction: boolean;
  isSegregatedGridSale: boolean;
  isRealProductionTotal: boolean;
  isGridSaleTotal: boolean;
};

export default function Cell({
  segregated,
  st,
  acumulatedSale,
  balance: { final, initial, isNew },
  demandPlanning,
  gridSale,
  averageSale,
  fkProduct,
  realProduction,
  accumulatedProduction,
  segregatedRealProduction,
  segregatedGridSale,
}: CellProps) {
  const status: StatusType = {
    isGridSale: st.toUpperCase() == GRID_SALE.toUpperCase(),
    isInitialBalance: st.toUpperCase() == SD_INICIAL_ST.toUpperCase(),
    isFinalBalance: st.toUpperCase() == SD_FINAL_ST.toUpperCase(),
    isAccumulatedSale: st.toUpperCase() == ACCUMULATE_SALE_ST.toUpperCase(),
    isDemandPlanning: st.toUpperCase() == DEMAND_PLANNING_ST.toUpperCase(),
    isAvergeSaleType: st.toUpperCase() == AVERAGE_SALE_ST.toUpperCase(),
    isRealProduction: st.toUpperCase() == REAL_PRODUCTION_ST.toUpperCase(),
    isAccumulatedProduction:
      st.toUpperCase() == ACCUMULATED_PRODUCION_ST.toUpperCase(),
    isSegregatedRealProduction:
      st.toUpperCase() == SEGREGATED_REAL_PRODUCTION_ST.toUpperCase(),
    isSegregatedGridSale:
      st.toUpperCase() == SEGREGATED_GRID_SALE_ST.toUpperCase(),
    isRealProductionTotal:
      st.toUpperCase() == REAL_PRODUCTION_TOTAL_ST.toUpperCase(),
    isGridSaleTotal: st.toUpperCase() == GRID_SALE_TOTAL_ST.toUpperCase(),
  };

  const conditionalGridSale = useMemo(
    () => (segregated ? gridSale - segregatedGridSale : gridSale),
    []
  );

  const conditionalRealProduction = useMemo(
    () =>
      segregated ? realProduction - segregatedRealProduction : realProduction,
    []
  );

  if (status.isAvergeSaleType)
    return (
      <Suspense
        fallback={<Empty />}
        children={<DefaultCell value={averageSale} />}
      />
    );
  if (status.isAccumulatedSale)
    return (
      <Suspense
        fallback={<Empty />}
        children={<DefaultCell value={acumulatedSale} />}
      />
    );
  if (status.isDemandPlanning)
    return (
      <Suspense
        fallback={<Empty />}
        children={<DefaultCell value={demandPlanning} />}
      />
    );

  if (status.isAccumulatedProduction)
    return (
      <Suspense
        fallback={<Empty />}
        children={<DefaultCell value={accumulatedProduction} />}
      />
    );
  if (status.isGridSale)
    return (
      <Suspense
        fallback={<Empty />}
        children={<GridSaleCell value={conditionalGridSale} />}
      />
    );
  if (status.isGridSaleTotal)
    return (
      <Suspense
        fallback={<Empty />}
        children={<GridSaleCell value={gridSale} />}
      />
    );
  if (status.isSegregatedGridSale) {
    return (
      <Suspense
        fallback={<Empty />}
        children={<SegregatedGridSaleCell value={segregatedGridSale} />}
      />
    );
  }
  if (status.isInitialBalance)
    return (
      <Suspense
        fallback={<Empty />}
        children={
          <InitialBalanceCell
            fkProduct={fkProduct}
            cellValue={quantityFormater(initial)}
            isNewInventory={isNew}
          />
        }
      />
    );
  if (status.isFinalBalance)
    return (
      <Suspense
        fallback={<Empty />}
        children={
          <FinalBalanceCell
            fkProduct={fkProduct}
            cellValue={quantityFormater(final)}
          />
        }
      />
    );
  if (status.isRealProduction)
    return (
      <Suspense
        fallback={<Empty />}
        children={<RealProductionCell value={conditionalRealProduction} />}
      />
    );
  if (status.isSegregatedRealProduction)
    return (
      <Suspense
        fallback={<Empty />}
        children={
          <SegregatedRealProductionCell value={segregatedRealProduction} />
        }
      />
    );
  if (status.isRealProductionTotal)
    return (
      <Suspense
        fallback={<Empty />}
        children={<RealProductionCell value={realProduction} />}
      />
    );

  return <Empty />;
}
