import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const productionPlanKeys = {
  all: ["productionPlans"] as const,
};

type ProductionPlanMutationType = {
  FK_fabrica: string;
  periodo_inicio: string;
  periodo_fim: string;
  descricao: string;
};

export default function useProductionPlanMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ProductionPlanMutationType) => {
      const response = await instance.put("/planningbeer/productionPlan", {
        ...data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productionPlanKeys.all });
    },
  });
}
