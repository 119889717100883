import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { productionSimulationsKey } from "./useProductionSimulators";

export default function useProductionSimulatorDeleteMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return instance.delete("/planningbeer/simulator", {
        data: { id },
      });
    },

    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: productionSimulationsKey.all,
      });
    },
  });
}
