import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import { useQuery } from "@tanstack/react-query";

export type ConfigurationsType = {
  SK_configuracao?: number;
  tabela_codigo: number;
  tabela_nome?: string;
  chave: string;
  valor: string;
  pode_editar?: string;
  pode_excluir?: string;
};

export type ConfigurationProps = Pick<ConfigurationsType, "tabela_codigo">;

export const configurationKey = {
  table: (tableCode: number) => ["configurations", tableCode] as const,
};

async function fetchConfigurations({ tabela_codigo }: ConfigurationProps) {
  const response = await instance.post<ApiResponse<ConfigurationsType>>(
    "/planningbeer/configuration/",
    { tabela_codigo }
  );
  return response.data;
}

export function useConfigurations({ tabela_codigo }: ConfigurationProps) {
  return useQuery({
    queryFn: () => fetchConfigurations({ tabela_codigo }),
    queryKey: configurationKey.table(Number(tabela_codigo)),
  });
}
