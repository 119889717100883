import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { FixedSizeList as List } from "react-window";
import Can from "../Can";

interface MultiSelectProps {
  isOpen: boolean;
  label?: string;
  emptyLabel?: string;
  placeholder?: string;
  options: {
    value: string;
    label: string;
  }[];
  selectedValues: string[];
  virtualized?: boolean;
  onOpen: () => void;
  onClose: () => void;
  onChange: (value: string) => void;
  customFilter?: (value: string, search: string) => number;
  onSelectAll?: (values: string[]) => void;
}
export function MultiSelect({
  label,
  emptyLabel,
  placeholder,
  options,
  isOpen,
  virtualized = false,
  selectedValues,
  customFilter,
  onOpen,
  onClose,
  onChange,
  onSelectAll,
}: MultiSelectProps) {
  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => (open ? onOpen() : onClose())}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={isOpen}
          className="justify-between w-full"
        >
          {selectedValues.length > 0
            ? `${selectedValues.length} items selecionados`
            : label || "Selecionar"}
          <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[600px] p-0 overflow-hidden">
        <Command filter={customFilter}>
          <CommandInput placeholder={placeholder || "Buscar item..."} />
          <CommandList>
            <CommandEmpty>
              {emptyLabel || "Nenhum item selecionado"}
            </CommandEmpty>
            <CommandGroup>
              {onSelectAll && (
                <CommandItem
                  onSelect={() => {
                    if (selectedValues.length === options.length) {
                      onSelectAll([]);
                    } else {
                      onSelectAll(options.map((option) => option.value));
                    }
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selectedValues.length === options.length
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                    Selecionar todos
                  </span>
                </CommandItem>
              )}
              <Can
                condition={virtualized}
                onTrue={
                  <List
                    height={options.length}
                    itemCount={options.length}
                    itemSize={30}
                    width={700}
                  >
                    {({ index, style }) => {
                      const option = options[index];
                      return (
                        <CommandItem
                          style={style}
                          key={option.value}
                          value={option.value}
                          onSelect={(currentValue) => {
                            onChange(currentValue);
                          }}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              selectedValues
                                .map((value) => value.toLowerCase())
                                .includes(option.value.toLowerCase())
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                            {option.label}
                          </span>
                        </CommandItem>
                      );
                    }}
                  </List>
                }
                onFalse={
                  <>
                    {options.map((option) => (
                      <CommandItem
                        key={option.value}
                        value={option.value}
                        onSelect={(currentValue) => {
                          onChange(currentValue);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            selectedValues
                              .map((value) => value.toLowerCase())
                              .includes(option.value.toLowerCase())
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                          {option.label}
                        </span>
                      </CommandItem>
                    ))}
                  </>
                }
              />
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
