import {
  CellLineValuesType,
  ProductionSimulationResponseType,
} from "@/hooks/useProductionSimulationMutation";
import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";
import {
  AccumulatedProductionType,
  FindProductInventoryType,
  ObjectValorValueType,
} from "../types";

type ProductStockPolicyType = {
  [key: string]: {
    estoque_maximo: string;
    estoque_minimo: string;
    estoque_reabastecimento: string;
  };
};

type ProductionPlanType = {
  SK_plano_producao: number;
  FK_fabrica: number;
  revisao: number | string;
};

type AccumulatedProductionProductType = {
  FK_produto: string;
  valor: number;
  dia: string;
  valor_anterior: number;
};

type SimulationProductionProps = {
  calculation: {
    getCount: () => number;
    getAverage: () => number;
    getTotal: () => number;
    handleCellValues: ({
      cellKey,
      value,
    }: {
      cellKey: string;
      value: string;
    }) => void;
    cellValues: { [key: string]: string };
  };
  productionPlan: {
    handleProductionPlan: (data: ProductionPlanType) => void;
    productionPlan: ProductionPlanType;
  };
  production: {
    accumulatedProduction: Array<AccumulatedProductionType>;
    handleAccumulatedProduction: (
      data: AccumulatedProductionProductType
    ) => void;
    handleAccumulatedProductionArray: (
      data: Array<AccumulatedProductionType>
    ) => void;
  };
  lines: {
    handleLineValuesArray: (lineValues: CellLineValuesType[]) => void;
    handleLineValues: (lineValue: CellLineValuesType) => void;
    lineValues: CellLineValuesType[];
  };
  inventory: {
    getInventory: () => Array<FindProductInventoryType>;
    handleInventory: (data: Array<FindProductInventoryType>) => void;
  };
  simulationData: ProductionSimulationResponseType;
  handleSimulationData: (data: ProductionSimulationResponseType) => void;
  clear: () => void;
  icons: {
    disabled: boolean;
    handleDisabled: () => void;
  };
  productStockPolicy: ProductStockPolicyType;
};

const SimulationProductionContext = createContext(
  {} as SimulationProductionProps
);

export function SimulationProductionProvider({ children }: ReactChildrenType) {
  const [accumulatedProduction, setAccumulatedProduction] = useState<
    Array<AccumulatedProductionType>
  >([]);
  const [inventory, setInventory] = useState<Array<FindProductInventoryType>>();
  const [cellValues, setCellValues] = useState({} as { [key: string]: string });
  const [productionPlan, setProductionPlan] = useState(
    {} as ProductionPlanType
  );
  const [productStockPolicy, setProductStockPolicy] =
    useState<ProductStockPolicyType>({});
  const [disabled, setDisabled] = useState(false);
  const [simulationData, setSimulationData] = useState(
    {} as ProductionSimulationResponseType
  );
  const [lineValues, setLineValues] = useState<Array<CellLineValuesType>>([]);

  function handleLineValues({
    dia,
    valor,
    FK_fabrica_recurso,
    FK_produto,
  }: {
    dia: string;
    valor?: string;
    FK_fabrica_recurso: string | number;
    FK_produto: string;
  }) {
    setLineValues((prev) => {
      const newCellLineValues = prev.filter(
        (cellLineValue) =>
          cellLineValue.dia !== dia ||
          cellLineValue.FK_fabrica_recurso !== FK_fabrica_recurso ||
          cellLineValue.FK_produto !== FK_produto
      );

      if (valor) {
        newCellLineValues.push({ dia, valor, FK_fabrica_recurso, FK_produto });
      }

      return newCellLineValues;
    });
  }

  function handleSimulationData(data: ProductionSimulationResponseType) {
    setSimulationData(data);
    const productsPolicy = data.previsao_demanda.reduce((acc, product) => {
      acc[product.FK_produto] = { ...product.politica_estoque };
      return acc;
    }, {});
    setProductStockPolicy(productsPolicy);
  }

  function handleLineValuesArray(data: CellLineValuesType[]) {
    setLineValues(data);
  }

  function handleDisabled() {
    setDisabled((prev) => !prev);
  }

  function handleProductionPlan(data: ProductionPlanType) {
    setProductionPlan(data);
  }

  function clear() {
    setSimulationData({} as any);
    // setProductionPlan({} as any);
    setLineValues([]);
    setDisabled(false);
    setCellValues({} as any);
    setAccumulatedProduction([]);
  }

  function handleCellValues({
    cellKey,
    value,
  }: {
    cellKey: string;
    value: string;
  }) {
    setCellValues((prev) => {
      if (Object.keys(prev).includes(cellKey)) {
        const newCellValues = { ...prev };
        delete newCellValues[cellKey];
        return newCellValues;
      }
      return {
        ...prev,
        [cellKey]: value,
      };
    });
  }

  function getAverage() {
    const total = Object.values(cellValues).reduce((acc, value) => {
      acc += Number(value);
      return acc;
    }, 0);

    const average = total / Object.keys(cellValues).length;
    return Math.ceil(average);
  }

  function getTotal() {
    return Object.values(cellValues).reduce((acc, value) => {
      acc += Number(value);
      return acc;
    }, 0);
  }

  function getCount() {
    return Object.keys(cellValues).length;
  }

  function handleInventory(data: Array<FindProductInventoryType>) {
    setInventory(data);
  }

  function getInventory() {
    return inventory || [];
  }

  function handleAccumulatedProduction(data: AccumulatedProductionProductType) {
    setAccumulatedProduction((prev) => {
      const targetProduct = prev.find((product) => {
        return product.produto == data.FK_produto;
      });
      const { producao_acumulada } = targetProduct || {
        producao_acumulada: [],
      };

      const accumulatedPosition = findIndex(producao_acumulada, data.dia);
      if (accumulatedPosition == -1) return prev;

      const originalValue =
        producao_acumulada[accumulatedPosition][data.dia].valor || 0;

      //prettier-ignore
      const remainingProductions = targetProduct?.producao_acumulada.slice(0, accumulatedPosition) || [];
      //prettier-ignore
      const extractedProductions = targetProduct?.producao_acumulada.slice(accumulatedPosition) || [];
      const newAccumulated = incrementValues(
        extractedProductions,
        data.valor,
        data.valor_anterior,
        originalValue,
        data.dia
      );
      const newProductions = [...remainingProductions, ...newAccumulated];

      return prev.map((product) => {
        if (product.produto == data.FK_produto) {
          return {
            ...product,
            producao_acumulada: newProductions,
          };
        }
        return product;
      });
    });
  }

  function handleAccumulatedProductionArray(
    data: Array<AccumulatedProductionType>
  ) {
    setAccumulatedProduction(data);
  }

  return (
    <SimulationProductionContext.Provider
      value={{
        productStockPolicy,
        calculation: {
          handleCellValues,
          getAverage,
          getTotal,
          getCount,
          cellValues,
        },
        icons: {
          handleDisabled,
          disabled,
        },
        productionPlan: {
          handleProductionPlan,
          productionPlan,
        },
        inventory: {
          getInventory,
          handleInventory,
        },
        production: {
          handleAccumulatedProduction,
          handleAccumulatedProductionArray,
          accumulatedProduction,
        },
        simulationData,
        handleSimulationData,
        clear,
        lines: { handleLineValues, lineValues, handleLineValuesArray },
      }}
    >
      {children}
    </SimulationProductionContext.Provider>
  );
}

export function useSimulationProductionContext() {
  return useContext(SimulationProductionContext);
}

function incrementValues(
  objectValues: ObjectValorValueType[],
  valueToAccumulate: number,
  oldCellValue = 0,
  originalAccumulatedValue: number,
  cellDate: string
) {
  let valueToIncrement = 0;
  if (!objectValues.length) return objectValues;
  return objectValues.map((item) => {
    const date = Object.keys(item)[0];
    const oldAccumulatedValue = item[date].valor;

    if (date == cellDate) {
      const totalAccumulatedValue =
        oldAccumulatedValue - originalAccumulatedValue;
      valueToIncrement =
        totalAccumulatedValue + valueToAccumulate - oldCellValue;
    }

    return {
      [date]: {
        valor: oldAccumulatedValue + valueToIncrement,
      },
    };
  });
}

function findIndex(data: ObjectValorValueType[], date: string) {
  return data.findIndex((item) => Object.keys(item)[0] == date);
}
