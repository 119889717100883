import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useAlertDialogContext } from "../../context/AlertDialogContext";
import useEstimateStatusMutation from "@/hooks/useEstimateStatusMutation";
import { toast } from "sonner";
import i18n from "@/i18n";
import { validationError } from "@/utils/api";

export default function AcceptDialogAlert() {
  const { mutateAsync } = useEstimateStatusMutation();
  const { handleDialogVisibility, clear, visibleDialog, sheet } =
    useAlertDialogContext();

  function handleDialogAlert() {
    handleDialogVisibility();
    clear();
  }

  async function handleUpdateStatus() {
    try {
      await mutateAsync({
        id: sheet.SK_planejamento_demanda,
        status: "ACEITO",
      });
      handleDialogAlert();
      return toast.success(i18n.editStatus);
    } catch (error) {
      return validationError(error);
    }
  }

  if (sheet.tipo !== "accept") return null;
  return (
    <AlertDialog open={visibleDialog} onOpenChange={handleDialogAlert}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Aceite</AlertDialogTitle>
          <AlertDialogDescription>
            Confirma o aceite da planilha "{sheet.nome_planejamento}"?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleDialogAlert}>
            Cancelar
          </AlertDialogCancel>
          <AlertDialogAction onClick={handleUpdateStatus}>
            Aceitar
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
