import ActionCell from "@/components/Cell/ActionCell";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Table from "@/components/NewTable";
import { TableData } from "@/components/NewTable/type";
import { getTableColumnOrder } from "@/utils/table";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import CreateFactoryResourceDialog from "./components/Dialog/CreateFactoryResourceDialog";
import Row from "@/components/Row";
import useFactories from "@/hooks/useFactories";
import useResources from "@/hooks/useResources";
import {
  FactoryResourceType,
  useFactoriesResources,
} from "@/hooks/useFactoriesResources";
import EditFactoryResourceDialog from "./components/Dialog/EditFactoryResourceDialog";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import { useFactoryResourceContext } from "./context";
import useFactoryResourceDeleteMutation from "@/hooks/useFactoryResourceDeleteMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import TableContainer from "@/components/Container/TableContainer";

const columnHelper = createColumnHelper<FactoryResourceType>();
export const TABLE_ORDER_KEY = "factoryResourceColumnOrder";

type FactoryResourceTable = TableData<FactoryResourceType>;

export default function FactoryResource() {
  useFactories();
  useResources();
  const { data } = useFactoriesResources();
  const { mutateAsync } = useFactoryResourceDeleteMutation();
  const { data: factoriesResources = [] } = data || {};
  const { dialogType, factoryResource, visibleDialog, clear } =
    useFactoryResourceContext();

  const columns = [
    columnHelper.accessor("SK_fabrica_recurso", {
      header: "ID",
      id: "SK_fabrica_recurso",
      size: 120,
    }),
    columnHelper.accessor("turno_de_trabalho", {
      header: "Regime de Linha",
      id: "turno_de_trabalho",
      size: 220,
    }),
    columnHelper.accessor("codigo", {
      header: "Código da Fábrica",
      id: "codigo",
      size: 200,
    }),
    columnHelper.accessor("cnpj", {
      header: "CNPJ da Fábrica",
      id: "cnpj",
      size: 200,
    }),
    columnHelper.accessor("abreviatura_empresa", {
      header: "Nome da Fábrica",
      id: "abreviatura_empresa",
      size: 200,
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição do Recurso",
      id: "descricao",
      size: 300,
    }),
    columnHelper.accessor("tipo", {
      header: "Tipo",
      id: "tipo",
      size: 200,
    }),
    columnHelper.accessor("tipo_de_embalagem", {
      header: "Tipo de Embalagem",
      id: "tipo_de_embalagem",
      size: 200,
    }),
    columnHelper.display({
      id: "Ações",
      header: "Ações",
      size: 100,
      cell: ({ row }) => (
        <ActionCell items={<ActionDropdown {...row.original} />} />
      ),
    }),
  ];

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getTableColumnOrder({ column: columns, key: TABLE_ORDER_KEY })
  );

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  async function handleDelete() {
    try {
      await mutateAsync({ id: Number(factoryResource?.SK_fabrica_recurso) });
      clear();
      return toast.success("Fábrica - recurso excluída com sucesso!");
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <AuthenticatedLayout>
      <Row justify="end">
        <CreateFactoryResourceDialog />
      </Row>
      <TableContainer>
        <Table<FactoryResourceTable>
          data={factoriesResources}
          columns={columns}
          onColumnOrderStateChange={handleColumnOrder}
          columnOrderState={columnOrder}
          meta={{ layout: "stretch" }}
          getRowId={(row) => row.SK_fabrica_recurso.toString()}
        />
      </TableContainer>
      <EditFactoryResourceDialog />
      <DeleteDialogAlert
        onConfirm={handleDelete}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        onCancel={clear}
        type={dialogType}
        alertDialogTitle={`Deseja excluir a relação fábrica - recurso, "${factoryResource?.SK_fabrica_recurso}"?. Essa ação é irreversível.`}
      />
    </AuthenticatedLayout>
  );
}
