import { ReactChildrenType } from "@/types";
import Logo from "@/assets/images/planningbeerLogo.png";

type CardProps = {
  width?: number;
} & ReactChildrenType;

export default function Card({ children, width = 300 }: CardProps) {
  return (
    <div style={{ width: width }} className="p-12 shadow-2xl rounded-2xl">
      <div className="w-full flex justify-start">
        <img src={Logo} width={200} className="mb-4" />
      </div>
      {children}
    </div>
  );
}
