import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Text from "@/components/Text/Text";
import ForecastAccordion from "./components/Accordion/ForecastAccordion";

export default function Help() {
  return (
    <AuthenticatedLayout>
      <Text className="text-2xl font-bold mb-4">Central de Ajuda</Text>
      <ForecastAccordion />
    </AuthenticatedLayout>
  );
}
