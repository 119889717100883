import Text from "@/components/Text/Text";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { FaTrash } from "react-icons/fa";
import { ItemProps } from "./EditItem";
import { useAlertDialogContext } from "@/pages/Estimativas/context/AlertDialogContext";

export default function DeleteItem(props: ItemProps) {
  const { handleDialogVisibility, handleSheet } = useAlertDialogContext();

  function handleApproveItem() {
    handleDialogVisibility();
    handleSheet({ ...props, tipo: "delete" });
  }
  return (
    <DropdownMenuItem onClick={handleApproveItem}>
      <FaTrash className="mr-2" color={TEXT_COLOR} />
      <Text>Deletar</Text>
    </DropdownMenuItem>
  );
}
