import { PackagingSupplierCellValueType } from "@/hooks/usePackagingSimulationMutation";
import { CalculatePackagingSupplierDailyTotalProps } from "../types";

export function calculatePackagingSupplierDailyTotal({
  composition,
  day,
  packagingSupplierCellValues,
  currentInventory,
  realCollection,
}: CalculatePackagingSupplierDailyTotalProps) {
  const realCollectionTotal = realCollection
    ?.filter(
      (packagingSupplierCellValue) =>
        packagingSupplierCellValue.produto == composition &&
        packagingSupplierCellValue.dia === day
    )
    .reduce((acc, curr) => acc + Number(curr.total), 0);

  if (realCollectionTotal) {
    return { total: realCollectionTotal + currentInventory };
  }

  const total = packagingSupplierCellValues
    .filter(
      (packagingSupplierCellValue) =>
        packagingSupplierCellValue.composicao == composition &&
        packagingSupplierCellValue.dia === day
    )
    .reduce((acc, curr) => acc + Number(curr.valor), 0);

  return { total: total + currentInventory };
}

export type PackagingPlanSupplierValueResponseType = {
  dia: string;
  valor: string;
  FK_fabrica: number;
  composicao: string;
  FK_simulacao_embalagem: number;
  FK_embalagem_fornecedor: number;
  SK_simulacao_embalagem_fornecedor: number;
};

export function formatPackagingPlanSupplierValue(
  packaginPlanSupplierValue: Array<PackagingPlanSupplierValueResponseType>
): PackagingSupplierCellValueType[] {
  if (packaginPlanSupplierValue.length == 0) return [];

  const formated: PackagingSupplierCellValueType[] =
    packaginPlanSupplierValue.map((value) => {
      return {
        composicao: value.composicao,
        dia: value.dia,
        FK_embalagem_fornecedor: value.FK_embalagem_fornecedor,
        FK_fabrica: value.FK_fabrica,
        FK_simulacao_producao: value.FK_simulacao_embalagem,
        valor: value.valor,
      };
    });

  return formated;
}
