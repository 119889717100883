import NotFound from "@/pages/NotFound";
import { getPermissionCookie } from "@/utils/cookies";
import { Outlet } from "react-router-dom";

export default function AdministratorMiddleware() {
  const permission = getPermissionCookie();

  if (permission != "logistics") return <NotFound />;

  return <Outlet />;
}
