import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { factoryKeys } from "./useFactoryMutation";

export function useFactoryDeleteMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return instance.delete("/planningbeer/factory", {
        data: { id },
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: factoryKeys.all });
    },
  });
}
