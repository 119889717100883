import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { packagingPlansKey } from "./usePackagingPlans";
import { packagingPlanSimulatorsKey } from "./usePackagingPlanSimulators";
import { packagingSimulationKey } from "@/hooks/usePackagingSimulation";

type PackagingPlanAddSimulationType = {
  fkPackagingPlan: number;
  fkPackagingSimulation: number;
  revision: string;
  supplierValues: string;
  oldSupplierValues?: string;
  observation?: string;
};

export function usePackagingPlanAddSimulation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: PackagingPlanAddSimulationType) => {
      await instance.put("/planningbeer/packagingPlan/simulators", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          packagingPlansKey.all,
          packagingPlanSimulatorsKey.show,
          packagingSimulationKey.show,
        ],
      });
    },
  });
}
