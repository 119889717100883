import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";
import { FactoryColumnsType } from "..";

type DialogTypeType = "edit" | "delete" | "nonSelected" | "manageFactory";

type FactoryContextProps = {
  visibleDialog: boolean;
  handleDialog: () => void;
  dialogType: DialogTypeType;
  handleDialogType: (type: DialogTypeType) => void;
  factory: FactoryColumnsType;
  handleFactory: (factory: FactoryColumnsType) => void;
  clear: () => void;
};

const FactoryContext = createContext({} as FactoryContextProps);

export default function FactoryProvider({ children }: ReactChildrenType) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [factory, setFactory] = useState<FactoryColumnsType>(
    {} as FactoryColumnsType
  );
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handleFactory(value: FactoryColumnsType) {
    setFactory(value);
  }

  function clear() {
    setFactory({} as FactoryColumnsType);
    setDialogType("nonSelected");
    setVisibleDialog(false);
  }

  return (
    <FactoryContext.Provider
      value={{
        clear,
        handleDialogType,
        handleDialog,
        handleFactory,
        factory,
        visibleDialog,
        dialogType,
      }}
    >
      {children}
    </FactoryContext.Provider>
  );
}

export function useFactoryContext() {
  return useContext(FactoryContext);
}
