import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { ROUTES } from "@/constants";
import { Link } from "react-router-dom";

export default function InvalidPackagingPlanError() {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex flex-col items-center">
        <Text className="text-lg font-bold">
          Não foi encontrado nenhum Plano de Ressuprimento de Embalagem
        </Text>
        <Link className="mt-4" to={"/" + ROUTES.packagingPlan.index}>
          <Button>Ir Para Planos de Ressuprimento de Embalagem</Button>
        </Link>
      </div>
    </div>
  );
}
