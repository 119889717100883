import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useState } from "react";

type SelectPlanningTypeDialogProps = {
  children: React.ReactNode;
  onConfirm: (option: string) => void;
};

export default function SelectPlanningTypeDialog({
  children,
  onConfirm,
}: SelectPlanningTypeDialogProps) {
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<string>("");

  function handleOpen() {
    setOpen((prev) => !prev);
  }

  return (
    <Dialog open={open} onOpenChange={handleOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <Text className="font-lg font-bold">Selecione o tipo de simulador</Text>
        <RadioGroup onValueChange={setSelectedType}>
          <Row align="center">
            <RadioGroupItem value="PA" id="r3" />
            <Label htmlFor="r3" className="ml-2">
              <Text className="capitalize">Produto Acabado</Text>
            </Label>
          </Row>
        </RadioGroup>
        <Row justify="end">
          <Button
            onClick={() => {
              onConfirm(selectedType);
              handleOpen();
              setSelectedType("");
            }}
            disabled={!selectedType}
          >
            Salvar
          </Button>
        </Row>
      </DialogContent>
    </Dialog>
  );
}
