import { PackagingSupplierCellValueType } from "@/hooks/usePackagingSimulationMutation";
import dayjs from "dayjs";
import { utils, writeFile } from "xlsx";
import { ExtraInfoType } from "../components/Button/DownloadExcel";
import { quantityFormater } from "@/utils/number";
import {
  calculateTotalCargos,
  calculateTotalPallets,
} from "@/pages/SimulacoesEmbalagem/utils/carga";

export function handleSheetModel(
  packagingSuppliers: PackagingSupplierCellValueType[],
  extraInformation: ExtraInfoType
) {
  const {
    factories,
    packagingSuppliers: suppliers,
    packagings,
    cargoModels,
  } = extraInformation;
  const workbook = utils.book_new();
  const worksheet = utils.aoa_to_sheet([[]]);

  utils.book_append_sheet(workbook, worksheet, "Pedidos");

  const modeloData = [
    [
      "Código",
      "Descrição",
      "Quantidade",
      "Dia",
      "Fornecedor",
      "Destino",
      "Lead Time",
      "Data limite para pedido",
      "Modelo de carga",
      "Quantidade de paletes por carga",
      "Quantidade de cargas necessárias",
    ],
    ...packagingSuppliers.map((supplier) => {
      const totalPallets = calculateTotalPallets(
        Number(supplier.valor),
        Number(supplier.quantidade_por_palete || 0)
      );

      const totalCargo = calculateTotalCargos(
        totalPallets,
        Number(supplier.quantidade_palete_carga)
      );

      return [
        supplier.composicao,
        packagings[supplier.composicao]?.descricao,
        quantityFormater(Number(supplier.valor)),
        dayjs(supplier.dia).format("DD/MM/YYYY"),
        suppliers[supplier.FK_embalagem_fornecedor]?.nome_fornecedor,
        factories[supplier.FK_fabrica]?.abreviatura_empresa,
        suppliers[supplier.FK_embalagem_fornecedor]?.lead_time,
        dayjs(supplier.dia)
          .subtract(
            Number(suppliers[supplier.FK_embalagem_fornecedor]?.lead_time),
            "days"
          )
          .format("DD/MM/YYYY"),
        cargoModels?.[supplier.FK_modelo_carga || ""]?.nome || "Padrão",
        supplier.quantidade_palete_carga,
        totalCargo,
      ];
    }),
  ];

  utils.sheet_add_aoa(worksheet, modeloData, { origin: 0 });
  writeFile(workbook, "Pedidos.xlsx");
}
