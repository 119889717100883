const DEFAULT_CARGO_MODEL = 25;

export function calculateTotalPallets(
  supplierValue: number,
  quantityPerPallet: number
) {
  return Math.ceil(supplierValue / quantityPerPallet);
}

export function calculateTotalCargos(
  totalPallets: number,
  cargoModel = DEFAULT_CARGO_MODEL
) {
  return Number((totalPallets / cargoModel).toFixed(2));
}

export function calculateTotalUnits(
  totalCargos: number,
  quantityPerPallet: number,
  cargoModel = DEFAULT_CARGO_MODEL
) {
  const totalPallets = totalCargos * cargoModel;
  return totalPallets * quantityPerPallet;
}
