import Row from "@/components/Row";
import ItemsStep from "@/components/Step/Items";
import React from "react";

type StepsProps = {
  selectedStep: number;
  handleStep: (step: number) => void;
};

export default function Steps({ handleStep, selectedStep }: StepsProps) {
  function isActive(step: number) {
    return selectedStep == step;
  }

  return (
    <React.Fragment>
      <Row className="w-full ml-2" align="center">
        <ItemsStep
          title="Recursos"
          onClick={handleStep}
          step={0}
          isSelected={isActive(0)}
        />
        <ItemsStep
          title="Produtos"
          onClick={handleStep}
          step={1}
          isSelected={isActive(1)}
        />
      </Row>
    </React.Fragment>
  );
}
