import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { ROUTES, TEXT_COLOR } from "@/constants";
import { FaEdit } from "react-icons/fa";
import { usePackagingPlanContext } from "../../context";
import dayjs from "dayjs";
import EditPackagingPlanForm from "../Form/Edit";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema, SeePackagingPlanFormType } from "../Form/See/zod";
import usePackagingSimulationMutation from "@/hooks/usePackagingSimulationMutation";
import { validationError } from "@/utils/api";
import { usePackagingSimulationContext } from "@/pages/SimulacoesEmbalagem/context";
import { useNavigate } from "react-router-dom";

export default function EditPackagingPlanModal() {
  const navigate = useNavigate();
  const form = useForm<SeePackagingPlanFormType>({
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: zodResolver(schema),
  });
  const {
    packagingPlan: { FK_plano_producao, SK_plano_embalagem },
  } = usePackagingPlanContext();
  const {
    handlePackagingSimulationData,
    packagingSupplier: { handlePackagingSupplierCellValue },
  } = usePackagingSimulationContext();
  const { mutateAsync, isPending } = usePackagingSimulationMutation();

  async function handleEditPackagingPlanForm(
    formData: SeePackagingPlanFormType
  ) {
    if (isPending) return;
    try {
      const { data } = await mutateAsync({
        meta: {
          FK_plano_producao,
          periodo_inicio: dayjs(formData.periodo_inicio).format("YYYY-MM-DD"),
          periodo_fim: dayjs(formData.periodo_fim).format("YYYY-MM-DD"),
          FK_plano_embalagem: SK_plano_embalagem,
          utiliza_simulador_produto_acabado: false,
          utiliza_valor_fornecedor_plano_embalagem: true,
        },
      });
      handlePackagingSimulationData(data);
      const {
        meta: { valores_fornecedores },
      } = data;
      handlePackagingSupplierCellValue(valores_fornecedores);

      return navigate(
        "/" + ROUTES.packagingPlan.index + "editar/" + SK_plano_embalagem
      );
    } catch (err) {
      return validationError(err);
    }
  }

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant="outline">
          <Row align="center">
            <FaEdit size={18} color={TEXT_COLOR} />
            <Text className="ml-1 mt-1">Editar Plano</Text>
          </Row>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <EditPackagingPlanForm
          onSubmit={handleEditPackagingPlanForm}
          form={form}
          button={{
            disabled: isPending,
            title: "Editar",
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
