import ActionCell from "@/components/Cell/ActionCell";
import TableContainer from "@/components/Container/TableContainer";
import Empty from "@/components/Empty";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Table from "@/components/NewTable";
import { TableData } from "@/components/NewTable/type";
import {
  PackagingSimulatorsType,
  usePackagingSimulators,
} from "@/hooks/usePackagingSimulators";
import { useProductionSimulators } from "@/hooks/useProductionSimulators";
import { getTableColumnOrder } from "@/utils/table";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useState } from "react";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import { usePackagingSimulatorContext } from "./context";
import { usePackagingDeleteMutation } from "@/hooks/usePackagingDeleteMutation";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import { VersionBadge } from "@/components/Badge/VersionBadge";
import ForecastStatusCell from "../Estimativas/components/Cell/StatusCell";
import Text from "@/components/Text/Text";
import HistoryDialog from "./components/Dialog/HistoryDialog";

export type PackagingSimulatorTableType = TableData<PackagingSimulatorsType>;

const columnHelper = createColumnHelper<PackagingSimulatorTableType>();
const TABLE_ORDER_KEY = "packagingSimulatorsColumnsOrder";

export default function PackagingSimulators() {
  useProductionSimulators();
  const { data } = usePackagingSimulators();
  const { data: packagingSimulators = [] } = data || {};
  const { mutateAsync, isPending } = usePackagingDeleteMutation();
  const { clear, dialogType, simulator, visibleDialog } =
    usePackagingSimulatorContext();

  const columns = [
    columnHelper.accessor("SK_simulacao_embalagem", {
      header: "ID",
      id: "SK_simulacao_embalagem",
      size: 100,
    }),
    columnHelper.accessor("revisao_simulador_embalagem", {
      header: "Versão Simulador de Embalagem",
      id: "revisao_simulador_embalagem",
      size: 150,
      cell: ({
        row: {
          original: { revisao_simulador_embalagem },
        },
      }) => <VersionBadge revisao={revisao_simulador_embalagem} />,
    }),
    columnHelper.accessor("observacao_simulador_embalagem", {
      header: "Descrição Simulador de Embalagem",
      id: "observacao_simulador_embalagem",
      size: 350,
    }),
    columnHelper.accessor("status", {
      header: "Status Simulador de Embalagem",
      id: "status",
      cell: ({ row }) => {
        const { status } = row.original;
        return <ForecastStatusCell status={status?.toLowerCase()} />;
      },
    }),
    columnHelper.accessor("foi_incluido", {
      header: "Incluído ao Plano de Ressuprimento de Embalagens",
      id: "foi_incluido",
      cell: ({ row }) => {
        const { foi_incluido } = row.original;
        return (
          <Text
            className={`p-1 px-1.5 rounded capitalize ${foi_incluido ? "bg-lime-300" : "bg-red-400"}`}
            color="black"
          >
            {foi_incluido ? "SIM" : "NÃO"}
          </Text>
        );
      },
    }),
    columnHelper.accessor("periodo_inicio", {
      header: "Período Início",
      id: "periodo_inicio",
      cell: (row) => {
        const date = row.getValue();
        if (!date) return <Empty />;
        return <span>{dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>;
      },
    }),
    columnHelper.accessor("periodo_fim", {
      header: "Período Fim",
      id: "periodo_fim",
      cell: (row) => {
        const date = row.getValue();
        if (!date) return <Empty />;
        return <span>{dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>;
      },
    }),
    columnHelper.accessor("FK_simulacao", {
      header: "Código da Simulação de Produção",
      id: "FK_simulacao",
      size: 120,
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição da Simulação de Produção",
      id: "descricao",
      size: 350,
    }),
    columnHelper.accessor("abreviatura_empresa", {
      header: "Abreviatura da Fábrica",
      id: "abreviatura_empresa",
      size: 220,
    }),
    columnHelper.accessor("cnpj", {
      header: "CNPJ",
      id: "cnpj",
      size: 200,
    }),
    columnHelper.accessor("codigo", {
      header: "Código da Fábrica",
      id: "codigo",
      size: 190,
    }),
    columnHelper.accessor("criado_por_nome", {
      header: "Criado Por",
      id: "criado_por_nome",
      size: 300,
    }),
    columnHelper.display({
      id: "Ações",
      header: "Ações",
      size: 100,
      cell: ({ row }) => {
        return <ActionCell items={<ActionDropdown {...row.original} />} />;
      },
    }),
  ];

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getTableColumnOrder({ column: columns, key: TABLE_ORDER_KEY })
  );

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  async function handleDelete() {
    if (isPending) return;
    try {
      await mutateAsync({ id: Number(simulator?.SK_simulacao_embalagem) });
      clear();
      return toast.success("Simulador excluído com sucesso.");
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <AuthenticatedLayout>
      <TableContainer>
        <Table<PackagingSimulatorTableType>
          columns={columns}
          data={packagingSimulators}
          columnOrderState={columnOrder}
          onColumnOrderStateChange={handleColumnOrder}
          meta={{ layout: "stretch" }}
        />
      </TableContainer>
      <DeleteDialogAlert
        onCancel={clear}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        onConfirm={handleDelete}
        type={dialogType}
        alertDialogTitle={`Deseja excluir o simulador, "${simulator?.SK_simulacao_embalagem}"?. Essa ação é irreversível.`}
      />
      <HistoryDialog />
    </AuthenticatedLayout>
  );
}
