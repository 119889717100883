import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { schema } from "../../form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { transformApiError } from "@/utils/api";
import { recoverPassword } from "@/api/auth/user";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants";
import { useLoadingContext } from "@/context/LoadingContext";

export type ResetPasswordFormDataType = z.infer<typeof schema>;

export default function ResetPasswordForm() {
  const { handleLoading, isLoading } = useLoadingContext();
  const navigate = useNavigate();
  const form = useForm<ResetPasswordFormDataType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });

  async function handleSubmit(data: ResetPasswordFormDataType) {
    if (isLoading) return;
    handleLoading(true);
    try {
      await recoverPassword(data.email);
      form.reset();
      toast.success("E-mail enviado com sucesso!");
      return navigate("/" + ROUTES.initial);
    } catch (error) {
      transformApiError(error);
    } finally {
      handleLoading(false);
    }
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input label="E-mail *" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button variant="default" type="submit" disabled={isLoading}>
          <Text color="white">Enviar</Text>
        </Button>
      </form>
    </Form>
  );
}
