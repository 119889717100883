import Text from "@/components/Text/Text";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { ProductionSimulationType } from "@/hooks/useProductionSimulators";
import { useProductionSimulatorStatusMutation } from "@/hooks/useProductionSimulatorStatusMutation";
import { validationError } from "@/utils/api";
import { IoMdThumbsDown } from "react-icons/io";

export default function RejectItem({ SK_simulacao }: ProductionSimulationType) {
  const { mutateAsync, isPending } = useProductionSimulatorStatusMutation();

  async function handlePublish() {
    if (isPending) return;

    try {
      await mutateAsync({
        skProductionSimulator: SK_simulacao,
        status: "RASCUNHO",
      });
    } catch (error) {
      validationError(error);
    }
  }
  return (
    <DropdownMenuItem onClick={handlePublish}>
      <IoMdThumbsDown className="mr-2" color={TEXT_COLOR} />
      <Text>Rejeitar</Text>
    </DropdownMenuItem>
  );
}
