import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const partialSchema = z.object({
  senha: z.string({ required_error: REQUIRED_FIELD }).trim(),
  confirmar_senha: z.string({ required_error: REQUIRED_FIELD }).trim(),
});

export const schema = partialSchema.refine(
  (data) => data.senha === data.confirmar_senha,
  {
    message: "As senhas não coincidem",
    path: ["confirmar_senha"],
  }
);
