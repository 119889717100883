import { cn } from "@/lib/utils";
import { ReactChildrenType } from "@/types";

type RowProps = {
  justify?:
    | "end"
    | "start"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  align?: "center" | "start" | "end";
} & ReactChildrenType &
  React.HTMLAttributes<HTMLDivElement>;

export default function Row({
  children,
  justify = "start",
  align,
  ...props
}: RowProps) {
  return (
    <div
      className={cn(props.className, "flex flex-row")}
      style={{ justifyContent: justify, alignItems: align }}
    >
      {children}
    </div>
  );
}
