import instance from "@/api/business";
import { SheetColumnsType } from "@/pages/Inventario/utils/sheet";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { inventoryKeys } from "./useInventory";

type InventoryMutationType = {
  sheetRows: Array<SheetColumnsType>;
  descricao?: string;
  vigencia?: string;
  FK_fabrica?: string;
};

export default function useInventoryMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: InventoryMutationType) => {
      return instance.put("/planningbeer/inventory", { ...data });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: inventoryKeys.all });
    },
  });
}
