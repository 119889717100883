import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { ROUTES, TEXT_COLOR } from "@/constants";
import CreateSimulationForm from "@/pages/Simulacoes/components/Form/CreateSimulationForm";
import { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

export default function EditProductionPlanSimulatorModal() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  function handleDialog() {
    setVisible((prev) => !prev);
    navigate("/" + ROUTES.productionPlan.index + "editar/" + id);
  }

  return (
    <Dialog onOpenChange={() => setVisible(!visible)} open={visible}>
      <DialogTrigger>
        <Button variant="outline" onClick={() => setVisible(!visible)}>
          <Row align="center">
            <FaEdit size={18} color={TEXT_COLOR} />
            <Text className="ml-1 mt-1">Editar Plano</Text>
          </Row>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <CreateSimulationForm
          handleClose={handleDialog}
          visibleObservation={false}
        />
      </DialogContent>
    </Dialog>
  );
}
