import instance from "@/api/business";
import { PackagingSupplierEditFormType } from "@/pages/EmbalagemFornecedores/components/Form/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { packagingSupplierKey } from "./usePackagingSuppliers";

type PackagingSupplierPatchMutation = {
  skPackagingSupplier: number;
} & PackagingSupplierEditFormType;

export function usePackagingSupplierPatchMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: PackagingSupplierPatchMutation) => {
      return instance.patch("/planningbeer/packagingSupplier", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: packagingSupplierKey.all });
    },
  });
}
