import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { PackagingPlanFormType, schema } from "../zod";
import PackagingPlanForm from "..";
import dayjs from "dayjs";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import { usePackagingPlanMutation } from "@/pages/PlanoEmbalagem/hooks/usePackagingPlanMutation";

type CreatePackagingPlanFormProps = {
  handleDialog: () => void;
};

export default function CreatePackagingPlanForm({
  handleDialog,
}: CreatePackagingPlanFormProps) {
  const { mutateAsync, isPending } = usePackagingPlanMutation();
  const form = useForm<PackagingPlanFormType>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  async function handleCreatePackagingPlan(formData: PackagingPlanFormType) {
    const data = {
      ...formData,
      periodo_inicio: dayjs(formData.periodo_inicio).format("YYYY-MM-DD"),
      periodo_fim: dayjs(formData.periodo_fim).format("YYYY-MM-DD"),
    };

    try {
      if (isPending) return;
      await mutateAsync(data);
      toast.success("Plano de Ressuprimento de Embalagem criado com sucesso!");
      return handleDialog();
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <PackagingPlanForm
      form={form}
      onSubmit={handleCreatePackagingPlan}
      button={{ disabled: false, title: "Salvar" }}
    />
  );
}
