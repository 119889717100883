import instance from "@/api/business";
import { useQuery } from "@tanstack/react-query";
import { productionPlanKeys } from "./useProductionPlanMutation";
import { ApiResponse } from "./useEstimates";

export type ProductionPlanType = {
  SK_plano_producao: number;
  FK_fabrica: number;
  descricao: string;
  periodo_inicio: string;
  periodo_fim: string;
  criado_por: string;
  created_at: string;
  abreviatura_empresa: string;
  codigo: string;
  revisao: number | string;
  valores_linhas?: string;
};

async function fetchProductionPlans() {
  const response = await instance.post<ApiResponse<ProductionPlanType>>(
    "/planningbeer/productionPlan"
  );

  return response.data;
}

export function useProductionPlans() {
  return useQuery({
    queryKey: productionPlanKeys.all,
    queryFn: fetchProductionPlans,
    staleTime: 0,
    gcTime: 0,
  });
}
