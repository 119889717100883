import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import { ROUTES } from "@/constants";
import PackagingSimulationTable from "@/pages/SimulacoesEmbalagem/components/Table";
import { useNavigate } from "react-router-dom";
import { usePackagingPlanContext } from "../context";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import { usePackagingSimulationContext } from "@/pages/SimulacoesEmbalagem/context";
import { useEffect } from "react";
import dayjs from "dayjs";
import Col from "@/components/Col";
import Can from "@/components/Can";
import { usePackagingPlanPatchMutation } from "../hooks/usePackagingPlanPatchMutation";
import { validationError } from "@/utils/api";

export default function EditPackagingPlan() {
  const {
    packagingPlan: { SK_plano_embalagem, versao, descricao },
    clear: clearPackagingPlan,
  } = usePackagingPlanContext();
  const {
    clear: clearPackagingSimulation,
    packagingSimulationData: { simulacao_producao },
    packagingSupplier: { packagingSupplierCellValue },
  } = usePackagingSimulationContext();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = usePackagingPlanPatchMutation();

  async function handleEditPackagingPlan() {
    if (isPending) return;

    try {
      await mutateAsync({
        SK_plano_embalagem,
        versao,
        valores_fornecedores: JSON.stringify(packagingSupplierCellValue),
      });
      clearPackagingPlan();
      clearPackagingSimulation();
      return navigate("/" + ROUTES.packagingPlan.index);
    } catch (err) {
      validationError(err);
    }
  }

  useEffect(() => {
    if (!SK_plano_embalagem) {
      clearPackagingPlan();
      clearPackagingSimulation();
      navigate("/" + ROUTES.packagingPlan.index);
    }
  }, []);

  return (
    <AuthenticatedLayout
      title="Editando Plano de ressuprimento de embalagem"
      className="max-h-[calc(100vh-150px)] h-full"
    >
      <PackagingSimulationTable
        editableSupplierCell={true}
        tableInformation={{
          showCustom: true,
          custom: (
            <Can
              condition={!!SK_plano_embalagem}
              onTrue={
                <Col>
                  <Text className="text-base">
                    Plano de Ressuprimento de Embalagem:{" "}
                    <strong>{SK_plano_embalagem}, </strong>
                    <strong>{descricao}</strong>, Versão:{" "}
                    <strong>{versao}</strong>
                  </Text>
                  <Row>
                    <Text className="font-bold text-sm">
                      {dayjs(simulacao_producao?.periodo_inicio || "").format(
                        "DD/MM/YYYY"
                      )}
                      {" - "}
                      {dayjs(simulacao_producao?.periodo_fim || "").format(
                        "DD/MM/YYYY"
                      )}
                    </Text>
                  </Row>
                </Col>
              }
            />
          ),
        }}
      />
      <Row justify="end" className="mt-8 gap-2">
        <Button
          variant="outline"
          onClick={() => {
            clearPackagingPlan();
            clearPackagingSimulation();
            navigate("/" + ROUTES.packagingPlan.index);
          }}
        >
          Cancelar
        </Button>
        <Button onClick={handleEditPackagingPlan}>Editar</Button>
      </Row>
    </AuthenticatedLayout>
  );
}
