import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { BsThreeDots } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import { FaCodeCompare } from "react-icons/fa6";
import { PackagingPlanOldTypeResponse } from "../../hooks/usePackagingPlanOld";
import CompareVersionDialog from "../Dialog/CompareVersionDialog";
import { useState } from "react";
import EditCurrentVersionDialog from "../Dialog/EditCurrentVersionDialog";

export type VersionDropdownProps = {
  date: string;
  revision: number | string;
  edition: boolean;
  lineValues: PackagingPlanOldTypeResponse["valores_linhas"];
};

export default function VersionDropdown(props: VersionDropdownProps) {
  const [visibleCompareDialog, setVisibleCompareDialog] = useState(false);
  const [visibleEditDialog, setVisibleEditDialog] = useState(false);

  return (
    <>
      <EditCurrentVersionDialog
        handleClose={() => setVisibleEditDialog(!visibleEditDialog)}
        visible={visibleEditDialog}
        {...props}
      />
      <CompareVersionDialog
        handleClose={() => setVisibleCompareDialog(!visibleCompareDialog)}
        visible={visibleCompareDialog}
        lineValues={props.lineValues}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="mr-2">
          <Button variant="ghost">
            <BsThreeDots />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            onClick={() => setVisibleEditDialog(!visibleEditDialog)}
          >
            <Row align="center">
              <FaStar className="mr-1 mb-1" color={TEXT_COLOR} />
              <Text>Definir como versão atual</Text>
            </Row>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => setVisibleCompareDialog(!visibleCompareDialog)}
          >
            <Row align="center">
              <FaCodeCompare
                className="mr-1 mb-1"
                size={14}
                color={TEXT_COLOR}
              />
              <Text>Comparar versão</Text>
            </Row>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
