import Can from "@/components/Can";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import DotsLoading from "@/components/Loading/DotsLoading";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import { usePackagingSimulator } from "@/hooks/usePackagingSimulator";
import { VisibleIconButton } from "@/pages/SimulacoesEmbalagem/components/Button/VisibleIconButton";
import PackagingSimulationTable from "@/pages/SimulacoesEmbalagem/components/Table";
import { usePackagingSimulationContext } from "@/pages/SimulacoesEmbalagem/context";
import SimulationError from "@/pages/Simuladores/components/Error/SimulationError";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

export default function SeePackagingSimulator() {
  const { id } = useParams();
  const {
    data,
    error,
    isPending: isPendingPackagingSimulator,
  } = usePackagingSimulator({
    id: String(id),
  });
  const { data: packagingSimulator } = data || {};
  const { meta } = packagingSimulator || {};
  const { valores_fornecedores: supplierValues = [] } = meta || {};
  const {
    handlePackagingSimulationData,
    clear,
    packagingSupplier: { handlePackagingSupplierCellValue },
  } = usePackagingSimulationContext();

  useEffect(() => {
    if (!packagingSimulator) return;
    handlePackagingSimulationData(packagingSimulator);
    handlePackagingSupplierCellValue(supplierValues);
  }, [packagingSimulator]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  if (isPendingPackagingSimulator)
    return (
      <AuthenticatedLayout className="h-full">
        <DotsLoading />
      </AuthenticatedLayout>
    );

  return (
    <AuthenticatedLayout title="Visualizando Simulador de Embalagens">
      <Can
        condition={!error}
        onTrue={
          <React.Fragment>
            <Can
              condition={!isPendingPackagingSimulator}
              onTrue={
                <React.Fragment>
                  <Row justify="end" className="gap-4">
                    <VisibleIconButton />
                  </Row>
                  <PackagingSimulationTable editableSupplierCell={false} />
                  <Row justify="end" className="mt-4 gap-4">
                    <Link to="/simuladoresEmbalagem">
                      <Button variant="ghost">Voltar</Button>
                    </Link>
                  </Row>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
        onFalse={<SimulationError />}
      />
    </AuthenticatedLayout>
  );
}
