import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { ProductionPerMonthType } from "@/hooks/useProduct";
import dayjs from "dayjs";
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from "recharts";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const chartConfig = {
  production: {
    label: "Produção",
    color: "#FAA307",
  },
};

type ChartProductionProps = { chartData: Array<ProductionPerMonthType> };

export default function ChartProduction({ chartData }: ChartProductionProps) {
  return (
    <ChartContainer
      config={chartConfig}
      className="min-h-[200px] h-[200px] w-fit"
    >
      <BarChart accessibilityLayer data={chartData}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="ano_mes"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value: string) => {
            return dayjs(value, "MM/YYYY").locale("pt-BR").format("MMM");
          }}
        />
        <ChartTooltip
          content={<ChartTooltipContent label="Produção" indicator="dashed" />}
        />
        <Bar
          dataKey="producao_mes"
          layout="vertical"
          fill="var(--color-production)"
          radius={4}
        >
          <LabelList dataKey="month" offset={8} fontSize={12} />
          <LabelList
            dataKey="producao_mes"
            position="top"
            offset={8}
            fontSize={12}
          />
        </Bar>
      </BarChart>
    </ChartContainer>
  );
}
