import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const schema = z.object({
  FK_fabrica_recurso: z.string({ required_error: REQUIRED_FIELD }),
  produtos: z
    .array(z.string({ required_error: REQUIRED_FIELD }), {
      required_error: REQUIRED_FIELD,
    })
    .min(1, { message: REQUIRED_FIELD }),
  capacidade_nominal: z
    .string({ required_error: REQUIRED_FIELD })
    .min(1, { message: REQUIRED_FIELD }),
});

export type FactoryResourceProductFormType = z.infer<typeof schema>;
