import { UploadFileFormDataType } from "../components/Form/UploadSheetForm";

export function fieldsToRender(
  type: UploadFileFormDataType["month" | "semester" | "year"],
  classification: UploadFileFormDataType["month" | "semester" | "year"]
) {
  if (!classification) return false;
  if (type === "year") return true;
  if (type === "month" && classification === "mensal") return true;
  if (type === "semester" && classification === "semestral") return true;

  return false;
}
