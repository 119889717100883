import instance from "@/api/business";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "./useEstimates";

type ProductionSimulatorLogType = {
  SK_simulador_log: number;
  status: string;
  alterado_por: string;
  created_at: string;
  FK_simulador: number;
};

async function fetchProductionSimulatorLog(id: number) {
  if (!id) return;
  const response = await instance.post<ApiResponse<ProductionSimulatorLogType>>(
    "/planningbeer/simulator/log",
    {
      skSimulation: id,
    }
  );

  return response.data;
}

export function useProductionSimulatorLog(id: number) {
  return useQuery({
    queryKey: ["productionSimulatorLog", id],
    queryFn: () => fetchProductionSimulatorLog(id),
  });
}
