import { useQueryClient } from "@tanstack/react-query";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import Each from "../Each";
import { resourceKeys, ResourcesType } from "@/hooks/useResources";
import { SelectProps } from "@radix-ui/react-select";

type ResourceData = {
  data: Array<ResourcesType>;
};

export default function ResourceSelect(props: SelectProps) {
  const queryClient = useQueryClient();

  const resourceData: ResourceData | undefined = queryClient.getQueryData(
    resourceKeys.all
  );
  const { data: resource } = resourceData || {};
  if (!resource) return null;

  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={resource}
          renderItem={(props) => {
            return (
              <SelectItem value={String(props.SK_recurso)}>
                {props.SK_recurso} {" - "}
                {props.descricao}
                {", "}
                {props.tipo_de_embalagem}
              </SelectItem>
            );
          }}
        />
      </SelectContent>
    </Select>
  );
}
