import dayjs from "dayjs";
import { CellLineValuesType } from "@/hooks/useProductionSimulationMutation";
import { AVERAGE_SALE_ST, DEMAND_PLANNING_ST } from "../constants";
import {
  GetAccumulatedSaleCellProps,
  GetGridSaleCellProps,
  GetProductInventoryType,
  GetProductRealProductionProps,
  IsLineIndisponibilityProps,
} from "../types";

const EMPTY_ARRAY = 0;

export function isIndisponibilityDemandPlanning({
  status,
  isIndisponibility,
}: {
  isIndisponibility: boolean;
  status: string;
}) {
  const isDemandPlanningCell =
    status == DEMAND_PLANNING_ST || status == AVERAGE_SALE_ST;

  const isIndisponibilityDemandPlanning =
    isDemandPlanningCell && isIndisponibility;

  return isIndisponibilityDemandPlanning;
}

export function isLineIndisponibility({
  factoryResourceFk,
  indisponibilityLine,
  currentDay,
}: IsLineIndisponibilityProps) {
  if (!factoryResourceFk) return false;
  const isIndisponibilityLine = indisponibilityLine.some((day) => {
    const onlyDates = day.dates.map((date) => dayjs(date).format("YYYY-MM-DD"));
    return (
      day.FK_fabrica_recurso == String(factoryResourceFk) &&
      onlyDates.includes(currentDay)
    );
  });

  return isIndisponibilityLine;
}

export function getLineIndisponibility({
  factoryResourceFk,
  indisponibilityLine,
  currentDay,
}: IsLineIndisponibilityProps) {
  return indisponibilityLine.filter((day) => {
    const onlyDates = day.dates.map((date) => dayjs(date).format("YYYY-MM-DD"));
    return (
      day.FK_fabrica_recurso == String(factoryResourceFk) &&
      onlyDates.includes(currentDay)
    );
  });
}

type GetCellLineValueProps = {
  cellLineValues: Array<CellLineValuesType>;
  currentDay: string;
  factoryResourceFk: string | number;
  productFk: string;
};

export function getCellLineValue({
  cellLineValues,
  factoryResourceFk,
  currentDay,
  productFk,
}: GetCellLineValueProps) {
  return (
    cellLineValues.find((cell) => {
      return (
        cell.FK_fabrica_recurso == factoryResourceFk &&
        cell.dia == currentDay &&
        cell.FK_produto == productFk
      );
    })?.valor || "0"
  );
}

export function existsCellLineValue({
  cellLineValues,
  day,
}: {
  cellLineValues: Array<CellLineValuesType>;
  day: string;
}) {
  const cellLineValue = cellLineValues.some((cell) => cell.dia == day);
  return cellLineValue;
}

export function getCellDayLineValue({
  cellLineValues,
  date,
  product,
}: {
  cellLineValues: Array<CellLineValuesType>;
  date: string;
  product: string;
}) {
  if (cellLineValues.length == EMPTY_ARRAY) return [];
  const cellLineValue = cellLineValues.filter(
    (cell) => cell.dia == date && cell.FK_produto == product
  );

  return cellLineValue;
}

export function isNewInventoryCellValue({
  productFk,
  day,
  inventory,
}: {
  productFk: string;
  day: string;
  inventory: Array<GetProductInventoryType>;
}) {
  if (!inventory.length) return false;
  const isNewInventoryValue = inventory.map((inventoryItem) => {
    const hasInventoryForDay = inventoryItem.saldo_inicial.find(
      (item) => item.dia == day && inventoryItem.produto == productFk
    );
    if (hasInventoryForDay) return true;
    return false;
  });
  return isNewInventoryValue.some((value) => value);
}

export function getGridSaleCell({
  gridSale,
  productFk,
  day,
}: GetGridSaleCellProps) {
  if (!gridSale) return 0;

  const gridSaleValue = gridSale.find(
    (gridSaleItem) =>
      gridSaleItem.FK_produto == productFk && gridSaleItem.dia == day
  )?.valor;

  if (!gridSaleValue) return 0;
  return Number(gridSaleValue);
}

export function getProductRealProduction({
  productFk,
  day,
  productRealProduction,
}: GetProductRealProductionProps) {
  if (!productRealProduction) return 0;

  const productRealProductionValue = productRealProduction.find(
    (product) => product.FK_produto == productFk && product.dia == day
  )?.valor;

  if (!productRealProductionValue) return 0;
  return Number(productRealProductionValue);
}

export function getAccumulatedSaleCell({
  accumulatedSale,
  productFk,
  day,
}: GetAccumulatedSaleCellProps) {
  const accumulatedSaleProduct = accumulatedSale.find(
    (item) => item.produto == productFk
  );
  if (!accumulatedSaleProduct) return 0;
  const accumulatedSaleForDays = accumulatedSaleProduct.venda_acumulada.find(
    (item) => item[day]
  );
  const accumulatedSaleForDay = accumulatedSaleForDays?.[day]?.valor || 0;

  return accumulatedSaleForDay;
}

export function getRows(
  array: Array<{
    FK_produto: string;
    descricao: string;
    tipo: string;
    st: string;
    quantidade: number | string;
    quantidade_total: number | string;
  }>
) {
  return array.reduce(
    (_, row) => ({
      FK_produto: row.FK_produto,
      descricao: row.descricao,
      tipo: row.tipo,
      st: row.st,
      quantidade: row.quantidade,
      quantidade_total: row.quantidade_total,
    }),
    {}
  );
}
