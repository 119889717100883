import Col from "@/components/Col";
import Each from "@/components/Each";
import Text from "@/components/Text/Text";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Separator } from "@/components/ui/separator";
import dayjs from "dayjs";
import { getQuantityCheckedPallets } from "../../utils/buildLoadUtils";
import { calculateTotalPallets } from "@/pages/SimulacoesEmbalagem/utils/carga";
import LoadPackagingSupplierHeader from "../Header/LoadPackagingSupplierHeader";
import {
  SelectedPalleteType,
  SuppliersPackageType,
} from "../Steps/BuildLoad/LoadPackaging";
import { PackagingType } from "@/hooks/usePackagings";
import Can from "@/components/Can";
import { SelectPalletRowActions } from "../Row/SelectPalletRowActions";
import PalletRow from "../Row/PalletRow";
import React from "react";

export type LoadPackagingContentProps = {
  supplierFk: string;
  supplierName: string;
  dates: SuppliersPackageType["dates"];
  packagings: PackagingType[];
  selectedPallets: SelectedPalleteType[];
  onCheckPallete: (selectedValue: SelectedPalleteType) => void;
  onChangeType: (selectedValue: SelectedPalleteType) => void;
  onCheckAll: (selectedValue: SelectedPalleteType[]) => void;
  onUncheckAll: (selectedValue: SelectedPalleteType[]) => void;
};

export default function LoadPackagingContent({
  dates,
  selectedPallets,
  packagings,
  supplierFk,
  supplierName,
  onChangeType,
  onCheckPallete,
  onCheckAll,
  onUncheckAll,
}: LoadPackagingContentProps) {
  return (
    <Accordion type="single" collapsible>
      <AccordionItem value={supplierFk.toString()}>
        <AccordionTrigger className="p-2">
          <Text className="text-sm">
            Fornecedor: <strong>{supplierName}</strong>
          </Text>
        </AccordionTrigger>
        <Separator />
        <AccordionContent className="gap-2">
          <Each
            array={dates}
            renderItem={({ day, items }) => {
              return (
                <>
                  <Col className="ml-4 p-2 gap-1">
                    <Text className="font-bold text-sm">
                      Dia: {dayjs(day).format("DD/MM/YYYY")}
                    </Text>
                    <Each
                      array={items}
                      renderItem={({
                        composicao,
                        valor,
                        quantidade_por_palete,
                        FK_embalagem_fornecedor,
                      }) => {
                        const totalPallets = calculateTotalPallets(
                          Number(valor || 0),
                          Number(quantidade_por_palete || 0)
                        );

                        const checkedPalletQuantity = getQuantityCheckedPallets(
                          selectedPallets,
                          {
                            composition: composicao,
                            day: day,
                            supplier: supplierFk,
                          }
                        );

                        const arrayPallets = Array.from(
                          { length: totalPallets },
                          (_, index) => index + 1
                        );

                        return (
                          <Col className="border rounded-sm p-2">
                            <Accordion type="single" collapsible>
                              <AccordionItem value={items.toString()}>
                                <AccordionTrigger className="p-2">
                                  <LoadPackagingSupplierHeader
                                    fkSupplier={supplierFk}
                                    day={day}
                                    selectedPallets={selectedPallets}
                                    packagings={packagings}
                                    composition={composicao}
                                    totalPallets={totalPallets}
                                    checkedPalletsQuantity={
                                      checkedPalletQuantity
                                    }
                                    valor={valor}
                                    quantityPerPallet={quantidade_por_palete}
                                  />
                                </AccordionTrigger>
                                <AccordionContent className="h-[28rem] overflow-y-scroll">
                                  <Each
                                    array={arrayPallets}
                                    renderItem={(pallet, index) => {
                                      return (
                                        <React.Fragment>
                                          <Can
                                            condition={index == 0}
                                            onTrue={
                                              <SelectPalletRowActions
                                                pallets={arrayPallets}
                                                composition={composicao}
                                                day={day}
                                                dates={dates}
                                                fkSupplier={supplierFk}
                                                fkPackagingSupplier={
                                                  FK_embalagem_fornecedor
                                                }
                                                onCheckAll={onCheckAll}
                                                onUncheckAll={onUncheckAll}
                                              />
                                            }
                                          />
                                          <PalletRow
                                            composition={composicao}
                                            day={day}
                                            fkSupplier={supplierFk}
                                            palletNumber={pallet}
                                            quantityPerPallet={
                                              quantidade_por_palete
                                            }
                                            selectedPallets={selectedPallets}
                                            onChangeType={onChangeType}
                                            onCheckPallete={onCheckPallete}
                                          />
                                        </React.Fragment>
                                      );
                                    }}
                                  />
                                </AccordionContent>
                              </AccordionItem>
                            </Accordion>
                          </Col>
                        );
                      }}
                    />
                  </Col>
                  <Separator />
                </>
              );
            }}
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
