import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import VisualizeItem from "@/components/DropdownItem/VisualizeDropdownItem";
import React from "react";
import { ProductionPlanTableType } from "../..";
import { useProductionPlanContext } from "../../context";

export default function ActionDropdown(props: ProductionPlanTableType) {
  const { handleDialog, handleDialogType, handleProductPlan } =
    useProductionPlanContext();

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handleProductPlan({ ...props });
  }

  function handleVisualize() {
    handleDialog();
    handleProductPlan({ ...props });
  }

  return (
    <React.Fragment>
      <VisualizeItem onClick={handleVisualize} id={props.SK_plano_producao} />
      <DeleteDropdownItem onClick={handleDelete} />
    </React.Fragment>
  );
}
