import { useMutation, useQueryClient } from "@tanstack/react-query";
import { estimates } from "./useEstimates";
import instance from "@/api/business";

export default function useEstimateDeleteMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return instance.delete("/planningbeer/forecast", {
        data: { id },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: estimates.all });
    },
  });
}
