import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cargoModelKey } from "./useCargoModels";
import { FormValues } from "../components/Form/zod";

type CargoModelPatchMutationProps = {
  SK_modelo_carga: number;
} & FormValues;

export function useCargoModelPatchMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: CargoModelPatchMutationProps) => {
      await instance.patch("/planningbeer/cargoModel", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cargoModelKey.all });
    },
  });
}
