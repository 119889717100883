import { BusinessType } from "@/hooks/useBusiness";

export function getOperationType(operationType: string) {
  const opertationObject: Pick<
    BusinessType,
    "ABREVIATURA_EMPRESA" | "CNPJ" | "SK_EMPRESA"
  > = JSON.parse(operationType || "{}");

  return opertationObject;
}
