import { useForm } from "react-hook-form";
import {
  PackagingSupplierEditFormType,
  packagingSupplierEditSchema,
} from "../zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePackagingSupplierContext } from "@/pages/EmbalagemFornecedores/context";
import { useEffect } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import Text from "@/components/Text/Text";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import FactorySelect from "@/components/Select/FactorySelect";
import ProductPackagingSelect from "@/components/Select/ProductPackagingSelect";
import SupplierSelect from "@/components/Select/SupplierSelect";
import { usePackagingSupplierPatchMutation } from "@/hooks/usePackagingSupplierPatchMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import { Input } from "@/components/ui/input";
import BusinessSelect from "@/components/Select/BusinessSelect";

export default function EditPackagingSupplierForm() {
  const { mutateAsync, isPending } = usePackagingSupplierPatchMutation();
  const { packagingSupplier, clear } = usePackagingSupplierContext();
  const {
    FK_fabrica,
    composicao,
    FK_fornecedor,
    lead_time,
    quantidade_por_palete,
    tipo_de_operacao,
  } = packagingSupplier;
  const form = useForm<PackagingSupplierEditFormType>({
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: zodResolver(packagingSupplierEditSchema),
  });

  async function handleSubmit(data: PackagingSupplierEditFormType) {
    if (isPending) return;
    try {
      await mutateAsync({
        ...data,
        skPackagingSupplier: packagingSupplier.SK_embalagem_fornecedor,
      });
      toast.success("Editado com sucesso!");
      return clear();
    } catch (error) {
      return validationError(error);
    }
  }

  useEffect(() => {
    form.reset({
      fkFactory: String(FK_fabrica),
      fkPackaging: String(composicao),
      fkSupplier: String(FK_fornecedor),
      leadTime: String(lead_time),
      palleteQuantity: String(quantidade_por_palete),
      operationType: String(tipo_de_operacao),
    });
  }, [packagingSupplier]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="flex flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="fkSupplier"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Fornecedor *</Text>
              </FormLabel>
              <FormControl>
                <SupplierSelect
                  onValueChange={(value) => {
                    form.setValue("fkSupplier", value);
                  }}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="fkPackaging"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Embalagem *</Text>
              </FormLabel>
              <FormControl>
                <ProductPackagingSelect
                  onValueChange={(value) => {
                    form.setValue("fkPackaging", value);
                  }}
                  value={field.value}
                  defaultValue={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="fkFactory"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Fábrica *</Text>
              </FormLabel>
              <FormControl>
                <FactorySelect
                  onValueChange={(value) => {
                    form.setValue("fkFactory", value);
                  }}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="leadTime"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Lead Time (em dias) *</Text>
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="palleteQuantity"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Quantidade por Palete *</Text>
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="operationType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Tipo de Operação *</Text>
              </FormLabel>
              <FormControl>
                <BusinessSelect
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={isPending}>
            <Text color="white">Salvar</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
