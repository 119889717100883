import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";

export default function CreateResourceButton() {
  return (
    <Button>
      <Text color="white">Criar Recurso</Text>
    </Button>
  );
}
