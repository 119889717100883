import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type CargoModelType = {
  SK_modelo_carga: number;
  nome: string;
  quantidade_palete: number;
  created_at: string;
};

export const cargoModelKey = {
  all: ["cargoModel"] as const,
};

async function fetchCargoModel() {
  const response = await instance.post<ApiResponse<CargoModelType>>(
    "/planningbeer/cargoModel"
  );
  return response.data;
}

export default function useCargoModels() {
  return useQuery({
    queryKey: cargoModelKey.all,
    queryFn: fetchCargoModel,
  });
}
