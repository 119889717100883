import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { productionPlanKeys } from "./useProductionPlanMutation";
import { productionSimulationKey } from "./useProductionSimulation";

type ProductionPlanVersionPatchMutationType = {
  skProductionPlan: number;
  lineValues: string;
  revision: number;
};

export function useProductionPlanVersionPatchMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ProductionPlanVersionPatchMutationType) => {
      await instance.patch("/planningbeer/productionPlan/version", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productionPlanKeys.all });
      queryClient.invalidateQueries({
        queryKey: productionSimulationKey.show,
      });
    },
  });
}
