import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type ProductsMutationType = {
  skProducts: {
    SK_produto: string;
    tipo_planejador: string | undefined;
  }[];
};

export default function useProductsMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ProductsMutationType) => {
      return instance.put("/planningbeer/product", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
    },
  });
}
