import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { FactoryColumnsType } from "@/pages/Factory";
import { useFactoryContext } from "@/pages/Factory/context";
import { FaEdit } from "react-icons/fa";

export default function EditDropdownItem(props: FactoryColumnsType) {
  const { handleFactory, handleDialogType, handleDialog } = useFactoryContext();

  function handleEditDialog() {
    handleFactory(props);
    handleDialogType("edit");
    handleDialog();
  }

  return (
    <DropdownMenuItem onClick={handleEditDialog}>
      <Row>
        <FaEdit className="mr-2" color={TEXT_COLOR} />
        <Text>Editar</Text>
      </Row>
    </DropdownMenuItem>
  );
}
