import instance from "@/api/business";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "./useEstimates";

export type ResourceFactoryProductsType = {
  codigo_produto: string;
  descricao_produto: string;
  produtos: {
    SK_recurso_fabrica_produto: number;
    FK_produto: string;
    FK_fabrica_recurso: number;
    descricao_produto: string;
    codigo_produto: string;
    codigo_fabrica: string;
    cnpj_fabrica: string;
    capacidade: string | number;
    abreviatura_fabrica: string;
    descricao_recurso: string;
    definicao_recurso: string;
  }[];
};

export const resourceFactoryProductsKeys = {
  all: ["resourceFactoryProducts"] as const,
  factoryResourcesProducts: (factoryKey: number) =>
    [...resourceFactoryProductsKeys.all, "factory", factoryKey] as const,
};

export async function fetchResourceFactoryProducts() {
  const response = await instance.post<
    ApiResponse<ResourceFactoryProductsType>
  >("/planningbeer/resourceFactoryProduct");
  return response.data;
}

export default function useResourceFactoryProducts() {
  return useQuery({
    queryFn: fetchResourceFactoryProducts,
    queryKey: resourceFactoryProductsKeys.all,
  });
}
