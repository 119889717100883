import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import EditDropdownItem from "@/components/DropdownItem/EditDropdownItem";
import React from "react";
import { useResourceTypeContext } from "../../context";
import { ConfigurationsType } from "@/hooks/useConfiguration";

export default function ActionDropdown(props: ConfigurationsType) {
  const { handleResourceType, handleDialog, handleDialogType } =
    useResourceTypeContext();

  function handleEdit() {
    handleDialog();
    handleDialogType("edit");
    handleResourceType({ ...props });
  }

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handleResourceType({ ...props });
  }

  return (
    <React.Fragment>
      <EditDropdownItem onClick={handleEdit} />
      <DeleteDropdownItem onClick={handleDelete} />
    </React.Fragment>
  );
}
