import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { indisponibilityResourceKey } from "./useIndisponibilityResources";

export default function useIndisponibilityResourceDeleteMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return instance.delete("/planningbeer/indisponibilityResource", {
        data: { id },
      });
    },

    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: indisponibilityResourceKey.all,
      });
    },
  });
}
