import { DropdownMenuItem } from "../ui/dropdown-menu";
import Row from "../Row";
import { FaTrash } from "react-icons/fa6";
import { TEXT_COLOR } from "@/constants";
import Text from "../Text/Text";

type DeleteDropdownItemProps = {
  onClick: () => void;
};

export default function DeleteDropdownItem({
  onClick,
}: DeleteDropdownItemProps) {
  return (
    <DropdownMenuItem onClick={onClick}>
      <Row>
        <FaTrash className="mr-2" color={TEXT_COLOR} />
        <Text>Deletar</Text>
      </Row>
    </DropdownMenuItem>
  );
}
