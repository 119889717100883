import Row from "@/components/Row";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import FactorySelect from "@/components/Select/FactorySelect";
import ResourceSelect from "@/components/Select/ResourceSelect";
import ModelTurnSelect from "@/components/Select/ModelTurnSelect";

type FactoryResourceFormProps<T extends FieldValues> = {
  form: UseFormReturn<T, any, undefined>;
  onSubmit: (data: T) => void;
  button?: {
    disabled?: boolean;
    title?: string;
  };
};

export default function FactoryResourceForm<T extends FieldValues>({
  form,
  onSubmit,
  button = {
    disabled: false,
    title: "",
  },
}: FactoryResourceFormProps<T>) {
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name={"FK_fabrica" as Path<T>}
          render={({ field }) => (
            <FormItem>
              <FormLabel>ID Fábrica *</FormLabel>
              <FormControl>
                <FactorySelect
                  onValueChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={"FK_recurso" as Path<T>}
          render={({ field }) => (
            <FormItem>
              <FormLabel>ID Recurso *</FormLabel>
              <FormControl>
                <ResourceSelect
                  onValueChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row className="gap-2">
          <FormField
            control={form.control}
            name={"turno_de_trabalho" as Path<T>}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Regime de linha</FormLabel>
                <FormControl>
                  <ModelTurnSelect
                    value={field.value}
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Row>
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={button.disabled}>
            <Text color="white">{button.title}</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
