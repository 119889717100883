import { useForm } from "react-hook-form";
import { CargoModelForm } from "..";
import { FormValues, schema } from "../zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCargoModelMutation } from "@/pages/ModeloCarga/hooks/useCargoModelMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";

type CreateCargoModelFormProps = {
  handleClose: () => void;
};

export default function CreateCargoModelForm({
  handleClose,
}: CreateCargoModelFormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const { mutateAsync, isPending } = useCargoModelMutation();

  async function handleSubmit(formData: FormValues) {
    try {
      if (isPending) return;
      await mutateAsync(formData);
      handleClose();
      return toast.success("Modelo de Carga criado com sucesso!");
    } catch (err) {
      return validationError(err);
    }
  }

  return <CargoModelForm form={form} onSubmit={handleSubmit} />;
}
