import instance from "@/api/business";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "./useEstimates";

type ProductionSimulatorsType = {
  fkFactory: number;
  fkProductionPlan: number;
};

export type ProductionSimulatorsResponseType = {
  SK_simulacao: number;
  FK_fabrica: number;
  criado_por: string;
  criado_por_nome: string;
  descricao: string;
  produtos_selecionados: null;
  recursos_selecionados: null;
  valores_linhas: string;
  status: string;
  periodo_inicio: string;
  periodo_fim: string;
  created_at: string;
  updated_at: string;
  D_E_L_E_T_: null;
  revisao: number;
  observacao?: string;
};

export const productionPlanSimulatorsKey = {
  all: ["productionPlanSimulators"] as const,
  show: (id) => [...productionPlanSimulatorsKey.all, id, "show"] as const,
};

async function fetchProductionPlanSimulators(props: ProductionSimulatorsType) {
  const response = await instance.post<
    ApiResponse<ProductionSimulatorsResponseType>
  >("/planningbeer/productionPlan/simulators", { ...props });

  return response.data;
}

export function useProductionPlanSimulators(props: ProductionSimulatorsType) {
  return useQuery({
    queryFn: () => fetchProductionPlanSimulators(props),
    queryKey: productionPlanSimulatorsKey.show(props.fkFactory),
    staleTime: 0,
    gcTime: 0,
  });
}
