import Row from "@/components/Row";
import SupplierSelect from "@/components/Select/SupplierSelect";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";

export default function CreateSupplierAccessDialog() {
  const form = useForm();
  return (
    <Dialog>
      <DialogTrigger>
        <Button>Criar Acesso para Fornecedor</Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form className="flex flex-col gap-4">
            <FormField
              name=""
              render={() => {
                return (
                  <FormItem>
                    <FormLabel>
                      <Text>Nome *</Text>
                    </FormLabel>
                    <FormControl>
                      <Input />
                    </FormControl>
                  </FormItem>
                );
              }}
            />
            <FormField
              name=""
              render={() => {
                return (
                  <FormItem>
                    <FormLabel>
                      <Text>E-mail *</Text>
                    </FormLabel>
                    <FormControl>
                      <Input />
                    </FormControl>
                  </FormItem>
                );
              }}
            />
            <FormField
              name=""
              render={() => {
                return (
                  <FormItem>
                    <FormLabel>
                      <Text>Usuário *</Text>
                    </FormLabel>
                    <FormControl>
                      <Input />
                    </FormControl>
                  </FormItem>
                );
              }}
            />
            <FormField
              name=""
              render={() => {
                return (
                  <FormItem>
                    <FormLabel>
                      <Text>Senha *</Text>
                    </FormLabel>
                    <FormControl>
                      <Input />
                    </FormControl>
                  </FormItem>
                );
              }}
            />
            <FormField
              name=""
              render={() => {
                return (
                  <FormItem>
                    <FormLabel>
                      <Text>Confirmar Senha *</Text>
                    </FormLabel>
                    <FormControl>
                      <Input />
                    </FormControl>
                  </FormItem>
                );
              }}
            />
            <FormField
              name=""
              render={() => {
                return (
                  <FormItem>
                    <FormLabel>
                      <Text>Fornecedor *</Text>
                    </FormLabel>
                    <FormControl>
                      <SupplierSelect />
                    </FormControl>
                  </FormItem>
                );
              }}
            />
          </form>
          <Row justify="end">
            <Button type="submit">Salvar</Button>
          </Row>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
